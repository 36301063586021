/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-constant-condition */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import {
  faArrowRight,
  faCheck,
  faCheckCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router-dom'
import ReactTooltip from 'react-tooltip'
import Lottie from 'react-lottie'
import { isAfter } from 'date-fns'
import downloadLottie from '../../assets/icons/arquivo-baixado.json'
import copyLottie from '../../assets/icons/copiar-codigo.json'
import iconInfoGreen from '../../assets/icons/information.svg'
import allCompleteImg from '../../assets/images/allComplete.svg'
import iconDownload from '../../assets/icons/download_2.svg'
import barcodeScannerIcon from '../../assets/icons/barcode_scanner.svg'
import noInvoicesPendIcon from '../../assets/icons/noinvoices.svg'
import searchIcon from '../../assets/icons/searchIcon.svg'
import * as S from './styles'
import { newApi } from '../../services/api'
import loadingIcon from '../../assets/icons/loading.svg'

import loadingPrimaryIcon from '../../assets/icons/loading-primary.svg'
import keyboardDownIcon from '../../assets/icons/keyboardDownIcon.svg'
import {
  FaturaDTO,
  Invoice as InvoiceValue,
  StateInitial,
  TFInvoice,
  User,
} from '../../utils/models'
import { isAuthenticated } from '../../utils/auth'
import Footer from '../../components/Footer'
import visibilityIcon from '../../assets/icons/visibility_icon.svg'
import emptyInvoiceIcon from '../../assets/icons/emptyInvoice.svg'
import logoBill from '../../assets/images/logo-bill.png'
import { InvoiceItem } from './components/InvoiceItem'
import { InvoiceDetailModal } from './components/InvoiceDetailModal'
import {
  defaultPageSize,
  handleConsultDoc,
  handleUC,
  pageSizes,
} from './viewModel'
import { PaginationFooterV2WithoutTotal } from '../../components/PaginationFooterWithoutTotal'
import Select from '../../components/Select'
import { FormlessSelect } from '../../components/FormlessSelect'
import { removeDuplicatesInvoiceId } from '../../utils/removeDups'
import { PaginationFooterV2WithTotal } from '../../components/PaginationFooterWithTotal'
import { GlobalRow } from '../../components/GlobalRow'
import { OmniChannelComponent } from '../../components/OmniChannelComponent'
import { StringToCurrency } from '../../utils/stringToCurrency'
import { GlobalColumn } from '../../components/GlobalColumn'
import { PaymentSlipBoletoInvoice } from '../../components/PDF/PaymentSlipBoletoInvoice'

const DECIMAL_NUMBER = 10
const THREE_SECONDS = 3000

interface DataDashDTO {
  modules: StateInitial
  dispatch: any
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Home = ({ modules, dispatch }: DataDashDTO): any => {
  const location = useLocation<any>()
  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [total, setTotal] = useState(0)
  const [hasMore, setHasMore] = useState(true)
  const [allInvoices, setAllInvoices] = useState<Array<TFInvoice>>([])
  const [invoices, setInvoices] = useState<Array<TFInvoice>>()
  const [yearsInvoices, setYears] = useState<Array<number>>([])
  const [invoicesId, setInvoicesId] = useState<number[]>()
  const [loadingData, setLoadingData] = useState(true)
  const [donwloadIdInvoice, setIdInvoice] = useState<string>()
  const [showDetails, setShowDetails] = useState<string>()
  const history = useHistory()
  const [totalCart, setTotalCart] = useState(0)
  const [originalValue, setOriginalValue] = useState(0)
  const [penalty, setPenalty] = useState(0)
  const [fees, setFees] = useState(0)
  const [correction, setCorrection] = useState(0)
  const [loading, setLoading] = useState(false)
  const [showInfo, setShowInfo] = useState<number | null>()
  const [markAll, setMarkAll] = useState(false)
  const [downloadThis, setDownloadThis] = useState<TFInvoice>()
  const [selectedInvoice, setSelectedInvoice] = useState<TFInvoice>()
  const [filterStatus, setFilterStatus] = useState('TODOS')
  const [showInfoDownloadFile, setShowInfoDownloadFile] = useState<boolean>(
    false
  )
  const [filterText, setFilterText] = useState('')
  const [page, setPage] = useState(1)
  function checkDueStatus(invoiceInfo: TFInvoice): boolean {
    const { paymentStatus } = invoiceInfo

    if (!paymentStatus) {
      return true
    }

    return false
  }

  function checkDueDate(dataInvoice: string): boolean {
    const [year, month, day] = dataInvoice.split('-')
    // console.log(dataInvoice)
    const dateInvoice = new Date(
      parseInt(year, DECIMAL_NUMBER),
      parseInt(month, DECIMAL_NUMBER) - 1,
      parseInt(day, DECIMAL_NUMBER)
    )
    dateInvoice.setHours(23, 59, 59)
    // VERIFICAÇÃO DE VENCIMENTO, REMOVENDO TEMPORARIAMENTE PARA TESTES
    return true
    return isAfter(dateInvoice, new Date())
  }

  const filteredInvoices = invoices?.filter((invoice) => {
    return !filterText || `${invoice.id}`.includes(filterText)
  })

  const paidInvoices = invoices?.filter((invoice) => {
    return invoice.paymentStatus
  })

  const unPaidInvoices = invoices?.filter((invoice) => {
    return invoice.paymentStatus !== 1
  })

  /* function showModalWhenDocEmpty(module: User, loadingSearchBd: boolean): unknown {
    return {
        type: 'SET_VISIBILITY_MODAL',
        module,
        loadingSearchBd,
    };
  } */

  function setLoadingSearchDb(
    module: boolean,
    showLoadingDb: boolean
  ): unknown {
    return {
      type: 'SET_SEARCH_DB_LOADING',
      module,
      showLoadingDb,
    }
  }

  useEffect(() => {
    if (invoices) {
      if (invoicesId) {
        const filterableInvoices = invoices.filter((invPend) => {
          if (invPend.paymentDueDate) {
            const isDueDate = checkDueDate(invPend.paymentDueDate)
            if (!isDueDate) {
              return false
            }
          }
          const isDueStatus = checkDueStatus(invPend)
          if (!isDueStatus) {
            return false
          }
          return true
        })
        if (
          filterableInvoices.length === 0 ||
          invoicesId.length < filterableInvoices.length
        ) {
          setMarkAll(false)
        } else {
          setMarkAll(true)
        }
      }
    }
  }, [invoices, invoicesId])

  useEffect(() => {
    if (isAuthenticated()) {
      const temp = localStorage.getItem('Invoices')
      if (temp) {
        const tempInvoices = JSON.parse(temp)
        if (tempInvoices[0] && tempInvoices[0].invoice_id) {
          const limitedInvoices = [...tempInvoices].slice(0, 10)
          setInvoices(limitedInvoices)

          setAllInvoices(tempInvoices)
          // console.log('12345')
          setLoadingData(false)
        } else {
          setYears([])
          setLoadingData(false)
        }
      } else {
        setYears([])
        setLoadingData(true)
      }
      const tempTotal = localStorage.getItem('x-total')
      if (tempTotal) {
        setTotal(Number(tempTotal) || 0)
      }
    } else {
      history.push('/login')
    }
  }, [modules])

  useEffect(() => {
    if (allInvoices && allInvoices.length) {
      const data = []
      data.push(parseFloat(allInvoices[0].refDate.substr(3)))
      for (let i = 1; i < allInvoices.length; i += 1) {
        if (
          parseFloat(allInvoices[i].refDate.substr(3)) !==
          parseFloat(allInvoices[i - 1].refDate.substr(3))
        ) {
          data.push(parseFloat(allInvoices[i].refDate.substr(3)))
        }
      }
      setYears(data)
    }
  }, [allInvoices])

  useEffect(() => {
    if (location.state) {
      // if (location.state.data) {
      //   const tempId = location.state.data.map((fatura: FaturaDTO) => {
      //     return fatura.id
      //   })
      //   let cartTotalTemp = 0
      //   let originalTemp = 0
      //   let penaltyTemp = 0
      //   let feesTemp = 0
      //   let correctionTemp = 0
      //   location.state.data.forEach((fatura: FaturaDTO) => {
      //     penaltyTemp += fatura.multa
      //     feesTemp += fatura.juros
      //     correctionTemp += fatura.correcao
      //     cartTotalTemp += fatura.total
      //     originalTemp += fatura.fatura
      //   })
      //   setPenalty(penaltyTemp)
      //   setFees(feesTemp)
      //   setCorrection(correctionTemp)
      //   setOriginalValue(originalTemp)
      //   setTotalCart(cartTotalTemp)
      //   setInvoicesId(tempId)
      // }

      if (location.state.refreshRef) {
        getFromRef(location.state.refreshRef, 1)
      } else if (location.state.docField) {
        getFromDoc(location.state.docField, 1)
      }
    }
  }, [
    location,
    // allInvoices
  ])

  function checkAllInvoices(checkbox: boolean): void {
    if (checkbox) {
      setTotalCart(0)
      setOriginalValue(0)
      let totalCartTemp = 0
      let originalValueTemp = 0
      setInvoicesId([])
      const invoiceTemp = [] as number[]
      if (invoices) {
        invoices.forEach((invPend) => {
          if (invPend.paymentDueDate) {
            const isDueDate = checkDueDate(invPend.paymentDueDate)
            if (!isDueDate) {
              return
            }
          }
          const isDueStatus = checkDueStatus(invPend)
          if (!isDueStatus) {
            return
          }

          totalCartTemp += Number(invPend.totalPaymentDue)
          originalValueTemp += Number(invPend.totalPaymentDue)
          if (invPend.invoice_id) {
            invoiceTemp.push(invPend.invoice_id)
          }
        })
      }

      setTotalCart(totalCartTemp)
      setOriginalValue(originalValueTemp)

      setInvoicesId(invoiceTemp)
    } else {
      setTotalCart(0)
      setOriginalValue(0)
      setInvoicesId([])
    }
  }

  function invoiceDescription(invoice: TFInvoice): string {
    if (invoice?.extras?.custa) {
      if (invoice?.extras?.custa[0]) {
        return `${
          invoice?.extras?.parcela ? `${invoice?.extras?.parcela} • ` : ''
        }${invoice?.extras?.custa[0].descricao}`
      }
    }
    if (invoice?.extras?.description) {
      return `${
        invoice?.extras?.parcela ? `${invoice?.extras?.parcela} • ` : ''
      }${invoice?.extras?.description}`
    }
    if (invoice?.description) {
      return `${
        invoice?.extras?.parcela ? `${invoice?.extras?.parcela} • ` : ''
      }${invoice.description}`
    }

    return '-'
  }

  function handlePaymentButton(invoice: TFInvoice): void {
    if (invoice && invoice.paymentDueDate) {
      if (!checkDueDate(invoice.paymentDueDate)) {
        return
      }
    }
    const isDueStatus = checkDueStatus(invoice)
    if (!isDueStatus) {
      return
    }

    const resp = invoicesId?.filter((inv) => {
      return inv !== invoice.invoice_id
    })
    if (resp) {
      if (resp?.length === invoicesId?.length) {
        setTotalCart(totalCart + Number(invoice.totalPaymentDue))
        setOriginalValue(originalValue + Number(invoice.totalPaymentDue))

        if (invoice.invoice_id)
          setInvoicesId([...invoicesId, invoice.invoice_id])
      } else {
        if (totalCart - Number(invoice.totalPaymentDue) <= 1) {
          setPenalty(0)
          setFees(0)
          setCorrection(0)
          setOriginalValue(0)
          setTotalCart(0)
        } else {
          setOriginalValue(originalValue - Number(invoice.totalPaymentDue))
          setTotalCart(totalCart - Number(invoice.totalPaymentDue))
        }
        setInvoicesId(resp)
      }
    } else {
      setTotalCart(Number(invoice.totalPaymentDue))
      setOriginalValue(Number(invoice.totalPaymentDue))
      if (invoice.invoice_id) setInvoicesId([invoice.invoice_id])
    }
  }

  function checkIdList(invoiceIdparse: number | null): boolean {
    if (invoiceIdparse) {
      const resp = invoicesId?.filter((invoice) => {
        return invoice === invoiceIdparse
      })
      if (resp)
        if (resp?.length > 0) {
          return true
        }
    }
    return false
  }

  function generatingTextStatus(inv: TFInvoice): string {
    if (inv.paymentDueDate) {
      if (!checkDueDate(inv.paymentDueDate)) {
        return 'Vencida'
      }
    }
    if (inv.paymentStatus === null) return 'Aberta'
    if (inv.paymentStatus === 1) return 'Paga'
    if (inv.paymentStatus === -1) return 'Indisponível'
    if (inv.paymentStatus === 0) return 'Aberta'

    return 'Aberto'
  }

  function generatingColorStatus(inv: TFInvoice): string {
    if (inv.paymentDueDate) {
      if (!checkDueDate(inv.paymentDueDate)) {
        return '#D93B3C'
      }
    }

    return getColorSituation(inv)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function getColorSituation(situation: TFInvoice): string {
    if (situation.paymentStatus === -1) return '#9E9E9E'
    if (situation.paymentStatus === 1) return '#00AB50'
    if (situation.paymentStatus === 0) return '#F79E1B'
    return '#F79E1B'
  }

  function generatingBackgroundColorStatus(inv: TFInvoice): string {
    if (inv.paymentDueDate) {
      if (!checkDueDate(inv.paymentDueDate)) {
        return '#F6D8D5'
      }
    }

    return getColorBackgroundSituation(inv)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function getColorBackgroundSituation(situation: TFInvoice): string {
    if (situation.paymentStatus === -1) return '#c5c5c5'
    if (situation.paymentStatus === 1) return '#D9F2E5'
    if (situation.paymentStatus === 0) return '#FBEDD1'
    return '#FBEDD1'
  }

  function getSituation(situation: TFInvoice): number {
    if (situation.paymentStatus) {
      return 1
    }
    return 0
  }

  async function paymentHandle(): Promise<void> {
    // console.log(invoicesId);

    // return;

    setLoading(true)
    const token = sessionStorage.getItem('token')
    const temp = localStorage.getItem('Invoices')
    let client
    if (temp && token) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      client = JSON.parse(temp || '')

      const invoicesList: any = []

      invoicesId?.forEach((ii) => {
        invoicesList.push({ id: ii })
      })

      try {
        if (invoices) {
          const { data } = await newApi.post(
            '/mobile/payment',
            {
              merchant: process.env.REACT_APP_MERCHANT_FIXED,
              doc: '',
              invoices: invoicesList,
            },
            {
              headers: {
                Authorization: `Basic ${token}`,
                'Content-Type': 'application/json',
              },
            }
          )

          if (data) {
            //  console.log(data)
            setLoading(false)
            history.push({
              pathname: '/payment',
              state: {
                data,
              },
            })
          }
        }
      } catch (error) {
        // console.log(error)
      } finally {
        setLoading(false)
      }
    }
  }

  function downloadPdf(invoice: TFInvoice): void {
    setShowInfoDownloadFile(true)
    setTimeout(() => {
      setDownloadThis(invoice)
      setShowInfoDownloadFile(false)
    }, THREE_SECONDS)
    setDownloadThis(undefined)
    if (donwloadIdInvoice === invoice.id) {
      setIdInvoice(undefined)
    } else {
      setIdInvoice(invoice.id)
    }
  }

  function transformDate(d: string): string {
    const dSplit = d.split('-')

    return `${dSplit[2]}/${dSplit[1]}/${dSplit[0]}`

    return ''
  }

  const markedInvoices = allInvoices?.filter((invoiceItem) =>
    invoicesId?.includes(invoiceItem.invoice_id)
  )

  async function getPaymentMethods(): Promise<any> {
    try {
      const token = sessionStorage.getItem('token')
      const { data } = await newApi.get(
        `/mobile/payment/payment-methods?merchant=${process.env.REACT_APP_MERCHANT_FIXED}`,
        {
          headers: {
            Authorization: `Basic ${token}`,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )
      localStorage.setItem(
        'payment_methods',
        JSON.stringify(data.payment_methods)
      )
      return data.payment_methods
    } catch (error) {
      console.log(error)
    }
    return undefined
  }

  function copyBarCode(inv: any): void {
    if (inv.invoice_id) setShowInfo(inv.invoice_id)
    const dummy = document.createElement('textarea')
    document.body.appendChild(dummy)
    dummy.value = inv.barcode || ''
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)
    setTimeout(() => {
      setShowInfo(undefined)
    }, THREE_SECONDS)
  }

  useEffect(() => {
    getPaymentMethods()
  }, [])

  function changePage(nextPage: boolean): void {
    let nextPageValue = page
    if (nextPage) {
      nextPageValue += 1
    } else {
      nextPageValue -= 1
    }
    setPage(nextPageValue)
    const refreshRef = localStorage.getItem('refreshRef')
    if (refreshRef && refreshRef.length) {
      getFromRef(refreshRef, nextPageValue)
    } else {
      const docField = localStorage.getItem('refreshDoc')
      if (docField && docField.length) {
        getFromDoc(docField, nextPageValue)
      }
    }
  }

  function changeMaxPage(maxPage: number): void {
    setPageSize(maxPage)
    const refreshRef = localStorage.getItem('refreshRef')
    if (refreshRef && refreshRef.length) {
      setPage(1)
      getFromRef(refreshRef, 1, maxPage)
    } else {
      const docField = localStorage.getItem('refreshDoc')
      if (docField && docField.length) {
        setPage(1)
        getFromDoc(docField, 1, maxPage)
      }
    }
  }

  async function getFromRef(
    refreshRef: string,
    nextPageValue: number,
    localPageSize?: number
  ): Promise<void> {
    setLoadingData(true)
    try {
      const data = await handleUC(
        refreshRef,
        nextPageValue,
        localPageSize || pageSize
      )
      if (data.invoices && data.invoices.length > 0) {
        const temp = [...allInvoices, ...data.invoices]
        const removedDups = removeDuplicatesInvoiceId(temp)

        localStorage.setItem('Invoices', JSON.stringify(removedDups))
        localStorage.setItem('refreshRef', refreshRef)
        setInvoices(data.invoices)
        setAllInvoices(removedDups)
        if (data.invoices.length < (localPageSize || pageSize)) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }
      }
      setTotal(data.total || 0)
    } catch (error) {
      console.log(error)
      setHasMore(false)
    } finally {
      setLoadingData(false)
    }
  }

  async function getFromDoc(
    docField: string,
    nextPageValue: number,
    localPageSize?: number
  ): Promise<void> {
    setLoadingData(true)
    try {
      const data = await handleConsultDoc(
        docField,
        nextPageValue,
        localPageSize || pageSize
      )
      if (data.invoices && data.invoices.length > 0) {
        localStorage.setItem('refreshDoc', docField)

        const temp = [...allInvoices, ...data.invoices]
        const removedDups = removeDuplicatesInvoiceId(temp)
        localStorage.setItem('Invoices', JSON.stringify(removedDups))

        setInvoices(data.invoices)
        setAllInvoices(removedDups)
        if (data.invoices.length < (localPageSize || pageSize)) {
          setHasMore(false)
        } else {
          setHasMore(true)
        }
      }
      setTotal(data.total || 0)
    } catch (error) {
      console.log(error)
      setHasMore(false)
    } finally {
      setLoadingData(false)
    }
  }

  return (
    <S.Container>
      <div style={{ display: 'none', opacity: 0 }}>
        {downloadThis && <PaymentSlipBoletoInvoice invoice={downloadThis} />}
      </div>
      {selectedInvoice ? (
        <InvoiceDetailModal
          invoiceItem={selectedInvoice}
          generatingColorStatus={generatingColorStatus}
          generatingTextStatus={generatingTextStatus}
          invoiceDescription={invoiceDescription}
          closeModal={() => {
            setSelectedInvoice(undefined)
          }}
        />
      ) : null}
      <S.BoxInfoDownload
        style={{
          opacity: !showInfoDownloadFile ? 0 : 1,
          bottom: !showInfoDownloadFile ? 0 : undefined,
          left: !showInfoDownloadFile ? 0 : 8,

          transition: '0.5s ease',
          padding: 0,
          display: showInfoDownloadFile ? 'flex' : 'none',
        }}
      >
        <Lottie
          style={{ width: 20, height: 20, margin: 0, padding: 0 }}
          options={{
            autoplay: true,
            loop: true,
            animationData: downloadLottie,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          isStopped={!showInfoDownloadFile}
          height={20}
          width={20}
        />
        <span>Salvando Fatura</span>
      </S.BoxInfoDownload>
      <S.BoxInfoCopy
        style={{
          opacity: !showInfo ? 0 : 1,
          bottom: !showInfo ? 0 : undefined,
          left: !showInfo ? 0 : 8,
          transition: 'all 0.5s ease',
          padding: 0,
          display: showInfo ? 'flex' : 'none',
        }}
      >
        <Lottie
          style={{
            width: 20,
            height: 20,
            margin: '0px 0px 0px 0px',
            padding: 0,
          }}
          options={{
            autoplay: true,
            loop: true,
            animationData: copyLottie,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice',
            },
          }}
          isStopped={!showInfo}
          height={20}
          width={20}
        />
        <span>Código de barras copiado</span>
      </S.BoxInfoCopy>
      <S.Box>
        <S.Left>
          {!loadingData ? (
            <>
              <div
                className="leftHeader"
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <S.Title>Débitos</S.Title>
              </div>
              {unPaidInvoices && unPaidInvoices.length ? (
                <S.DebitsGrid>
                  {unPaidInvoices?.map((invoiceItem) => {
                    return (
                      <S.BoxInvoiceRight
                        key={invoiceItem.invoice_id}
                        id="invoiceBox"
                        style={{
                          top: 0,
                          transition: '0.5s',
                        }}
                      >
                        <GlobalRow
                          className="box_invoice_right-100"
                          justifyContent="space-between"
                        >
                          <GlobalRow>
                            <p className="box_invoice_header">{`Fatura ${invoiceItem.refDate}`}</p>
                          </GlobalRow>
                          <S.BoxInvoiceStatus
                            data-tip
                            data-for={`infoSituationInvoices${invoiceItem.id}`}
                            backgroundColor={generatingBackgroundColorStatus(
                              invoiceItem
                            )}
                            circleColor={generatingColorStatus(invoiceItem)}
                          >
                            <div className="invoice_status_circle" />
                            {generatingTextStatus(invoiceItem)}
                          </S.BoxInvoiceStatus>

                          <ReactTooltip
                            id={`infoSituationInvoices${invoiceItem.id}`}
                            place="top"
                            type="dark"
                            effect="solid"
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 5,
                                alignItems: 'flex-start',
                              }}
                            >
                              {generatingTextStatus(invoiceItem)}
                            </div>
                          </ReactTooltip>
                        </GlobalRow>
                        <GlobalColumn
                          alignItems="flex-start"
                          className="box_invoice_right-100"
                        >
                          <span className="valueAndDate">
                            {StringToCurrency(
                              `${invoiceItem.totalPaymentDue || 0}`
                            )}
                          </span>
                          <GlobalRow
                            className="box_invoice_right-100"
                            justifyContent="space-between"
                          >
                            <span className="box_invoice_venc">
                              {`Venc. ${
                                /* invoiceItem.DT_VENCIMENTO_EDT */ invoiceItem.paymentDueDate
                                  ? transformDate(invoiceItem.paymentDueDate)
                                  : ''
                              }`}
                            </span>

                            <span className="box_invoice_venc">267 kWh</span>
                          </GlobalRow>
                        </GlobalColumn>
                        <GlobalRow
                          className="box_invoice_right-100 box_invoice_border-top"
                          justifyContent="space-between"
                        >
                          {getSituation(invoiceItem) === 0 ? (
                            <S.BoxInvoiceBtn
                              backgroundColor={
                                checkIdList(invoiceItem.invoice_id)
                                  ? '#00AB50'
                                  : '#eeeeee'
                              }
                              labelColor={
                                checkIdList(invoiceItem.invoice_id)
                                  ? '#FFF'
                                  : '#212121'
                              }
                              type="button"
                              onClick={() => {
                                handlePaymentButton(invoiceItem)
                              }}
                            >
                              {checkIdList(invoiceItem.invoice_id) ? (
                                <div>
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    style={{ marginRight: 10 }}
                                  />
                                  Selecionada
                                </div>
                              ) : (
                                'Selecionar fatura'
                              )}
                            </S.BoxInvoiceBtn>
                          ) : null}
                          <GlobalRow gap="16px" justifyContent="flex-end">
                            {invoiceItem.barcode ? (
                              <S.InvoiceIconButton
                                onClick={() => {
                                  copyBarCode(invoiceItem)
                                }}
                              >
                                <img alt="barcode" src={barcodeScannerIcon} />
                              </S.InvoiceIconButton>
                            ) : null}
                            {invoiceItem.barcode ? (
                              <S.InvoiceIconButton
                                onClick={() => {
                                  downloadPdf(invoiceItem)
                                }}
                              >
                                <img alt="download" src={iconDownload} />
                              </S.InvoiceIconButton>
                            ) : null}
                          </GlobalRow>
                        </GlobalRow>
                      </S.BoxInvoiceRight>
                    )
                  })}
                </S.DebitsGrid>
              ) : (
                <S.NoUnpaidContainer>
                  <img src={allCompleteImg} alt="parabéns!" />

                  <GlobalColumn alignItems="flex-start">
                    <p className="no_unpaid_title">Parábens!</p>
                    <p className="no_unpaid_label">
                      Todas as suas contas estão em dia.
                    </p>
                    <p className="no_unpaid_label">
                      Obrigado por manter tudo organizado com o Bill!
                    </p>
                  </GlobalColumn>
                </S.NoUnpaidContainer>
              )}
            </>
          ) : null}

          {paidInvoices && paidInvoices?.length > 0 ? (
            <>
              <div
                className="leftHeader"
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <S.Title>Faturas</S.Title>
              </div>

              <S.TableHeader className="grid_container_row">
                <p>FATURA</p>
                <p>VENCIMENTO</p>
                <p>CONSUMO</p>
                <div className="grid_container_center grid_container_end">
                  <p>VALOR (R$)</p>
                </div>
                <p>SITUAÇÃO</p>
                <div />
              </S.TableHeader>
              <S.BoxInvoicesPending
                style={{
                  // marginBottom: totalCart <= 0 ? 0 : 70,
                  transition: '0.3s',
                }}
              >
                {paidInvoices?.map((invoiceItem) => {
                  return (
                    <InvoiceItem
                      checkDueDate={checkDueDate}
                      checkDueStatus={checkDueStatus}
                      checkIdList={checkIdList}
                      generatingColorStatus={generatingColorStatus}
                      generatingTextStatus={generatingTextStatus}
                      handlePaymentButton={handlePaymentButton}
                      invoiceDescription={invoiceDescription}
                      invoiceItem={invoiceItem}
                    />
                  )
                })}
              </S.BoxInvoicesPending>
            </>
          ) : null}

          {!loadingData ? (
            invoices && invoices.length > 0 ? (
              filteredInvoices && filteredInvoices.length ? null : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <span
                    style={{
                      fontFamily: 'Inter',
                      color: '#2B2B2B',
                      fontSize: 36,
                      fontWeight: 500,
                      marginTop: 20,
                    }}
                  >
                    Nenhuma fatura encontrada
                  </span>
                  <span
                    style={{
                      fontFamily: 'Inter',
                      color: '#2B2B2B',
                      fontSize: 24,
                      fontWeight: 400,
                    }}
                  >
                    Mude os filtros e tente novamente
                  </span>
                </div>
              )
            ) : null
          ) : (
            // Shimmer Efects
            <img
              src={loadingPrimaryIcon}
              alt="Carregando"
              style={{ height: 140, margin: 0 }}
            />
          )}
          <S.BoxPrice
            style={{
              opacity: totalCart <= 0 ? 0 : 1,
              bottom: totalCart <= 0 ? '0px' : '50px',
              transition: '0.5s ease',
              padding: 10,
              cursor: totalCart <= 0 ? 'default' : '',
              display: 'none',
            }}
          >
            <div
              style={{
                display: 'flex',
                gap: 10,
                flexDirection: 'row',
                height: '100%',
                alignItems: 'center',
              }}
            >
              <span
                style={{
                  width: 227,
                  height: 40,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 8,
                  background: '#EDF1F5',
                }}
              >
                {invoicesId?.length &&
                  `FATURA${invoicesId?.length > 1 ? 'S' : ''} SELECIONADA${
                    invoicesId?.length > 1 ? 'S' : ''
                  } - ${invoicesId?.length}`}
              </span>
              <div
                style={{
                  justifyContent: 'center',
                  borderRadius: 8,
                  display: 'flex',
                  width: 227,
                  height: 40,
                  flexDirection: 'row',
                  alignItems: 'center',
                  gap: 10,
                  background: '#EDF1F5',
                }}
              >
                {`TOTAL - ${StringToCurrency(`${totalCart || 0}`)}`}
                <img
                  style={{
                    opacity: originalValue !== totalCart ? 1 : 0,
                    display: originalValue !== totalCart ? 'flex' : 'none',
                    transition: 'opacity 0.3s',
                    height: 20,
                  }}
                  data-tip
                  data-for="infoFees"
                  src={iconInfoGreen}
                  alt="Juros e multa"
                />
              </div>
            </div>
            <button
              type="button"
              onClick={() => {
                paymentHandle()
              }}
              disabled={totalCart <= 0 || loading}
            >
              {loading ? (
                <img
                  src={loadingIcon}
                  alt="Carregando"
                  style={{ height: 40, margin: 0 }}
                />
              ) : (
                <>
                  <span>INICIAR PAGAMENTO</span>
                  <FontAwesomeIcon icon={faArrowRight} color="#fff" />
                </>
              )}
            </button>
          </S.BoxPrice>

          <S.BoxPriceMobile
            onClick={() => {
              paymentHandle()
            }}
            disabled={totalCart <= 0}
            style={{
              zIndex: totalCart <= 0 ? 10 : 20,
              opacity: totalCart <= 0 ? 0 : 1,
              cursor: totalCart <= 0 ? 'default' : '',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <span>
                {invoicesId?.length
                  ? `${invoicesId?.length} FATURA${
                      invoicesId?.length > 1 ? 'S' : ''
                    }`
                  : ''}
              </span>
              <span style={{ fontSize: 19, fontWeight: 600 }}>
                {`${StringToCurrency(`${totalCart || 0}`)}`}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                overflow: 'hidden',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 10,
              }}
            >
              <img
                style={{
                  overflow: 'hidden',
                  opacity: originalValue !== totalCart ? 1 : 0,
                  transition: 'opacity 0.3s',
                }}
                data-tip
                data-for="infoFees"
                src={iconInfoGreen}
                alt="Juros e multa"
              />
              <span style={{ fontSize: 19, fontWeight: 600, marginRight: 10 }}>
                PAGAR
              </span>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 34,
                  width: 34,
                  borderRadius: 8,
                  backgroundColor: '#ECF1DF',
                }}
              >
                {loading ? (
                  <img
                    src={loadingPrimaryIcon}
                    alt="Carregando"
                    style={{ height: 30, margin: 0 }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    color="var(--primaryColorDark)"
                  />
                )}
              </div>
            </div>
          </S.BoxPriceMobile>
        </S.Left>

        <S.Right>
          <S.Title>Pagamento</S.Title>
          <S.TitleMobile>Débitos</S.TitleMobile>
          {/* <S.InputSearchContainerMobile>
            <img alt="search" src={searchIcon} />
            <input
              placeholder="Número da guia"
              value={filterText}
              type="tel"
              onChange={(e) => {
                setFilterText(e.target.value || '')
              }}
            />
          </S.InputSearchContainerMobile> */}
          <S.BoxAllInvoices>
            {!loadingData ? (
              markedInvoices?.length ? (
                <>
                  <S.InvoiceListHeader>
                    <p>DESCRIÇÃO</p>
                    <p>VALOR (R$)</p>
                  </S.InvoiceListHeader>

                  {markedInvoices.map((invoiceItem) => {
                    return (
                      <S.InvoiceRightItem>
                        <p className="invoice_right_value_container">
                          {invoiceItem.refDate}
                        </p>
                        <div className="invoice_right_value_container">
                          <p>
                            {`${StringToCurrency(
                              `${invoiceItem.totalPaymentDue || 0}`
                            )}`}
                          </p>
                          <FontAwesomeIcon
                            onClick={() => {
                              handlePaymentButton(invoiceItem)
                            }}
                            icon={faTrash}
                            color="#CC2E1F"
                          />
                        </div>
                      </S.InvoiceRightItem>
                    )
                  })}
                  <S.BoxInvoicesFooter>
                    <button
                      onClick={() => {
                        paymentHandle()
                      }}
                      type="button"
                      className="invoice_footer_button"
                    >
                      {loading ? (
                        <img
                          src={loadingIcon}
                          alt="Carregando"
                          style={{ height: 40, margin: 0 }}
                        />
                      ) : (
                        <>Pagar {`${StringToCurrency(`${totalCart || 0}`)}`}</>
                      )}
                    </button>
                    <OmniChannelComponent />
                  </S.BoxInvoicesFooter>
                </>
              ) : (
                <>
                  <S.EmptyInvoice>
                    <img alt="empty" src={emptyInvoiceIcon} />
                    <p>
                      Selecione os débitos ao lado para prosseguir com o
                      pagamento.
                    </p>
                  </S.EmptyInvoice>

                  <S.EmptyInvoiceFooter>
                    <OmniChannelComponent />
                  </S.EmptyInvoiceFooter>
                </>
              )
            ) : (
              // Shimmer Efects
              <>
                <div
                  style={{
                    width: '100%',
                    height: 50,
                    background: 'var(--secondaryColor)',
                  }}
                />
                <S.Invoice>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                      padding: '20px 20px 15px 0px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <S.Skeleton
                            style={{
                              width: 25,
                              height: 25,
                              borderRadius: '50%',
                              marginRight: 20,
                            }}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <S.Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <S.Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <S.Skeleton
                            style={{
                              width: 80,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <S.Skeleton
                      style={{
                        width: 80,
                        height: 25,
                        borderRadius: 16,
                      }}
                    />
                  </div>
                </S.Invoice>
                <S.Invoice>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                      padding: '20px 20px 15px 0px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <S.Skeleton
                            style={{
                              width: 25,
                              height: 25,
                              borderRadius: '50%',
                              marginRight: 20,
                            }}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <S.Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <S.Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <S.Skeleton
                            style={{
                              width: 80,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <S.Skeleton
                      style={{
                        width: 80,
                        height: 25,
                        borderRadius: 16,
                      }}
                    />
                  </div>
                </S.Invoice>
                <S.Invoice>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                      padding: '20px 20px 15px 0px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <S.Skeleton
                            style={{
                              width: 25,
                              height: 25,
                              borderRadius: '50%',
                              marginRight: 20,
                            }}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <S.Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <S.Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <S.Skeleton
                            style={{
                              width: 80,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <S.Skeleton
                      style={{
                        width: 80,
                        height: 25,
                        borderRadius: 16,
                      }}
                    />
                  </div>
                </S.Invoice>
                <S.Invoice>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                      padding: '20px 20px 15px 0px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <S.Skeleton
                            style={{
                              width: 25,
                              height: 25,
                              borderRadius: '50%',
                              marginRight: 20,
                            }}
                          />
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex-start',
                        }}
                      >
                        <span>
                          <S.Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <S.Skeleton
                            style={{
                              width: 120,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                        <span>
                          <S.Skeleton
                            style={{
                              width: 80,
                              height: 10,
                              margin: 5,
                            }}
                          />
                        </span>
                      </div>
                    </div>
                    <S.Skeleton
                      style={{
                        width: 80,
                        height: 25,
                        borderRadius: 16,
                      }}
                    />
                  </div>
                </S.Invoice>
              </>
            )}
          </S.BoxAllInvoices>
          <S.BoxAllInvoicesMobile isTotal={totalCart > 0}>
            {!loadingData ? (
              invoices?.length ? (
                <>
                  {filteredInvoices?.map((invoiceItem, index) => {
                    return (
                      <S.BoxInvoiceRight
                        key={invoiceItem.invoice_id}
                        id="invoiceBox"
                        style={{
                          top: 0,
                          transition: '0.5s',
                        }}
                        onClick={() => {
                          handlePaymentButton(invoiceItem)
                        }}
                      >
                        <GlobalRow
                          className="box_invoice_right-100"
                          justifyContent="space-between"
                        >
                          <GlobalRow>
                            <p className="box_invoice_header">{`Fatura ${invoiceItem.refDate}`}</p>
                          </GlobalRow>
                          <S.BoxInvoiceStatus
                            data-tip
                            data-for={`infoSituationInvoices${invoiceItem.id}`}
                            backgroundColor={generatingBackgroundColorStatus(
                              invoiceItem
                            )}
                            circleColor={generatingColorStatus(invoiceItem)}
                          >
                            <div className="invoice_status_circle" />
                            {generatingTextStatus(invoiceItem)}
                          </S.BoxInvoiceStatus>

                          <ReactTooltip
                            id={`infoSituationInvoices${invoiceItem.id}`}
                            place="top"
                            type="dark"
                            effect="solid"
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 5,
                                alignItems: 'flex-start',
                              }}
                            >
                              {generatingTextStatus(invoiceItem)}
                            </div>
                          </ReactTooltip>
                        </GlobalRow>
                        <GlobalColumn
                          alignItems="flex-start"
                          className="box_invoice_right-100"
                        >
                          <span className="valueAndDate">
                            {StringToCurrency(
                              `${invoiceItem.totalPaymentDue || 0}`
                            )}
                          </span>
                          <GlobalRow
                            className="box_invoice_right-100"
                            justifyContent="space-between"
                          >
                            <span className="box_invoice_venc">
                              {`Venc. ${
                                /* invoiceItem.DT_VENCIMENTO_EDT */ invoiceItem.paymentDueDate
                                  ? transformDate(invoiceItem.paymentDueDate)
                                  : ''
                              }`}
                            </span>

                            <span className="box_invoice_venc">267 kWh</span>
                          </GlobalRow>
                        </GlobalColumn>
                        <GlobalRow
                          className="box_invoice_right-100 box_invoice_border-top"
                          justifyContent="space-between"
                        >
                          {getSituation(invoiceItem) === 0 ? (
                            <S.BoxInvoiceBtn
                              backgroundColor={
                                checkIdList(invoiceItem.invoice_id)
                                  ? '#00AB50'
                                  : '#eeeeee'
                              }
                              labelColor={
                                checkIdList(invoiceItem.invoice_id)
                                  ? '#FFF'
                                  : '#212121'
                              }
                              type="button"
                            >
                              {checkIdList(invoiceItem.invoice_id) ? (
                                <div>
                                  <FontAwesomeIcon
                                    icon={faCheckCircle}
                                    style={{ marginRight: 10 }}
                                  />
                                  Selecionada
                                </div>
                              ) : (
                                'Selecionar fatura'
                              )}
                            </S.BoxInvoiceBtn>
                          ) : null}
                          <GlobalRow gap="16px" justifyContent="flex-end">
                            {invoiceItem.barcode ? (
                              <S.InvoiceIconButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  copyBarCode(invoiceItem)
                                }}
                              >
                                <img alt="barcode" src={barcodeScannerIcon} />
                              </S.InvoiceIconButton>
                            ) : null}
                            {invoiceItem.barcode ? (
                              <S.InvoiceIconButton
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  downloadPdf(invoiceItem)
                                }}
                              >
                                <img alt="download" src={iconDownload} />
                              </S.InvoiceIconButton>
                            ) : null}
                          </GlobalRow>
                        </GlobalRow>
                      </S.BoxInvoiceRight>
                    )
                    // return (
                    //   <S.InvoiceItemMobile
                    //     key={`${invoiceItem.invoice_id}-${index}`}
                    //     onClick={() => {
                    //       handlePaymentButton(invoiceItem)
                    //     }}
                    //   >
                    //     <div className="invoice_mobile_column">
                    //       <S.InvoiceMobileCheck
                    //         isDisabled={
                    //           !checkDueDate(invoiceItem.paymentDueDate) ||
                    //           !checkDueStatus(invoiceItem)
                    //         }
                    //         isActive={checkIdList(invoiceItem.invoice_id)}
                    //       >
                    //         <FontAwesomeIcon
                    //           className="mobile_check_icon"
                    //           icon={faCheck}
                    //         />
                    //       </S.InvoiceMobileCheck>
                    //     </div>
                    //     <div className="invoice_mobile_container">
                    //       <div className="invoice_mobile_space-between">
                    //         <p className="invoice_mobile_label">
                    //           {invoiceItem.id}
                    //           {invoiceItem.paymentDueDate
                    //             ? ` Venc. ${
                    //                 invoiceItem.paymentDueDate
                    //                   ? transformDate(
                    //                       invoiceItem.paymentDueDate
                    //                     )
                    //                   : ''
                    //               }`
                    //             : ''}
                    //         </p>
                    //         <p className="invoice_mobile_label">Valor</p>
                    //       </div>
                    //       <div className="invoice_mobile_space-between invoice_mobile_mt-4">
                    //         {/* <div
                    //           style={{
                    //             display: 'grid',
                    //             maxWidth:'75%'
                    //           }}
                    //         >
                    //           <p className="invoice_mobile_title invoice_mobile_ellipsis">
                    //             {invoiceDescription(invoiceItem)}
                    //           </p>
                    //         </div> */}

                    //         <p className="invoice_mobile_title invoice_mobile_aling-left">
                    //           {invoiceDescription(invoiceItem)}
                    //         </p>

                    //         <p className="invoice_mobile_title invoice_mobile_aling-right">
                    //           {`${StringToCurrency(
                    //             `${invoiceItem.totalPaymentDue || 0}`
                    //           )}`}
                    //         </p>
                    //       </div>
                    //       <div className="invoice_mobile_line invoice_mobile_mt-12" />
                    //       <div className="invoice_mobile_space-between invoice_mobile_mt-12">
                    //         <div className="invoice_mobile_row">
                    //           <S.InvoiceItemStatus
                    //             color={generatingColorStatus(invoiceItem)}
                    //           />
                    //           <p className="invoice_mobile_label">
                    //             {generatingTextStatus(invoiceItem) || '-'}
                    //           </p>
                    //         </div>
                    //         <S.MobileDetailButton
                    //           onClick={(e) => {
                    //             e.preventDefault()
                    //             e.stopPropagation()
                    //             setSelectedInvoice(invoiceItem)
                    //           }}
                    //         >
                    //           Ver detalhes
                    //         </S.MobileDetailButton>
                    //       </div>
                    //     </div>
                    //   </S.InvoiceItemMobile>
                    // )
                  })}
                </>
              ) : (
                <S.EmptyInvoice>
                  <img alt="empty" src={emptyInvoiceIcon} />
                  <p>Nenhuma pendência encontrada</p>
                  <OmniChannelComponent />
                </S.EmptyInvoice>
              )
            ) : (
              // Shimmer Efects
              <img
                src={loadingPrimaryIcon}
                alt="Carregando"
                style={{ height: 140, margin: 0 }}
              />
            )}
            {/* <div id="mobile">
              <Footer />
            </div> */}
          </S.BoxAllInvoicesMobile>

          <S.BoxPriceMobile
            onClick={() => {
              paymentHandle()
            }}
            disabled={totalCart <= 0}
            style={{
              zIndex: totalCart <= 0 ? 10 : 20,
              opacity: totalCart <= 0 ? 0 : 1,
              cursor: totalCart <= 0 ? 'default' : '',
              bottom: totalCart <= 0 ? -60 : 0,
              // display: totalCart <=0 ? 'none' : 'flex',

              // height: totalCart <=0 ? 0 : 60,
            }}
          >
            <span style={{ fontSize: 19, fontWeight: 600 }}>
              {`Total ${StringToCurrency(`${totalCart || 0}`)}`}
            </span>
            {
              <div className="box_price_mobile_continue">
                {loading ? (
                  <img
                    src={loadingPrimaryIcon}
                    alt="Carregando"
                    style={{ height: 30, margin: 0 }}
                  />
                ) : (
                  <>
                    {' '}
                    <span>Continuar</span>
                    <FontAwesomeIcon icon={faArrowRight} color="#212121" />
                  </>
                )}
                {/* <img
                style={{
                  overflow: 'hidden',
                  opacity: originalValue !== totalCart ? 1 : 0,
                  transition: 'opacity 0.3s',
                }}
                data-tip
                data-for="infoFees"
                src={iconInfoGreen}
                alt="Juros e multa"
              /> */}
              </div>
            }
          </S.BoxPriceMobile>
        </S.Right>
      </S.Box>
      <ReactTooltip
        id="infoFees"
        disable={originalValue === totalCart}
        place="top"
        type="dark"
        effect="solid"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 5,
            alignItems: 'flex-start',
          }}
        >
          <h3>
            Valor original:
            <span>{` ${StringToCurrency(`${originalValue || 0}`)}`}</span>
          </h3>
          <span>Devido ao atraso, serão cobrados:</span>
          <h4>{`Juros: ${StringToCurrency(`${fees || 0}`)}`}</h4>
          <h4>{`Multas: ${StringToCurrency(`${penalty || 0}`)}`}</h4>
          <h4>{`Correção: ${StringToCurrency(`${correction || 0}`)}`}</h4>
          <h3>
            Valor Atualizado:
            <span>{` ${StringToCurrency(`${totalCart || 0}`)}`}</span>
          </h3>
        </div>
      </ReactTooltip>
    </S.Container>
  )
}

export default connect((state: StateInitial) => ({ modules: state }))(Home)
