import styled, { css } from 'styled-components'

export const Container = styled.div`
  background-color: #fff;
  // font-family: 'Arial', 'sans-serif';
  font-family: Inter;
  letter-spacing: 0;
  position: relative;

  .invoice_custas_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .invoice_max_width {
    width: 100%;
  }
  .invoice_row_between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .invoice_padding_left-16{
    padding-left: 16px;
  }
  .invoices_custas_label {
    font-size: 12px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #212121;
  }
  .invoices_custas_title {
    font-size: 12px;
    font-weight: 700;
    font-weight: bold;
    line-height: 24px;
    text-align: right;

    color: #212121;
  }
`

export const Span = styled.span`
  font-size: 10px;
  color: var(--secondaryColor);
`

export const SpanInfo = styled.span`
  font-size: 13px;
`

export const TableInvoices = styled.div`
  border: 1px solid #000;
  //font-family: 'Arial', 'sans-serif';
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  width: 100%;
  border-radius: 9px;
  padding: 0px;
  margin-bottom: 5px;
  div:first-child {
    border-top: none;
  }
  > div {
    border-top: 2px solid #000;
    padding: 5px;
    width: 100%;
    > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      > div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span:first-child {
          font-size: 12px;
          font-weight: 700;
          font-weight: bold;
        }
        span {
          font-size: 6px;
        }
      }
    }
  }
`

export const Line = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #212121;
`
export const WeakLine = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #dddddd;
`

export const BlackLine = styled.div`
  margin-top: 7px;
  margin-bottom: 7px;
  display: flex;
  width: 100%;
  height: 1px;
  background-color: #000;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`

export const InvoiceRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
`
export const HeaderComponentTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: Inter;
  font-size: 12px;
  font-weight: 700;
  font-weight: bold;
  line-height: 16px;
  text-align: left;
  color: #212121;
`

interface GridContainerProps {
  gridRemplateColumns?: string
  customGap?:string
}
export const GridContainer = styled.div<GridContainerProps>`
  ${({ gridRemplateColumns,customGap }) => css`
    display: grid;
    grid-template-columns: ${gridRemplateColumns || '1fr 1fr'};
    color: #212121;
    gap: ${customGap||'6px'};
    width: 100%;
  `}
`
export const InfoTitle = styled.p`
  font-family: Inter;
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #212121;
`
export const InfoLabel = styled.p`
  font-family: Inter;
  font-size: 8px;
  font-weight: 700;
  font-weight: bold;
  line-height: 12px;
  text-align: left;

  color: #212121;
`

export const InfoProofTitle = styled.p`
  font-family: Inter;
  font-size: 8px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: #212121;
`
export const InfoProofLabel = styled.p`
  font-family: Inter;
  font-size: 6px;
  font-weight: 700;
  font-weight: bold;
  line-height: 10px;
  text-align: left;

  color: #212121;
`

export const InfoProofInvoiceLabel = styled.p`
  font-family: Inter;
  font-size: 6px;
  font-weight: 400;
  font-weight: normal;
  line-height: 10px;
  text-align: left;

  color: #212121;
`


export const InfoProofInvoiceLabelBold = styled.p`
  font-family: Inter;
  font-size: 6px;
  font-weight: 700;
  font-weight: bold;
  line-height: 10px;
  text-align: left;

  color: #212121;
`

export const InfoProofSubLabel = styled.p`
  font-family: Inter;
  font-size: 6px;
  font-weight: 400;
  font-weight: bold;
  line-height: 10px;
  text-align: left;

  color: #212121;
`


export const SlipFooter = styled.div`
  width: 420px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;

  font-family: Inter;
  font-size: 8px;
  font-weight: 700;
  font-weight: bold;
  line-height: 12px;
  text-align: left;

  color: #212121;

  .slipfooter_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }
  .slipfooter_label {
    font-weight: 400;
  }
`
