import { isAfter } from 'date-fns'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  font-family: Inter;

  #mobile {
    display: none;
  }
  #web {
    display: flex;
  }

  .shake_item {
    animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    @keyframes shake {
      10%,
      90% {
        transform: translate3d(-1px, 0, 0);
      }

      20%,
      80% {
        transform: translate3d(2px, 0, 0);
      }

      30%,
      50%,
      70% {
        transform: translate3d(-4px, 0, 0);
      }

      40%,
      60% {
        transform: translate3d(4px, 0, 0);
      }
    }
  }

  @media only screen and (max-width: 768px) {
    overflow: scroll;
    padding-bottom: 60px;
    #mobile {
      display: flex;
    }
    #web {
      display: none;
    }
  }
`

export const Box = styled.div`
  /* width: 818px; */
  width: 100vw;
  max-width: 1000px;
  @media only screen and (max-width: 768px) {
    width: 100vw;
    padding: 10px;
    overflow: scroll;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .header_row {
    gap: 16px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  margin-bottom: 10px;
  gap: 16px;
  @media only screen and (max-width: 768px) {
    padding-top: 48px;
  gap: 6px;
  .header_row {
    gap: 6px;
  }
  }
`

export const Title = styled.span`
  color: #212121;
  font-size: 24px;
  font-family: 'Inter';
  line-height: 32px;
  font-weight: 500;
  @media only screen and (max-width: 768px) {
    font-size: 18px;
  }
`

export const BtnBack = styled.button`
  border-radius: 8px;
  padding: 10px;
  gap: 10px;
  box-shadow: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  color: #212121;
  background-color: #fff;
  transition: 300ms;
  border: 1px solid #fff;
  font-family: 'Inter';
  font-weight: 500;
  &:hover {
    color: #212121;
    border: 1px solid #064076;
  }
  &:active {
    background-color: #eee;
  }
`

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`

export const Left = styled.div`
  /* width: 394px; */
  width: 710px;
  display: flex;
  flex: 1;
  flex-direction: column;
  /* justify-content: flex-start; */
  height: 437px;
  gap: 16px;
  padding-bottom: 16px;
  border-radius: 16px;
  .left_body {
    background: #fff;
    padding-bottom: 16px;
    border-radius: 16px;
  }
  /* margin-bottom: 200px; */

  .div_header {
    padding: 15px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    background-color: #ffffff;
    color: #212121;
    font-weight: 500;
    font-size: 18px;
    margin: 0;
  }

  &::-webkit-scrollbar {
    width: 8px;
    height: 0px;
    background-color: transparent;
    position: absolute !important;
    right: 10px !important;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 20px;
    transform: translate(20px) !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #9e9e9e;
    border-radius: 20px;
  }
  /* > div {
    padding: 15px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    background-color: #ffffff;
    color: #212121;
    font-weight: 500;
    font-size: 18px;
    margin: 0;
  } */
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }

  @media only screen and (min-width: 769px) {
    overflow-y: scroll;
    height: calc(100vh - 230px);
  }
`

export const Right = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background: #fff;
  border-radius: 8px;
  width: 394px;

  .right_header {
    padding: 24px;
    padding-bottom: 8px;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #212121;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: sticky;
    top: 0;
    background: #fff;

    z-index: 2;
    @media only screen and (max-width: 768px) {
      border-radius: 8px;
    }
  }

  .right_line {
    height: 1px;
    width: 100%;
    background: #dddddd;
  }

  .right_div {
    display: flex;
    flex-direction: column;
    position: relative;
    > div:first-child {
      padding: 15px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      background-color: #ffffff;
      color: #212121;
      font-weight: 500;
      font-size: 18px;
      margin: 0;
    }
    > span {
      padding: 0 15px;
    }
    margin-bottom: 10px;
    width: 394px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    > div {
      margin-bottom: 20px;
      width: 100%;
    }
  }

  .right_label {
    color: #212121;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
  .right_strong {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
  }
  .right_row_between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .right_max_width-200 {
    max-width: 200px;
  }

  .invoice_footer_bill {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #212121;
    gap: 8px;
    img {
      height: 16px;
    }
  }

  .right_ph-16 {
    padding-left: 16px;
    padding-right: 16px;
  }
  .right_pl-16 {
    padding-left: 16px;
  }
  .right_mt-4 {
    margin-top: 4px;
  }
  .right_mt-16 {
    margin-top: 16px;
  }
  .right_mt-24 {
    margin-top: 24px;
  }
  @media only screen and (min-width: 769px) {
    overflow-y: scroll;
    padding-bottom: 128px;
    height: calc(100vh - 230px);
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`

export const ScrollInvoice = styled.div`
  height: 150px;
  padding: 0 15px;
  overflow: scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const InvoiceDetails = styled.div`
  border: 1px solid var(--primaryColorDark);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 9px;
  margin-bottom: 10px;
  border-radius: 8px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    /* width: 100vw; */
  }
`

export const TitleInvoiceDetails = styled.span`
  font-size: 16px;
  color: #494949;
  font-family: 'Inter';
  font-weight: 500;
`

export const DescriptionInvoice = styled.span`
  font-size: 13px;
  font-family: 'Inter';
  color: #858585;
`

export const ButtonShare = styled.button`
  border: none;
  height: 40px;
  background: #ffffff;
  color: #212121;
  font-family: 'Inter';
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  width: 100%;
  max-width: 240px;
  gap: 16px;
  @media only screen and (max-width: 768px) {
    font-size: 10px;
    font-weight: 400;
    line-height: 12px;
    text-align: left;

    width: 100%;
    max-width: 164px;
    gap: 6px;
  }
`
export const ButtonPay = styled.button`
  border: none;
  height: 40px;
  background: #00ab50;
  color: #fff;
  font-family: 'Inter';
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  width: 100%;
`

export const PixContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  background-color: #fff !important;
  gap: 8px;

  .pix_container_copy_button {
    border: none;
    background: #f96b30;
    color: #fff;
  }

  .pix_container_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    font: normal normal normal 12px/15px Roboto;
    color: #212121;
    text-align: center;

    .pix_container_13px {
      font: normal normal normal 13px/18px Roboto;
    }
  }

  .pix_container_box {
    border-radius: 16px;
    border: 1px solid #f96b30;
    display: grid;
    grid-template-columns: 1fr 64px;
    height: 40px;
    overflow: hidden;

    .pix_container_box_label_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 16px;
    }
    .pix_container_box_label {
      font: normal normal normal 13px/18px Roboto;
      color: #212121;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    }
  }

  .qrCodeContainer {
    width: 160px;
  }
`

interface PaymentTypeContainerProps {
  isActive?: boolean
}
export const PaymentTypeContainer = styled.div<PaymentTypeContainerProps>`
  ${({ isActive }) => css`
    padding: 16px;
    border: 1px solid #dddddd;
    background: ${isActive ? '#F2F2F4' : '#FFFFFF'};
    border-radius: 16px;
    margin: 0 16px;
    margin-top: 16px;

    cursor: pointer;

    .payment_type_icon {
      width: 20px;
      height: 20px;
      color: #212121;
    }

    .payment_type_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #212121;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;

      .payment_type_header_left {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        cursor: pointer;
      }
    }

    .payment_type_body {
      display: ${isActive ? 'flex' : 'none'};
    }
  `}
`

export const FooterButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
`

interface RadioButtonProps {
  isActive?: boolean
}
export const RadioButton = styled.button<RadioButtonProps>`
  ${({ isActive }) => css`
    width: 16px;
    height: 16px;
    background: #ffffff;
    border-radius: 8px;
    border: ${isActive ? '4px solid #00AB50' : '1px solid #bdbdbd'};
  `}
`

export const RightButtonFooter = styled.div`
  width: 100%;
  padding: 0 24px;

  @media only screen and (max-width: 768px) {
    .pay_button {
      display: none;
    }
  }
  .pay_disabled {
    background: #eeeeee;
    color: #9e9e9e;
  }

  @media only screen and (min-width: 769px) {
    position: fixed;
    background: #fff;
    z-index: 4;
    bottom: 50px;
    padding-bottom: 16px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    width: 394px;
  }
`
export const BoxPriceMobile = styled.button`
  border: none;
  height: 60px;
  left: 0px;
  bottom: 0px;
  transition: 0.5s ease;
  padding: 20px;
  margin: 0px;
  box-shadow: 0px 0px 10px #ccc;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 20;
  transition: 0.3s;
  align-items: center;
  background: #00ab50;
  position: fixed;
  color: #fff;
  font-family: 'Inter';

  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;

  .box_price_mobile_continue {
    background: #ffffff;
    color: #212121;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    padding: 0 16px;
    gap: 8px;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    //position: relative;
  }
`

export const CustomSelectOption = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  font-family: Inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #212121;

  .custom_select_option_title {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #212121;
  }

  .custom_select_option_subLabel {
    font-family: Inter;
    font-size: 9px;
    font-style: italic;
    font-weight: 400;
    line-height: 11px;
    text-align: left;
    color: #616161;
  }
`

export const BoxInfoCopy = styled.div`
  z-index: 12;
  font-family: 'Inter';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 283px;
  height: 40px;
  border-radius: 8px;
  position: absolute;
  background: var(--secondaryColor);
  span {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }
  @media only screen and (max-width: 768px) {
    width: 95%;
    width: 95%;
    font-weight: 500;
    font-size: 18px;
  }
`
