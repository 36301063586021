/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer'
import loadingIconPrimary from '../../../../assets/icons/loading-primary.svg'
import loadingIcon from '../../../../assets/icons/loading.svg'

import infoGreen from './information.svg'
import logoBill from '../../../../assets/images/logo-bill.png'

import { PayDTO, PaymentDTO, TFInvoice } from '../../../../utils/models'
import * as S from './styles'
import { handleConsultDoc, handleUC } from './viewModel'
import { ProofPaymentPDF } from '../../../../components/PDF/ProofPaymentPDF'
import { StringToCurrency } from '../../../../utils/stringToCurrency'

interface Invoice {
  data: PaymentDTO
}

const Success: React.FC = () => {
  const { state } = useLocation<Invoice>()
  const history = useHistory()
  const [printProofPayment, setPrintProofPayment] = useState<PayDTO>()
  const transaction = printProofPayment?.transactions?.[0] || undefined
  const [hasPrint, setHasPrint] = useState(false)
  const [total, setTotal] = useState(0)
  const [loadingConsult, setLoadingConsult] = useState(false)
  const [invoices, setInvoices] = useState<TFInvoice[]>([])
  const [originalSubtotal, setOriginalSubTotal] = useState<number>(0)
  const [shownTaxServiceDifference, setShownTaxServiceDifference] = useState(0)

  useEffect(() => {
    const taxServiceObject = localStorage.getItem('taxServiceObject')
    if (taxServiceObject) {
      const parsedTaxservice = JSON.parse(taxServiceObject)
      if (parsedTaxservice.originalSubtotal) {
        setOriginalSubTotal(parsedTaxservice.originalSubtotal)
      }
      if (parsedTaxservice.taxServiceDifference) {
        setShownTaxServiceDifference(parsedTaxservice.taxServiceDifference)
      }
    }
    const temp = localStorage.getItem('Invoices')
    if (temp) {
      const tempInvoices = JSON.parse(temp)
      if (tempInvoices[0] && (tempInvoices[0].invoice_id)) {
        setInvoices(tempInvoices)
      }
    }
  }, [])

  function printProof(): void {
    setHasPrint(true)
    setTimeout(() => {
      setHasPrint(false)
    }, 3000)
  }
  useEffect(() => {
    if (hasPrint) {
      const pdfDownloader = document.getElementById('pdfDownloadRef')
      if (pdfDownloader) {
        pdfDownloader.click()
      }
    }
  }, [hasPrint])

  useEffect(() => {
    const tempPayment = localStorage.getItem('statusPaymentResume')
    if (tempPayment && tempPayment !== undefined) {
      setPrintProofPayment(JSON.parse(tempPayment))
      setTotal(JSON.parse(tempPayment).total)
    } else {
      history.push('/')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  async function getFromRef(refreshRef: string): Promise<void> {
    setLoadingConsult(true)
    try {
      await handleUC(refreshRef)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingConsult(false)
    }
  }

  async function getFromDoc(docField: string): Promise<void> {
    setLoadingConsult(true)
    try {
      await handleConsultDoc(docField)
    } catch (error) {
      console.log(error)
    } finally {
      setLoadingConsult(false)
    }
  }

  function toStartPage(): void {
    const refreshRef = localStorage.getItem('refreshRef')
    const docField = localStorage.getItem('refreshDoc')
    const pushState = {
      refreshRef,
      docField,
    }
    history.push({
      pathname: '/',
      state: pushState,
    })
  }

  // useEffect(() => {
  //   const refreshRef = localStorage.getItem('refreshRef')
  //   if (refreshRef && refreshRef.length) {
  //     getFromRef(refreshRef)
  //   }
  //   const docField = localStorage.getItem('refreshDoc')
  //   if (docField && docField.length) {
  //     getFromDoc(docField)
  //   }
  // }, [])

  return (
    <S.Container>
      <S.Box>
        <S.Title>Pagamento Recebido</S.Title>
        <S.WhiteContainer className="page_container_mt-16">
          <p className="container_title">Resumo do pagamento</p>
          <div className="container_row">
            <p className="row_label">Quantidade de Guias</p>
            <p className="row_label">
              {printProofPayment?.invoices.length || 0}
            </p>
          </div>
          <div className="container_row">
            <p className="row_label">Forma de pagamento</p>
            <p className="row_label">
              {printProofPayment?.paymentMethod || '-'}
            </p>
          </div>
          <div className="container_row">
            <p className="row_label">Parcelas</p>
            <p className="row_label">{`${
              transaction?.installments || '1'
            }x`}</p>
          </div>
          {originalSubtotal ? (
            <>
              <div className="container_row">
                <p className="row_label">Subtotal</p>
                <p className="row_label">
                  {`${StringToCurrency(`${originalSubtotal || 0}`)}`}
                </p>
              </div>

              {shownTaxServiceDifference ? (
                <div className="container_row">
                  <p className="row_label">Taxa de serviço</p>
                  <p className="row_label">
                    {`${StringToCurrency(`${shownTaxServiceDifference || 0}`)}`}
                  </p>
                </div>
              ) : null}

              <div className="container_row">
                <p className="row_label">Total</p>
                <p className="row_label">
                  {`${StringToCurrency(`${printProofPayment?.total || 0}`)}`}
                </p>
              </div>
            </>
          ) : (
            <div className="container_row">
              <p className="row_label">Subtotal</p>
              <p className="row_label">
                {`${StringToCurrency(`${printProofPayment?.total || 0}`)}`}
              </p>
            </div>
          )}

          {/* <div className="container_row">
            <p className="row_label">Taxa de serviço </p>
            <p className="row_label">Taxa de serviço </p>
          </div> */}
          <S.Button
            className="proofButton"
            onClick={() => {
              printProof()
            }}
          >
            {hasPrint ? (
              <img src={loadingIcon} alt="Loading" style={{ height: 24 }} />
            ) : (
              'Baixar comprovante'
            )}
          </S.Button>
          <div className="invoice_footer_bill page_container_mt-16">
            <p>Fornecido por</p>
            <img alt="bill" src={logoBill} />
          </div>
        </S.WhiteContainer>
        <S.Bottom>
          <img src={infoGreen} alt="InfoIcon" />
          <span>
            Seu pagamento foi recebido e confirmado. Enviaremos um e-mail com o
            comprovante. Em caso de necessidade, podem ser realizadas
            verificações extras.
          </span>
        </S.Bottom>

        {!loadingConsult ? (
          <S.Button
            className="homeButton"
            onClick={() => {
              toStartPage()
            }}
          >
            Tela inicial
          </S.Button>
        ) : null}
        {/* <S.Button
          className="proofButton"
          onClick={() => {
            printProof()
          }}
        >
          BAIXAR COMPROVANTE
        </S.Button> */}
      </S.Box>
      <div>

        {printProofPayment && hasPrint ? (
          <div style={{ display: 'none' }}>
            <ProofPaymentPDF proof={printProofPayment} invoices={invoices} />
          </div>
        ) : null}
      </div>
      <div style={{ width: 50, height: 10 }} />
    </S.Container>
  )
}
export default Success
