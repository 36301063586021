export function StringToCurrency(value: string): string {
  return parseFloat(value).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL'
  })
}

export function StringToCurrencyNoSifra(value: string): string {
  const res = parseFloat(value).toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    style: 'currency',
    currency: 'BRL'
  })
  return res.replace('R$ ', '')
}
