import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  body {
    background: #F0F2F5 ;
    -webkit-font-smoothing: antialiased;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .grecaptcha-badge {
      visibility: hidden;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    @media only screen and (max-width: 768px){
      overflow: hidden;
    }
  }

  body, input, button {
    font: 16px "Roboto", "Inter", sans-serif;
  }
  button {
    cursor: pointer;
  }
  :root{
    --primaryColor: #01CFFF;
    --primaryColorDark: #91DB51;
    --secondaryColor: #0B547F;
    --backgroundColor: #536979;
    --backgroundColorLight: #F2F3F3;
  }
`;
