/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable radix */
import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import {
  faArrowLeft,
  faArrowRight,
  faChevronDown,
  faCreditCard,
  faPaperPlane,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useHistory, useLocation } from 'react-router-dom'
import { Form } from '@unform/web'
import * as Yup from 'yup'
import { FormHandles } from '@unform/core'
import { validateCPF, validateCNPJ } from 'validations-br'
import informationIcon from '../../assets/icons/information.svg'
import loadingIcon from '../../assets/icons/loading.svg'
import sendIcon from '../../assets/icons/send.svg'

import logoBill from '../../assets/images/logo-bill.png'

import pixSvg from '../../assets/icons/pixIcon.svg'
import Select from '../../components/Select'
import * as S from './styles'
import InputMask from '../../components/InputMask'
import Input from '../../components/Input'
import { mundiPagg, newApi } from '../../services/api'
import {
  MundiPaggDTO,
  MundiReturnDTO,
  PayDTO,
  paymentInvoice,
  Invoice,
  TFInvoice,
} from '../../utils/models'
import Footer from '../../components/Footer'
import Modal from './components/Modal'
import { isAuthenticated } from '../../utils/auth'
import { CheckBoxItem } from '../../components/CheckboxItem'
import { PaymentMethods, PixDataProps } from './Models'
import { PaymentTypeContainer } from './components/PaymentTypeContainer'
import { PaymentInvoice } from './components/PaymentInvoice'
import loadingPrimaryIcon from '../../assets/icons/loading-primary.svg'
import { CreditMinValue } from '../../components/CreditMinValue'
import { floatValuesSub } from '../../utils/floatValuesOperations'
import { StringToCurrency } from '../../utils/stringToCurrency'

// import replaceSpecialChars from '../../functions/replaceSpecialChars'

interface LocationStateDTO {
  data: PayDTO
}

interface Errors {
  [key: string]: string
}

const Payment: React.FC = () => {
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)

  const thisRef = useRef<any>(null)
  const [isShared, setIsShared] = useState(false)
  const [userInfo, setUserInfo] = useState<Invoice>()
  const [invoices, setInvoices] = useState<Array<TFInvoice>>()
  const [paymentInvoices, setPaymentInvoices] = useState<Array<TFInvoice>>([])
  const [isModalMinCreditOpen, setIsModalMinCreditOpen] = useState(false)
  const [loadingPayment, setLoadingPayment] = useState(false)
  const [loadingPix, setLoadingPix] = useState(false)
  const [loading, setLoading] = useState(false)
  const location = useLocation<LocationStateDTO>()
  const [paymentResume, setPaymentResume] = useState<PayDTO>()
  const [openDetail, setOpenDetail] = useState<number>()
  const [total, setTotal] = useState<number>(0)
  const [listInvoicePay, setList] = useState<Array<paymentInvoice>>()
  const [invoideId, setInvoiceId] = useState<Array<paymentInvoice>>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loadingErase, setLoadingErase] = useState<boolean>(false)
  const [optionsSelect, setOptionsSelect] = useState<Array<any>>()
  const [showModalErrorMundi, setShowModalErrorMundi] = useState(false)
  const [paymentFormat, setPaymentFormat] = useState('')
  const refPaymentFormat = useRef('')
  const [pixData, setPixData] = useState<PixDataProps | undefined>()
  const [limited, setLimited] = useState(true)
  const [parcelamentosOptions, setParcelamentosOptions] = useState<any[]>([])
  const [parcelamentoQtd, setParcelamentoQtd] = useState(1)
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethods[]>([])

  const [isShaking, setIsShaking] = useState(false)

  const paymentId = location?.state?.data?.id || paymentResume?.id
  const paymentResumeTotal =
    location?.state?.data?.total || paymentResume?.total

  function shakeItem(): void {
    setIsShaking(true)
    setTimeout(() => {
      setIsShaking(false)
    }, 1000)
  }

  function changePaymentFormat(format: string): void {
    if (loading || loadingPix) return
    if (format === 'CREDIT' && total < 5) {
      setIsModalMinCreditOpen(true)
      return
    }
    setPaymentFormat((actualMethod) => {
      if (actualMethod !== format) {
        if (format === 'CREDIT') {
          setTotalFromParcelamentos(1)
        } else {
          setParcelamentoQtd(1)
          if (paymentResume) setTotal(parseFloat(paymentResume?.total))
        }
      }

      return format
    })
    refPaymentFormat.current = format
  }

  useEffect(() => {
    if (!isAuthenticated()) {
      history.push('/login')
    }
  }, [history])

  useEffect(() => {
    const userTemp = localStorage.getItem('invoice')
    const compartilhado = sessionStorage.getItem('compartilhado')
    if (compartilhado) {
      setIsShared(true)
    }
    if (userTemp) {
      setUserInfo(JSON.parse(userTemp))
    }
  }, [])

  function overlapInvoices(
    paymentResumeItem: PayDTO,
    invoicesList: TFInvoice[]
  ): void {
    const tempArray = [...invoicesList]
    const itemArray = tempArray.filter((invoice) =>
      paymentResumeItem.invoices.some(
        (paymentInvoiceItem) => paymentInvoiceItem.id === invoice.invoice_id
      )
    )
    const uniqueArray = itemArray.reduce((acc: TFInvoice[], item) => {
      if (!acc.some((ac) => ac.invoice_id === item.invoice_id)) {
        acc.push(item)
      }
      return acc
    }, [])
    setPaymentInvoices(uniqueArray)
  }

  useEffect(() => {
    if (paymentResume && invoices?.length) {
      overlapInvoices(paymentResume, invoices)
    }
  }, [paymentResume, invoices])

  useEffect(() => {
    const temp = localStorage.getItem('Invoices')
    if (temp) {
      const tempInvoices = JSON.parse(temp)
      if (tempInvoices[0] && tempInvoices[0].invoice_id) {
        setInvoices(tempInvoices)
      }
    }
  }, [])

  useEffect(() => {
    const localPaymentResume = localStorage.getItem('paymentResume')

    if (location.state && paymentResume === undefined) {
      localStorage.setItem('paymentResume', JSON.stringify(location.state.data))
      setPaymentResume(location.state.data)
    } else if (localPaymentResume) {
      const JSONPaymentResume = JSON.parse(localPaymentResume)
      setPaymentResume(JSONPaymentResume)
    } else {
      history.push('/')
    }
  }, [location])

  async function getInstallmentsCredit(): Promise<void> {
    try {
      const token = sessionStorage.getItem('token')

      const { data } = await newApi.get(
        `/mobile/payment/${paymentId}/installments`,
        {
          headers: {
            Authorization: `Basic ${token}`,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )
      if (data.installments) {
        setParcelamentosOptions(data.installments)
      }
      if (data && data.installments) {
        const tempOptions = [] as any[]
        const tempOptionsV2 = [] as any[]

        data.installments.forEach((parcela: any) => {
          let label = ''
          label = `${
            parcela.number_installments || parcela.num_installments
          }x de ${StringToCurrency(`${parcela.amount}`)} (${
            parcela.rate !== 0
              ? ` ${Number(`${parcela.rate}`).toLocaleString(
                  'pt-BR'
                )}% de juros`
              : 'sem juros'
          })${
            parcela.rate === 0
              ? ''
              : ` (Total ${StringToCurrency(`${parcela.total}`)})`
          }`
          const item = {
            value: parcela.number_installments || parcela.num_installments,
            label,
          }

          const itemV2 = {
            value: parcela.number_installments || parcela.num_installments,
            label,
            customComponent: (
              <S.CustomSelectOption>
                <div>
                  <p className="custom_select_option_title">
                    {`${
                      parcela.number_installments || parcela.num_installments
                    }x de ${StringToCurrency(`${parcela.amount}`)}`}
                  </p>
                  <p className="custom_select_option_subLabel">
                    {`${
                      parcela.monthly_rate !== 0
                        ? `${Number(`${parcela.monthly_rate}`).toLocaleString(
                            'pt-BR'
                          )}% de juros ao mês`
                        : 'sem juros'
                    }`}
                  </p>
                </div>
                <p className="custom_select_option_title">
                  {`${
                    parcela.rate === 0
                      ? ''
                      : ` Total ${StringToCurrency(`${parcela.total}`)}`
                  }`}
                </p>
              </S.CustomSelectOption>
            ),
          }

          tempOptions.push(item)
          tempOptionsV2.push(itemV2)
        })
        // console.log(tempOptions,'aaa')
        // setOptionsSelect(tempOptions)
        setOptionsSelect(tempOptionsV2)
      }
    } catch (error) {
      console.log(error)
    }
  }
  React.useEffect(() => {
    if (userInfo && paymentResume) {
      getInstallmentsCredit()
    }
  }, [userInfo, paymentResume])

  useEffect(() => {
    if (paymentResume?.invoices.length === 1) {
      if (
        location &&
        location.state &&
        location.state.data &&
        location.state.data.invoices
      ) {
        setOpenDetail(location.state.data.invoices[0].id)
      }
    }
    if (paymentResume) setTotal(parseFloat(paymentResume?.total))
    const temp = paymentResume?.invoices
    setInvoiceId(temp)
    setList(paymentResume?.invoices)
    const tempOption = []
    if (paymentResume?.invoices) {
      for (
        let i = 1;
        paymentResume?.invoices.length > 6
          ? i <= 6
          : i <= paymentResume?.invoices.length;
        i += 1
      ) {
        tempOption.push({
          value: i,
          label: `${i}x ${StringToCurrency(
            `${paymentResume?.total}`
          )} (sem juros)`,
        })
      }
    }
    // setOptionsSelect(tempOption)
  }, [paymentResume])
  /*
  async function removeInvoice(idInvoice: number):Promise<void>{
    setLoadingErase(true)
    const token = sessionStorage.getItem('token');
    try {
      if(paymentResume?.invoices.length === 1) {
        throw new Error("");
      }
      const {data} = await api.patch(`pagamento/${paymentResume?.id}/fatura`, {
        faturas: [idInvoice]
      },{
        headers: {
          Authorization: `Basic ${token}`,
          "X-Forwarded-Host": window.location.hostname||process.env.REACT_APP_HOST
        }
      });
      setPaymentResume(data);
      setLoadingErase(false);
    } catch (error) {
      setLoadingErase(false);
      history.push('/');
    }
  }
*/

  async function consultDataInput(): Promise<void> {
    if (formRef.current) {
      await generateTokenMundi({
        number: formRef.current.getData().cardNumber.replace(/\s/g, ''),
        holder_name: String(formRef.current.getData().name)
          .toUpperCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
        exp_month: parseInt(formRef.current.getData().valid?.substr(0, 2)),
        exp_year: parseInt(formRef.current.getData().valid?.substr(3, 4)),
        cvv: formRef.current.getData().cvv,
        label: '',
      })
    }
  }

  async function generateTokenMundi(card: MundiPaggDTO): Promise<void> {
    try {
      setLoadingPayment(true)
      const { data } = await mundiPagg.post(
        `/tokens?appId=${process.env.REACT_APP_API_MUNDI}`,
        {
          type: 'card',
          card,
        }
      )
      pay(data)
    } catch (err: any) {
      setLoadingPayment(false)
      // console.log(err)
      if (err.status === 500) {
        setShowModalErrorMundi(true)
      } else if (formRef.current) {
        formRef.current.setErrors({
          cardNumber: 'Dados do cartão inválidos',
          cvv: 'Dados do cartão inválidos',
          valid: 'Dados do cartão inválidos',
          name: 'Dados do cartão inválidos',
        })
      }
    }
  }

  async function payBoleto(): Promise<void> {
    const token = sessionStorage.getItem('token')
    if (formRef.current && token) {
      try {
        const { data } = await newApi.post(
          `/mobile/payment/${paymentId}/process`,
          {
            transactions: [
              {
                type: 'BOLETO',
                name: formRef.current.getData().name,
                doc: String(formRef.current.getData().document).replace(
                  /[^\d]+/g,
                  ''
                ),
                email: formRef.current.getData().email,
                phone: formRef.current.getData().phone,
                invoice: `${total}`,
              },
            ],
          },
          {
            headers: {
              Authorization: `Basic ${token}`,
              'X-Forwarded-Host':
                window.location.hostname || process.env.REACT_APP_HOST,
            },
          }
        )
        setLoadingPayment(false)
        if ([0, 4, 5, 10].includes(data.status)) {
          history.push('/payment/boleto', {
            data,
          })
          // localStorage.setItem('statusPaymentResume', JSON.stringify(data))
          // history.push('/payment/success')
        } else {
          localStorage.setItem('statusPaymentResume', JSON.stringify(data))
          history.push('/payment/error')
        }
      } catch (error) {
        console.log(error)
        history.push('/payment/error')
        setLoadingPayment(false)
      }
    }
  }
  async function payPix(): Promise<void> {
    const token = sessionStorage.getItem('token')
    if (formRef.current && token) {
      try {
        const { data } = await newApi.post(
          `/mobile/payment/${
            location?.state?.data?.id || paymentResume?.id
          }/process`,
          {
            transactions: [
              {
                type: 'PIX',
                name: formRef.current.getData().name,
                doc: String(formRef.current.getData().document).replace(
                  /[^\d]+/g,
                  ''
                ),
                email: formRef.current.getData().email,
                phone: formRef.current.getData().phone,
                invoice: `${total}`,
              },
            ],
          },
          {
            headers: {
              Authorization: `Basic ${token}`,
              'X-Forwarded-Host':
                window.location.hostname || process.env.REACT_APP_HOST,
            },
          }
        )
        setLoadingPayment(false)
        if ([0, 4, 5, 10].includes(data.status)) {
          history.push('/payment/pix', {
            data,
          })
          // localStorage.setItem('statusPaymentResume', JSON.stringify(data))
          // history.push('/payment/success')
        } else {
          localStorage.setItem('statusPaymentResume', JSON.stringify(data))
          history.push('/payment/error')
        }
        // history.push('/payment/pix', {
        //   data,
        // })
      } catch (error) {
        console.log(error)
        history.push('/payment/error')
        setLoadingPayment(false)
      }
    }
  }

  async function pay(dataMuni: MundiReturnDTO): Promise<void> {
    const token = sessionStorage.getItem('token')
    if (formRef.current && token) {
      /* console.log({
        transactions: [
          {
            name:replaceSpecialChars(formRef.current.getData().name),
            doc: String(formRef.current.getData().document).replace(/[^\d]+/g,''),
            email:  formRef.current.getData().email,
            holderName: formRef.current.getData().name,
            phone: formRef.current.getData().phone,
            token: dataMuni.id,
            invoice: paymentResumeTotal,
            installments: formRef.current.getData().plots

           // cliente: location.state.data.cliente,
           // tipo: 1,
          }
        ]
      }); */

      try {
        const { data } = await newApi.post(
          `/mobile/payment/${
            location?.state?.data?.id || paymentResume?.id
          }/process`,
          {
            transactions: [
              {
                name: formRef.current.getData().name,
                doc: String(formRef.current.getData().document).replace(
                  /[^\d]+/g,
                  ''
                ),
                email: formRef.current.getData().email,
                holderName: formRef.current.getData().name,
                phone: formRef.current.getData().phone,
                token: dataMuni.id,
                invoice: `${total}`,
                installments: formRef.current.getData().plots||1,

                // cliente: location.state.data.cliente,
                // tipo: 1,
              },
            ],
          },
          {
            headers: {
              Authorization: `Basic ${token}`,
              'X-Forwarded-Host':
                window.location.hostname || process.env.REACT_APP_HOST,
            },
          }
        )
        setLoadingPayment(false)
        // console.log('paymentData: ',data)
        if ([4, 5].includes(data.status)) {
          const paymentData = { ...data, paymentMethod: 'Crédito' }
          localStorage.setItem(
            'statusPaymentResume',
            JSON.stringify(paymentData)
          )
          const taxServiceDifference = floatValuesSub(
            total,
            parseFloat(paymentResume?.total || '0')
          )

          if (taxServiceDifference > 0) {
            const taxServiceObject = {
              taxServiceDifference,
              originalSubtotal: parseFloat(paymentResume?.total || '0'),
            }
            localStorage.setItem(
              'taxServiceObject',
              JSON.stringify(taxServiceObject)
            )
          }
          history.push('/payment/success')
        } else {
          localStorage.setItem('statusPaymentResume', JSON.stringify(data))
          history.push('/payment/error')
        }
      } catch (error) {
        history.push('/payment/error')
        setLoadingPayment(false)
      }
    }
  }

  async function handleSubmit(): Promise<void> {
    if (formRef.current) {
      setLoading(true)
      try {
        const schema = Yup.object().shape({
          cardNumber: Yup.string()
            .min(15, 'Formato inválido')
            .required('O número do cartão é obrigatório'),
          valid: Yup.string()
            .min(5)
            .max(5)
            .required('A validade é obrigatória'),
          name: Yup.string()
            .min(4, 'Nome inválido')
            .required('O nome é obrigatório'),
          cvv: Yup.string()
            .min(3, 'Formato Inválido')
            .max(4, 'Formato Inválido')
            .required('O código verificador é obrigatório'),
          phone: Yup.string()
            .min(11, 'Formato Inválido')
            .required('O numero de telefone é obrigatório'),
          email: Yup.string()
            .email('Formato de email inválido')
            .required('O email é obrigatório'),
          // plots:
          //   paymentFormat === 'CREDIT'
          //     ? Yup.number().required('A quantidade de parcelas é obrigatória')
          //     : Yup.number().notRequired(),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
        await consultDataInput()
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
        }
      } finally {
        setLoading(false)
      }
    }
  }
  async function handleSubmitPix(): Promise<void> {
    if (loadingPix) return
    setLoadingPix(true)
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          document: Yup.string()
            .test('is-cpf', 'CPF inválido.', (value) =>
              validateCPF(formRef.current?.getData().document)
            )
            .min(14, 'No mínimo 11 dígitos.')
            .required('O CPF é obrigatório'),
          phone: Yup.string()
            .min(11, 'Formato Inválido')
            .required('O numero de telefone é obrigatório'),
          name: Yup.string()
            .min(4, 'Nome inválido')
            .required('O nome é obrigatório'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
        await getQrCode()
      } catch (err) {
        // console.log(err)
        console.log(err)

        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
        }

        if (thisRef && thisRef.current) {
          // console.log('')

          thisRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      }
    }
    setLoadingPix(false)
  }

  async function handleSubmitBoleto(): Promise<void> {
    if (formRef.current) {
      if (loading) return
      setLoading(true)
      try {
        const schema = Yup.object().shape({
          document: Yup.string()
            .test('is-cpf', 'CPF inválido.', (value) =>
              validateCPFOrCNPJ(formRef.current?.getData().document)
            )
            .min(14, 'No mínimo 11 dígitos.')
            .required('O CPF é obrigatório'),
          phone: Yup.string()
            .min(11, 'Formato Inválido')
            .required('O numero de telefone é obrigatório'),
          name: Yup.string()
            .min(4, 'Nome inválido')
            .required('O nome é obrigatório'),
          email: Yup.string()
            .email('Formato de email inválido')
            .required('O email é obrigatório'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
        await payBoleto()
      } catch (err) {
        // console.log(err)
        console.log(err)

        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
        }

        if (thisRef && thisRef.current) {
          thisRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      } finally {
        setLoading(false)
      }
    }
  }

  async function handleSubmitPix2(): Promise<void> {
    if (formRef.current) {
      if (loading) return
      setLoading(true)
      try {
        const schema = Yup.object().shape({
          document: Yup.string()
            .test('is-cpf', 'CPF inválido.', (value) =>
              validateCPFOrCNPJ(formRef.current?.getData().document)
            )
            .min(14, 'No mínimo 11 dígitos.')
            .required('O CPF é obrigatório'),
          phone: Yup.string()
            .min(11, 'Formato Inválido')
            .required('O numero de telefone é obrigatório'),
          name: Yup.string()
            .min(4, 'Nome inválido')
            .required('O nome é obrigatório'),
          email: Yup.string()
            .email('Formato de email inválido')
            .required('O email é obrigatório'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
        await payPix()
      } catch (err) {
        // console.log(err)
        console.log(err)

        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
        }

        if (thisRef && thisRef.current) {
          thisRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          })
        }
      } finally {
        setLoading(false)
      }
    }
  }

  async function getQrCode(): Promise<void> {
    if (formRef.current)
      try {
        const token = sessionStorage.getItem('token')
        const postData = {
          transacoes: [
            {
              valor: total,
              cliente: formRef.current.getData().name,
              tipo: 9,
              doc: String(formRef.current.getData().document).replace(
                /[^\d]+/g,
                ''
              ),
            },
          ],
        }
        const response = await newApi.post(
          `/pagamento/${location?.state?.data?.id || paymentResume?.id}/pix`,
          postData,
          {
            headers: {
              Authorization: `Basic ${token}`,
              'X-Forwarded-Host':
                window.location.hostname || process.env.REACT_APP_HOST,
            },
          }
        )
        const { data } = response
        setPixData(data)
        checkQrCodeData()
      } catch (error) {
        console.log(error)
      }
  }

  async function checkQrCodeData(): Promise<void> {
    try {
      const token = sessionStorage.getItem('token')

      const response = await newApi.get(
        `/pagamento/${location?.state?.data?.id || paymentResume?.id}`,
        {
          headers: {
            Authorization: `Basic ${token}`,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )
      const { data } = response
      if (data.status === 4 || data.status === 5) {
        localStorage.setItem('statusPaymentResume', JSON.stringify(data))
        history.push('/payment/success')
      } else {
        setTimeout(() => {
          if (refPaymentFormat.current === 'PIX') checkQrCodeData()
        }, 5000)
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function checkCardNumber(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          cardNumber: Yup.string()
            .min(15, 'Formato inválido')
            .required('O número do cartão é obrigatório'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  async function checkValid(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          valid: Yup.string()
            .min(5)
            .max(5)
            .required('A validade é obrigatória'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  async function checkName(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          name: Yup.string()
            .min(6, 'Nome inválido')
            .required('O nome é obrigatório'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  async function checkCvv(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          cvv: Yup.string()
            .min(3, 'Tamanho inválido')
            .max(4, 'Tamanho inválido')
            .required('O código verificador é obrigatório'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  async function checkPhoneNumber(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          phone: Yup.string()
            .min(11, 'Formato Inválido')
            .required('O numero de telefone é obrigatório'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  async function checkEmail(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Formato de email inválido')
            .required('O email é obrigatório'),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  async function checkPlots(): Promise<void> {
    if (formRef.current) {
      try {
        // const schema = Yup.object().shape({
        //   plots: Yup.number().required(
        //     'A quantidade de parcelas é obrigatória'
        //   ),
        // })
        // await schema.validate(formRef.current.getData(), {
        //   abortEarly: false,
        // })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  function validateCPFOrCNPJ(value: any): boolean {
    return validateCNPJ(value) || validateCPF(value)
  }

  async function checkDocNumber(): Promise<void> {
    if (formRef.current) {
      try {
        const schema = Yup.object().shape({
          document: Yup.string()
            .required('O documento é obrigatório')
            .test(
              'is-valid-cpf-or-cnpj',
              'Formato inválido',
              validateCPFOrCNPJ
            ),
        })
        await schema.validate(formRef.current.getData(), {
          abortEarly: false,
        })
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          if (formRef.current) formRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  function cutName(name: string | undefined): string {
    if (name === undefined) {
      return 'NOME'
    }
    if (name.length > 22) {
      return `${name.substring(0, 22)}...`
    }
    return name
  }
  function transformDate(d: string): string {
    const dSplit = d.split('-')

    return `${dSplit[2]}/${dSplit[1]}/${dSplit[0]}`

    return ''
  }

  function backButton(): void {
    const refreshRef = localStorage.getItem('refreshRef')
    const docField = localStorage.getItem('refreshDoc')

    history.push({
      pathname: '/',
      state: {
        data: invoideId,
        refreshRef,
        docField,
      },
    })
  }

  function setTotalFromParcelamentos(qtd: number): void {
    setParcelamentoQtd(qtd)
    if (parcelamentosOptions && parcelamentosOptions.length > 0) {
      const find = parcelamentosOptions.find(
        (parcela) => parcela.number_installments === qtd
      )
      if (find) {
        setTotal(parseFloat(find.total))
      }
    }
  }

  async function getPaymentMethods(): Promise<any> {
    try {
      const token = sessionStorage.getItem('token')
      const { data } = await newApi.get(
        `/mobile/payment/payment-methods?merchant=${process.env.REACT_APP_MERCHANT_FIXED}`,
        {
          headers: {
            Authorization: `Basic ${token}`,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )
      localStorage.setItem(
        'payment_methods',
        JSON.stringify(data.payment_methods)
      )
      return data.payment_methods
    } catch (error) {
      console.log(error)
    }
    return undefined
  }

  function reorderArray(arr: PaymentMethods[]): PaymentMethods[] {
    const arrayOrder = ['3DS', 'CREDIT', 'PIX', 'BOLETO']

    const orderMap = new Map(arrayOrder.map((item, index) => [item, index]))
    if (orderMap.size > 1) {
      return arr.sort(
        (a, b) => arrayOrder.indexOf(a.tag) - arrayOrder.indexOf(b.tag)
      )
    }
    return arr
  }

  async function applyPaymentMethods(): Promise<void> {
    localStorage.removeItem('taxServiceObject')
    const payment_methods = localStorage.getItem('payment_methods')
    let paymentMethodsList = [] as any[]
    if (payment_methods) {
      paymentMethodsList = [...JSON.parse(payment_methods)]
    } else {
      paymentMethodsList = await getPaymentMethods()
    }

    if (paymentMethodsList && paymentMethodsList.length) {
      const reorderList = reorderArray(paymentMethodsList)
      setPaymentMethods(reorderList)
      // changePaymentFormat(reorderList[0].tag)
    } else {
      setPaymentMethods([
        {
          name: 'Cartão de Crédito',
          tag: '3DS',
        },
      ])

      // changePaymentFormat('3DS')
    }
  }
  useEffect(() => {
    applyPaymentMethods()
  }, [])

  async function dynamicHandleSubmit(tag: string): Promise<void> {
    if (!tag || tag === '') {
      shakeItem()
      return
    }

    if (tag === 'BOLETO') {
      handleSubmitBoleto()
    } else if (tag === 'PIX') {
      handleSubmitPix2()
    } else handleSubmit()
  }
  const [showInfo, setShowInfo] = useState(false)
  function copyBarCode(toCopyText: string): void {
    const dummy = document.createElement('textarea')
    document.body.appendChild(dummy)
    dummy.value = toCopyText
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)
    setShowInfo(true)
    setTimeout(() => {
      setShowInfo(false)
    }, 3000)
  }

  function handleShare(): void {
    if (paymentInvoices.length === 1) {
      copyBarCode(
        `${process.env.REACT_APP_URL_HOST||window.location.hostname}/pagamento?guia=${paymentInvoices[0].invoice_id}&compartilhado=1`
      )
      return
    }
    const refreshRef = localStorage.getItem('refreshRef')
    if (refreshRef) {
      copyBarCode(
        `${process.env.REACT_APP_URL_HOST||window.location.hostname}/pagamento?processo=${refreshRef}&compartilhado=1`
      )
      return
    }
    const refreshDoc = localStorage.getItem('refreshDoc')

    if (refreshDoc) {
      copyBarCode(
        `${process.env.REACT_APP_URL_HOST||window.location.hostname}/pagamento?doc=${refreshDoc}&compartilhado=1`
      )
    }
  }

  function pixDate(): string {
    if (pixData) {
      const pixDateHour = new Date(pixData.expires_at)
      const day = String(pixDateHour.getDate()).padStart(2, '0')
      const month = String(pixDateHour.getMonth() + 1).padStart(2, '0') // Months are zero-based
      const year = pixDateHour.getFullYear()
      const hour = String(pixDateHour.getHours()).padStart(2, '0')
      const minute = String(pixDateHour.getMinutes()).padStart(2, '0')

      const formattedDate = ` ${day}/${month}/${year} às ${hour}:${minute}`
      return formattedDate
    }
    return ''
  }

  return (
    <S.Container>
      <Modal showModalErrorMundi={showModalErrorMundi} />
      <S.BoxInfoCopy
        style={{
          opacity: !showInfo ? 0 : 1,
          bottom: !showInfo ? 0 : 40,
          transition: 'all 0.5s ease',
          padding: 0,
          display: showInfo ? 'flex' : 'none',
        }}
      >
        <span>Link copiado com sucesso</span>
      </S.BoxInfoCopy>
      <S.Box>
        <S.Header>
          <div className="header_row">
            <S.BtnBack type="button" onClick={backButton}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </S.BtnBack>
            <S.Title>Pagamento</S.Title>
          </div>

          {paymentInvoices && paymentInvoices[0] && isShared ? (
            <S.ButtonShare
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                handleShare()
              }}
            >
              <p>Enviar link para pagamento</p>

              <img className="payment_type_icon" alt="send" src={sendIcon} />
            </S.ButtonShare>
          ) : null}
        </S.Header>
        <S.Body>
          <S.Left>
            <div className={`left_body ${isShaking ? 'shake_item' : ''} `}>
              <div className="div_header" ref={thisRef}>
                <span>Formas de pagamento</span>
              </div>
              {pixData ? (
                <S.PixContainer>
                  <div className="pix_container_top">
                    <p>Copie o código abaixo e utiliza o Pix Copia e Cola no</p>
                    <p>aplicativo que você vai fazer o pagamento</p>
                    <div className="pix_container_box">
                      <div className="pix_container_box_label_container">
                        <div style={{ display: 'grid' }}>
                          <p className="pix_container_box_label">
                            {pixData.qr_code}
                          </p>
                        </div>
                      </div>
                      <button
                        className="pix_container_copy_button"
                        type="button"
                        onClick={(e) => {
                          e.preventDefault()
                          e.stopPropagation()
                          copyBarCode(pixData.qr_code_url)
                        }}
                        style={{ cursor: 'pointer', width: '100%' }}
                      >
                        Copiar
                      </button>
                    </div>
                    <img
                      className="qrCodeContainer"
                      src={pixData.qr_code_url}
                      alt="qr code"
                    />
                    <p>
                      Pague até
                      {pixDate()}
                    </p>
                    <p className="pix_container_13px">
                      Abra o leitor de QR Code na área Pix do aplicativo que
                      você vai fazer o pagamento e escaneie o código
                    </p>
                  </div>
                  <S.ButtonPay
                    type="button"
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      refPaymentFormat.current = 'CREDIT'
                      setPaymentFormat('CREDIT')
                      setPixData(undefined)
                    }}
                    style={{ cursor: 'pointer', width: '100%' }}
                  >
                    Alterar forma de pagamento
                  </S.ButtonPay>
                </S.PixContainer>
              ) : (
                paymentMethods.map((method) => (
                  <PaymentTypeContainer
                    key={method.tag}
                    method={method}
                    changePaymentFormat={changePaymentFormat}
                    checkCardNumber={checkCardNumber}
                    checkDocNumber={checkDocNumber}
                    checkEmail={checkEmail}
                    checkName={checkName}
                    checkPhoneNumber={checkPhoneNumber}
                    checkPlots={checkPlots}
                    checkValid={checkValid}
                    formRef={method.tag === paymentFormat ? formRef : undefined}
                    handleSubmit={async () => {
                      dynamicHandleSubmit(method.tag)
                    }}
                    optionsSelect={optionsSelect}
                    paymentFormat={paymentFormat}
                    setTotalFromParcelamentos={setTotalFromParcelamentos}
                    checkCvv={checkCvv}
                  />
                ))
              )}
            </div>

            {paymentInvoices && paymentInvoices[0] && !isShared ? (
              <div className="left_body">
                <div className="div_header" ref={thisRef}>
                  <span>Compartilhe o link para pagamento</span>
                </div>
                <S.PaymentTypeContainer
                  onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()

                    handleShare()
                  }}
                >
                  <div className="payment_type_header">
                    <div className="payment_type_header_left">
                      <p>Enviar link para pagamento</p>
                    </div>

                    <img
                      className="payment_type_icon"
                      alt="send"
                      src={sendIcon}
                    />
                  </div>{' '}
                </S.PaymentTypeContainer>
              </div>
            ) : null}
          </S.Left>
          <S.Right>
            <div className="right_header">
              <span>Resumo do Pagamento</span>
              <div className="right_line" />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              {paymentInvoices.map((invoice) => {
                return (
                  <PaymentInvoice
                    invoiceItem={invoice}
                    key={invoice.invoice_id}
                  />
                )
              })}
            </div>

            <S.RightButtonFooter>
              <S.FooterButtonContainer className="right_mt-24">
                <S.ButtonPay
                  type="button"
                  onClick={() => {
                    dynamicHandleSubmit(paymentFormat)
                  }}
                  disabled={loadingPayment}
                  style={{ cursor: !loadingPayment ? 'pointer' : 'initial' }}
                  className={` pay_button ${
                    paymentFormat === '' ? 'pay_disabled' : ''
                  }`}
                >
                  {loadingPayment || loading ? (
                    <img
                      src={loadingIcon}
                      alt="Loading"
                      style={{ height: 50 }}
                    />
                  ) : paymentFormat === '' ? (
                    'Selecione uma forma de pagamento'
                  ) : (
                    `Pagar ${StringToCurrency(`${total || 0}`)}`
                  )}
                </S.ButtonPay>
              </S.FooterButtonContainer>

              <div className="invoice_footer_bill right_mt-16">
                <p>Fornecido por</p>
                <img alt="bill" src={logoBill} />
              </div>
            </S.RightButtonFooter>
          </S.Right>
        </S.Body>
        <div id="mobile">
          <Footer />
        </div>
      </S.Box>
      {/* <div id="web" style={{ width: '100%' }}>
        <Footer />
      </div> */}
      <S.BoxPriceMobile
        onClick={() => {
          dynamicHandleSubmit(paymentFormat)
        }}
        disabled={loading || loadingPayment || paymentFormat === ''}
        className="right_mt-24"
        style={{
          zIndex: total <= 0 ? 10 : 20,
          opacity: total <= 0 ? 0 : 1,
          cursor: total <= 0 ? 'default' : '',
          // position: total <= 0 ? 'fixed' : 'relative',
          bottom: total <= 0 ? -60 : 0,
          // display: total <=0 ? 'none' : 'flex',

          // height: total <=0 ? 0 : 60,
        }}
      >
        <span style={{ fontSize: 19, fontWeight: 600 }}>
          {paymentFormat === ''
            ? 'Selecione forma de pagamento'
            : `Total ${StringToCurrency(`${total || 0}`)}`}
        </span>
        <div className="box_price_mobile_continue">
          {loadingPayment || loading ? (
            <img
              src={loadingPrimaryIcon}
              alt="Carregando"
              style={{ height: 30, margin: 0 }}
            />
          ) : (
            <>
              {' '}
              <span>Pagar</span>
              <FontAwesomeIcon icon={faArrowRight} color="#212121" />
            </>
          )}
        </div>
      </S.BoxPriceMobile>
      {isModalMinCreditOpen ? (
        <CreditMinValue
          closeModal={() => {
            setIsModalMinCreditOpen(false)
          }}
        />
      ) : null}
    </S.Container>
  )
}

export default connect((state: any) => ({ modules: state }))(Payment)
