/* eslint-disable no-nested-ternary */
import { connect } from 'react-redux'
import styled, { css } from 'styled-components'

export const Container = styled.div`
  padding: 0;
  width: 100vw;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  #mobile {
    display: none;
  }
  #web {
    display: flex;
  }
  font-family: Inter;
  @media only screen and (max-width: 768px) {
    overflow: scroll;
    #mobile {
      display: flex;
    }
    #web {
      display: none;
    }
    .marginIfOn {
      margin-bottom: 60px;
      // background-color:#000;
    }
  }
  .container-input-react-select {
    margin-left: 16px;
  }
  .react-select-container {
    width: 132px !important;
    height: 24px;
    font-family: Inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #212121;
  }

  .react-select__control {
    height: 24px;
    min-height: 24px;
  }
  .react-select__indicators {
    height: 24px;
    min-height: 24px;
  }
`

export const Box = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: flex;
  gap: 10px;
  max-height: 90%;
  overflow: hidden;
  flex-direction: row;
  justify-content: space-between;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    width: 100vw;
    max-height: 100%;
    padding: 10px;
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 0;
  }
`

export const BoxPrice = styled.div`
  border: none;
  box-shadow: 0px 0px 2px #555;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 874px;
  z-index: 3;
  transition: 0.3s;
  height: 60px;
  border-radius: 8px;
  align-items: center;
  background: #fff;
  position: absolute;
  color: var(--secondaryColor);
  font-family: 'Inter';
  font-weight: 500;
  font-size: 16px;
  button {
    background-color: var(--primaryColorDark);
    width: 220px;
    height: 40px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-radius: 8px;
    color: #fff;
    font-weight: 500;
    transition: 0.2s;
    &:hover {
      background-color: var(--primaryColorDark);
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    position: absolute;
    display: none;
  }
`

export const BoxInfoDownload = styled.div`
  z-index: 12;
  font-family: 'Inter';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 283px;
  height: 40px;
  border-radius: 8px;
  margin: auto;
  position: absolute;
  background: var(--secondaryColor);
  bottom: 40px;
  span {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }
  @media only screen and (max-width: 768px) {
    width: 95%;
    font-weight: 500;
    font-size: 18px;
    bottom: 64px;
    div {
      margin: 10px;
      width: 65vw;
    }
  }
`

export const BoxInfoCopy = styled.div`
  z-index: 12;
  font-family: 'Inter';
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 283px;
  height: 40px;
  border-radius: 8px;
  position: absolute;
  background: var(--secondaryColor);
  span {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
  }
  bottom: 40px;

  @media only screen and (max-width: 768px) {
    width: 95%;
    width: 95%;
    bottom: 64px;
    font-weight: 500;
    font-size: 18px;
  }
`

export const BoxInfo = styled.div`
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  font-family: 'Inter';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 283px;
  height: 145px;
  border-radius: 8px;
  transition: 0.3s;
  position: absolute;
  background-color: #555;
  @media only screen and (max-width: 768px) {
    width: 100vw;
    font-weight: 500;
    font-size: 15px;
    div {
      margin: 10px;
      width: 65vw;
    }
  }
`

export const Left = styled.div`
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .leftHeader {
    justify-content: space-between;
  }
  .leftSelectAll {
    display: flex;
  }
  @media only screen and (max-width: 768px) {
    display: none;
    overflow-y: hidden;

    .leftHeader {
      justify-content: center;
    }
    .leftSelectAll {
      display: none;
    }
  }

  .grid_container_row {
    padding: 0 16px;
    grid-gap: 24px;
    display: grid;
    grid-template-columns: 128px 88px 1fr 128px 128px 40px;
    width: 100%;
    .grid_container_center {
      display: flex;
      align-items: center;
    }
    .grid_container_end {
      justify-content: flex-end;
    }
    .grid_check {
      width: 16px;
      height: 16px;
      border: 1px solid #bdbdbd;
    }
  }

  .text_overflow {
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
  @media only screen and (min-width: 769px) {
    position: relative;
    padding-bottom: 56px;
  }
`

export const TableHeader = styled.div`
  margin-top: 24px;
  font-size: 10px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  color: #ffffff;
  background: #0f5286;
  border-radius: 8px;
`

export const BoxInvoicesPending = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  grid-template-columns: repeat(4, 1fr);
  @media only screen and (max-width: 768px) {
    //  display: none;
    grid-template-columns: repeat(1, 1fr);
  }
  gap: 8px;
  /* max-height: 100%; */
  /* overflow: scroll; */
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  /* > div {
    > div {
      span {
        color: #494949;
        font-size: 16px;
        line-height: 28px;
        font-family: 'Inter';
        font-weight: 500;
      }
    }
    span {
      color: #494949;
      font-size: 14px;
      line-height: 28px;
      font-family: 'Inter';
      font-weight: 500;
    }
    button {
      margin-left: 8px;
      border: none;
      width: 35px;
      height: 35px;
      border-radius: 8px;
      background: #edf1f5;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
  } */
  .btnConfirm {
    margin: 0px;
    width: 100%;
    height: 35px;
    background: var(--primaryColorDark);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
  .btnProccess {
    margin: 0px;
    width: 100%;
    height: 35px;
    background: #fff;
    border: 1.5px solid var(--secondaryColor);
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    color: var(--secondaryColor);
    cursor: initial;
  }
  .btnOpen {
    border: 1px solid var(--secondaryColor);
    margin: 0px;
    width: 100%;
    height: 35px;
    background: #fff;
    font-family: 'Inter';
    cursor: default;
    font-size: 14px;
    font-weight: 500;
    color: var(--secondaryColor);
  }
  .nameClient {
    color: #858585;
    font-size: 12px;
    font-family: 'Inter';
    line-height: 18px;
    font-weight: 300;
  }
  .valueAndDate {
    color: #858585;
    font-size: 15px;
    font-family: 'Inter';
    line-height: 25px;
    font-weight: 500;
  }
`

export const BoxInvoiceRight = styled.div`
  background-color: #fff;
  position: relative;
  width: 100%;
  z-index: 1;
  padding: 16px;
  border-radius: 16px;
  font-family: Inter;
  color: #212121;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  .box_invoice_right-100 {
    width: 100%;
  }
  .valueAndDate {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    color: #212121;
  }

  .box_invoice_venc {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: right;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .box_invoice_header {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
  }

  .box_invoice_btn {
    height: 40px;
    border: none;
    outline: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .box_invoice_border-top {
    padding-top: 16px;
    border-top: 1px solid #e0e0e0;
  }
`

interface BoxInvoiceBtnProps {
  backgroundColor?: string
  labelColor?: string
}
export const BoxInvoiceBtn = styled.button<BoxInvoiceBtnProps>`
  ${({ backgroundColor, labelColor }) => css`
    height: 40px;
    border: none;
    outline: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    font-family: Inter;
    font-size: 15px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    margin-top: 5px;
    transition: all 0.5s ease;
    background: ${backgroundColor};
    color: ${labelColor};
  `}
`

export const Right = styled.div`
  max-width: 314px;
  /* display: none; */
  flex-direction: column;
  @media only screen and (max-width: 768px) {
    max-width: 100%;
    width: 100%;
    display: flex;
  }
  @media only screen and (min-width: 769px) {
    height: calc(100vh - 120px);
  }
`
export const BoxPriceMobile = styled.button`
  border: none;
  height: 60px;
  left: 0px;
  bottom: 0px;
  transition: 0.5s ease;
  padding: 20px;
  margin: 0px;
  box-shadow: 0px 0px 10px #ccc;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  z-index: 20;
  transition: 0.3s;
  align-items: center;
  background: #00ab50;
  position: fixed;
  color: #fff;
  font-family: 'Inter';

  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;

  .box_price_mobile_continue {
    background: #ffffff;
    color: #212121;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 8px;
    padding: 0 16px;
    gap: 8px;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
    //position: relative;
  }
`

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #212121;
  .title {
    font-size: 18px;
    color: #2b2b2b;
    font-weight: 500;
  }
  .allInvoiceMobile {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    display: none;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    .title {
      font-size: 15px;
      color: #2b2b2b;
      font-weight: 500;
    }
    .allInvoiceMobile {
      display: flex;
    }
  }
`

export const TitleMobile = styled.div`
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: #212121;
  .title {
    font-size: 18px;
    color: #2b2b2b;
    font-weight: 500;
  }
  .allInvoiceMobile {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    padding-top: 24px;
    justify-content: space-between;
    flex-direction: row;
    padding-left: 16px;
    padding-right: 16px;
    .title {
      font-size: 15px;
      color: #2b2b2b;
      font-weight: 500;
    }
    .allInvoiceMobile {
      display: flex;
    }
  }
`

export const BoxInvoices = styled.div`
  max-height: 100%;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    height: 0px;
    background-color: var(--secondaryColor);
    position: absolute !important;
    right: 10px !important;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 20px;
    transform: translate(20px) !important;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
    margin-top: 50px;
    border-bottom-right-radius: 10px;
    position: absolute !important;
    right: 10px !important;
    transform: translate(20px) !important;
  }
  &::-webkit-scrollbar-thumb {
    background: var(--secondaryColor);
    border-radius: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    &::-webkit-scrollbar {
      width: 6px;
      height: 0px;
      background-color: white;
      border-top-right-radius: 16px;
      border-bottom-right-radius: 0px;
    }
    &::-webkit-scrollbar-track {
      background-color: white;
      margin-top: 50px;
      border-bottom-right-radius: 16px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--secondaryColor);
      border-radius: 20px;
    }
  }
`

export const BoxAllInvoices = styled.div`
  margin-top: 15px;
  width: 314px;
  border-radius: 8px;
  max-height: 100%;
  // overflow: auto;
  overflow-y: overlay;
  -webkit-overflow-scrolling: auto; /* pare de rolar imediatamente */

  background-color: #fff;

  @media only screen and (max-width: 768px) {
    width: 100%;
    display: none;

    height: calc(100vh - 30px);
    #ScrollContainer {
      position: sticky;
      position: -webkit-sticky;
      display: block;
      -webkit-overflow-scrolling: auto; /* pare de rolar imediatamente */
    }

    border-radius: 0;

    ::-webkit-scrollbar {
      background: transparent;

      width: 12px;
    }

    &::-webkit-scrollbar-track {
      margin-top: 54px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(103, 103, 103, 0.5);
      border-radius: 20px;
      border: 2.5px solid rgba(0, 0, 0, 0);
      border-right: 5px solid rgba(0, 0, 0, 0);

      background-clip: padding-box;
      max-height: 5px;
    }
  }
  @media only screen and (min-width: 769px) {
    height: calc(100% - 110px - 15px);
    position: relative;
    padding-bottom: 100px;
  }
`
interface BoxAllINvoicesMobileProps {
  isTotal?: boolean
}
export const BoxAllInvoicesMobile = styled.div<BoxAllINvoicesMobileProps>`
  margin-top: 15px;
  width: 314px;
  border-radius: 8px;
  max-height: 100%;
  // overflow: auto;
  overflow-y: overlay;
  -webkit-overflow-scrolling: auto; /* pare de rolar imediatamente */

  display: none;

  @media only screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: column;

    height: calc(100vh - 30px);
    padding: 0 16px;
    padding-bottom: ${({ isTotal }) => (isTotal ? '84px' : '24px')};
    gap: 8px;
    #ScrollContainer {
      position: sticky;
      position: -webkit-sticky;
      display: block;
      -webkit-overflow-scrolling: auto; /* pare de rolar imediatamente */
    }

    border-radius: 0;

    ::-webkit-scrollbar {
      background: transparent;

      width: 12px;
    }

    &::-webkit-scrollbar-track {
      margin-top: 54px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(103, 103, 103, 0.5);
      border-radius: 20px;
      border: 2.5px solid rgba(0, 0, 0, 0);
      border-right: 5px solid rgba(0, 0, 0, 0);

      background-clip: padding-box;
      max-height: 5px;
    }
  }
  @media only screen and (min-width: 769px) {
    height: calc(100% - 110px - 15px);
    position: relative;
  }
`

export const YearTitle = styled.h1`
  height: 50px;
  border-radius: unset;
  width: 314px;
  padding: 10px 16px;
  font-size: 20px;
  font-weight: 600;
  font-family: 'Inter';
  line-height: 28px;
  margin-bottom: 20px;
  background: var(--secondaryColor);
  color: #fff;
  position: -webkit-sticky;

  position: sticky;
  top: 0;
  z-index: 10;
  @media only screen and (max-width: 768px) {
    width: 100%;
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
    z-index: 1;
    display: block;
  }
`

export const Invoice = styled.div`
  width: 100%;
  margin-bottom: 10px;
  padding: 0px 10px 0px 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 5px;
  justify-content: space-between;
  span {
    line-height: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const BtnShowDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 90px;
  height: 40px;
  background-color: #fff;
  border: none;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.55px;
  font-family: 'Inter';
  font-weight: 500;
  color: var(--secondaryColor);
  border: 0.5px solid var(--secondaryColor);
`

export const BtnCopyDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  background-color: #edf1f5;
  height: 40px;
  border: none;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.55px;
  font-family: 'Inter';
  font-weight: 500;
  color: var(--secondaryColor);
`

export const BtnHideDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  color: #edf1f5;
  height: 40px;
  border: none;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.55px;
  font-family: 'Inter';
  font-weight: 500;
  background-color: var(--secondaryColor);
`

export const BtnPayDetails = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  color: #edf1f5;
  height: 40px;
  border: none;
  font-size: 12px;
  line-height: 28px;
  letter-spacing: 0.55px;
  font-family: 'Inter';
  font-weight: 500;
  background-color: var(--primaryColorDark);
`

export const Skeleton = styled.div`
  background-image: linear-gradient(
    -90deg,
    #fafafc 0%,
    #e4e4e4 50%,
    #fafafc 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`

export const SkeletonGreen = styled.div`
  background-image: linear-gradient(
    -90deg,
    var(--primaryColorDark) 0%,
    #abdb30 50%,
    var(--primaryColorDark) 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`

export const InputSearchContainer = styled.div`
  background: #ffffff;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 20px;
  color: #212121;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  gap: 8px;
  padding: 0 8px;
  overflow: hidden;
  width: 216px;
  input {
    border: none;
    height: 100%;
    ::placeholder {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #212121;
    }
  }
`

export const InputSearchContainerMobile = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
    background: #ffffff;
    height: 32px;
    min-height: 32px;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-radius: 20px;
    color: #212121;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    gap: 8px;
    padding: 0 8px;
    overflow: hidden;
    width: initial;

    margin: 0 16px;
    input {
      border: none;
      height: 100%;
      ::placeholder {
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        text-align: left;
        color: #212121;
      }
    }
  }
`
interface FilterButtonProps {
  isActive?: boolean
}

export const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`

export const FilterRowMobile = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: initial;
    margin: 0 16px;
    background: #e0e0e0;
    border-radius: 8px;
    height: 32px;
    min-height: 32px;
    margin-top: 16px;
    padding: 2px;
  }
`

export const FilterButtonMobile = styled.div<FilterButtonProps>`
  ${({ isActive }) => css`
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    gap: 8px;
    font-size: 11px;
    font-weight: ${isActive ? 600 : 400};
    line-height: 11px;
    text-align: center;
    color: #212121;

    background: ${isActive ? '#ffffff' : 'transparent'};
    padding: 0 8px;

    .filter_button_qtd {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${isActive ? '#ffffff' : '#f0f0f0'};
      color: #212121;
      font-size: 11px;
      font-weight: 600;
      line-height: 11px;
    }
  `}
`

export const FilterButton = styled.div<FilterButtonProps>`
  ${({ isActive }) => css`
    height: 32px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 16px;
    gap: 8px;
    font-size: 11px;
    font-weight: 600;
    line-height: 11px;
    text-align: center;
    color: ${isActive ? '#ffffff' : '#212121'};

    background: ${isActive ? '#00AB50' : '#ffffff'};
    padding: 0 8px;

    .filter_button_qtd {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: ${isActive ? '#ffffff' : '#f0f0f0'};
      color: #212121;
      font-size: 11px;
      font-weight: 600;
      line-height: 11px;
    }
  `}
`
export const InvoiceItem = styled.div`
  height: 40px;
  min-height: 40px;
  background: #ffffff;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #212121;

  .invoice_value {
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
  }

  .invoice_status_container {
    gap: 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

export const InvoiceItemMobile = styled.div`
  padding: 16px;
  border-radius: 16px;
  background: #ffffff;
  display: grid;
  grid-template-columns: 24px 1fr;
  gap: 16px;
  color: #212121;
  .invoice_mobile_column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .invoice_mobile_container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .invoice_mobile_space-between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .invoice_mobile_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
  }
  .invoice_mobile_label {
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }
  .invoice_mobile_title {
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;
    max-width: 75%;
  }
  .invoice_mobile_aling-left {
    text-align: left;
  }
  .invoice_mobile_aling-right {
    text-align: right;
  }
  .invoice_mobile_ellipsis {
    text-overflow: ellipsis;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }
  .invoice_mobile_line {
    width: 100%;
    height: 1px;
    background: #e0e0e0;
  }
  .invoice_mobile_mt-4 {
    margin-top: 4px;
  }
  .invoice_mobile_mt-12 {
    margin-top: 12px;
  }
`

export const MobileDetailButton = styled.button`
  background: #e0e0e0;

  border: none;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 0 8px;
  font-size: 11px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #212121;
`

interface InvoiceMobileCheckProps {
  isActive?: boolean
  isDisabled?: boolean
}
export const InvoiceMobileCheck = styled.div<InvoiceMobileCheckProps>`
  ${({ isActive, isDisabled }) => css`
    height: 20px;
    width: 20px;
    border-radius: 10px;
    background: ${isDisabled ? '#e0e0e0' : isActive ? '#00AB50' : '#dddddd'};
    display: flex;
    align-items: center;
    justify-content: center;
    .mobile_check_icon {
      width: 12px;
      height: 12px;
      color: #fff;
      display: ${isActive && !isDisabled ? 'block' : 'none'};
    }
  `}
`
interface InvoiceItemStatusProps {
  color?: string
}
export const InvoiceItemStatus = styled.div<InvoiceItemStatusProps>`
  ${({ color }) => css`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${color || '#9E9E9E'};
  `}
`

export const EmptyInvoice = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: center;
  color: #212121;
  padding: 0 48px;
  gap: 24px;
  position: relative;
`

export const EmptyInvoiceFooter = styled.div`
  left: 16px;
  right: 16px;
  bottom: 16px;
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #212121;
  gap: 8px;
  img {
    height: 16px;
  }
`

export const InvoiceListHeader = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  font-size: 10px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  border-bottom: 1px solid #dddddd;
  color: #212121;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 3;
`

export const InvoiceRightItem = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  color: #212121;
  .invoice_right_value_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-align: right;
  }
`

export const BoxInvoicesFooter = styled.div`
  height: 100px;
  border-top: 1px solid #dddddd;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 12px;
  position: fixed;
  bottom: 64px;
  background: #ffffff;
  width: inherit;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  /* width: 100%; */
  .invoice_footer_button {
    border: 0;
    width: 100%;
    height: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #ffffff;
    background: #00ab50;
    border-radius: 8px;
  }

  .invoice_footer_bill {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #212121;
    gap: 8px;
    img {
      height: 16px;
    }
  }
`

export const DebitsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`
interface BoxInvoiceStatusProps {
  backgroundColor: string
  circleColor: string
}
export const BoxInvoiceStatus = styled.div<BoxInvoiceStatusProps>`
  ${({ backgroundColor, circleColor }) => css`
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 8px;
    color: #212121;
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    text-align: left;

    border-radius: 12px;
    gap: 4px;
    background: ${backgroundColor};

    .invoice_status_circle {
      background: ${circleColor};
      width: 8px;
      min-width: 8px;
      max-width: 8px;

      height: 8px;
      min-height: 8px;
      max-height: 8px;
      border-radius: 50%;
    }
  `}
`

export const NoUnpaidContainer = styled.div`
  width: 100%;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  padding: 16px;
  flex-direction: row;
  gap: 16px;
  color: #212121;
  img {
    width: 128px;
    height: 128px;
  }
  .no_unpaid_title {
    font-family: Inter;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
  .no_unpaid_label {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
`

export const InvoiceIconButton = styled.button`
  outline: none;
  border: none;
  height: 40px;
  width: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee;
`
