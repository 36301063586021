import React from 'react';

import { Box, Container } from './styles';
import orangeLabs from '../../assets/icons/orange-logo.svg';

const Footer: React.FC = () => {
  return (
    <Container>
      <Box>
        <a href="https://orangelabs.com.br/" target="__blank">
          Arrecadação Omnichannel -
          <img color="#fff" src={orangeLabs} alt="Pigz Pay" />
        </a>
        <div>
          @Orange Labs 2021
        </div>
      </Box>
    </Container>
);
}

export default Footer;
