/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
/* eslint-disable radix */
import React from 'react'
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select'
import { faCreditCard } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as S from './styles'
import InputMask from '../../../../components/InputMask'
import Input from '../../../../components/Input'
import Select from '../../../../components/Select'
import boletoIcon from '../../../../assets/icons/boletoIcon.svg'

import pixSvg from '../../../../assets/icons/pixIcon.svg'
import { PaymentMethods } from '../../Models'
import InputDoc from '../../../../components/InputDoc'

interface PaymentTypeContainerProps {
  paymentFormat: string
  changePaymentFormat: (paymentFormat: string) => void
  formRef: React.RefObject<FormHandles> | undefined

  handleSubmit(): Promise<void>
  checkCardNumber(): Promise<void>
  checkValid(): Promise<void>
  checkName(): Promise<void>
  checkCvv(): Promise<void>
  checkDocNumber(): Promise<void>
  checkPhoneNumber(): Promise<void>
  checkEmail(): Promise<void>
  optionsSelect: any[] | undefined
  checkPlots(): Promise<void>
  setTotalFromParcelamentos(qtd: number): void
  method: PaymentMethods
}

export const PaymentTypeContainer = ({
  paymentFormat,
  changePaymentFormat,
  formRef,

  handleSubmit,
  checkCardNumber,
  checkValid,
  checkName,
  checkCvv,
  checkDocNumber,
  checkPhoneNumber,
  checkEmail,
  optionsSelect,
  checkPlots,
  setTotalFromParcelamentos,
  method,
}: PaymentTypeContainerProps): any => {
  const MethodIcon = (): any => {
    if (method.tag === 'PIX') {
      return <img className="payment_type_icon" alt="pix" src={pixSvg} />
    }
    if (method.tag === 'BOLETO') {
      return <img className="payment_type_icon" alt="boleto" src={boletoIcon} />
    }
    return <FontAwesomeIcon className="payment_type_icon" icon={faCreditCard} />
  }

  return (
    <>
      <S.PaymentTypeContainer
        onClick={() => {
          changePaymentFormat(method.tag)
        }}
        isActive={paymentFormat === method.tag}
      >
        <div className="payment_type_header">
          <div className="payment_type_header_left">
            <S.RadioButton isActive={paymentFormat === method.tag} />
            <p>
              Pagar com{' '}
              {method.tag === '3DS' ? 'Cartão de Crédito' : method.name}
            </p>
          </div>

          <MethodIcon />
        </div>
        {paymentFormat === method.tag ? (
          <div className="payment_type_body">
            <Form
              style={{
                margin: 0,
                display: 'flex',
                flexDirection: 'column',
                justifyContent:
                  method.tag === 'CREDIT' || method.tag === '3DS'
                    ? 'space-between'
                    : 'flex-start',
                height: '100%',
                width: '100%',
              }}
              ref={formRef}
              onSubmit={handleSubmit}
              autoSave=""
            >
              {method.tag === 'CREDIT' || method.tag === '3DS' ? (
                <>
                  <S.PaymentTypeGrid
                    style={{
                      gap: 15,
                      gridTemplateColumns: '1fr',
                    }}
                  >
                    <InputMask
                      customLabelStyle={{ marginTop: 8 }}
                      customBoxInputStyle={{ background: '#ffffff' }}
                      type="tel"
                      onBlurCapture={checkCardNumber}
                      name="cardNumber"
                      label={`Numero do Cartão de ${
                        paymentFormat === 'DEBIT' ? 'Débito' : 'Crédito'
                      }`}
                      mask="9999 9999 9999 9999"
                    />
                  </S.PaymentTypeGrid>
                  <S.PaymentTypeGrid
                    style={{
                      gap: 15,
                      gridTemplateColumns: '2fr 2fr',
                    }}
                  >
                    <InputMask
                      customBoxInputStyle={{ background: '#ffffff' }}
                      customLabelStyle={{ marginTop: 8 }}
                      type="tel"
                      onBlurCapture={checkValid}
                      name="valid"
                      label="Validade"
                      maxLength={5}
                      mask="99/99"
                    />
                    <InputMask
                      customLabelStyle={{ marginTop: 8 }}
                      customBoxInputStyle={{ background: '#ffffff' }}
                      type="tel"
                      onBlurCapture={checkCvv}
                      name="cvv"
                      label="CVV"
                      mask="9999"
                    />
                  </S.PaymentTypeGrid>
                  <S.PaymentTypeGrid
                    style={{
                      gap: 15,
                      gridTemplateColumns: '1fr',
                    }}
                  >
                    <Input
                      customBoxInputStyle={{ background: '#ffffff' }}
                      customLabelStyle={{ marginTop: 8 }}
                      onBlurCapture={checkName}
                      name="name"
                      maxLength={64}
                      label="Nome do Titular"
                    />
                  </S.PaymentTypeGrid>
                  <S.PaymentTypeGrid
                    style={{
                      gap: 15,
                      gridTemplateColumns: '2fr 2fr',
                    }}
                  >
                    {/* <InputMask
                      customBoxInputStyle={{ background: '#ffffff' }}
                      customLabelStyle={{ marginTop: 8 }}
                      type="tel"
                      onBlurCapture={checkDocNumber}
                      name="document"
                      label="CPF"
                      mask="999.999.999-99"
                    /> */}
                    <InputDoc
                      customBoxInputStyle={{ background: '#ffffff' }}
                      customLabelStyle={{ marginTop: 8 }}
                      type="doc"
                      onBlurCapture={checkDocNumber}
                      name="document"
                      label="CPF ou CNPJ"
                    />
                    <InputMask
                      type="tel"
                      onBlurCapture={checkPhoneNumber}
                      customBoxInputStyle={{ background: '#ffffff' }}
                      customLabelStyle={{ marginTop: 8 }}
                      name="phone"
                      label="Telefone"
                      mask="(99) 99999-9999"
                    />
                  </S.PaymentTypeGrid>
                  <Input
                    customBoxInputStyle={{ background: '#ffffff' }}
                    onBlurCapture={checkEmail}
                    name="email"
                    label="Email"
                    type="email"
                  />
                  {optionsSelect && optionsSelect.length ? (
                    <Select
                      isSearchable={false}
                      customLabelStyle={{ marginTop: 8 }}
                      options={optionsSelect}
                      onBlurCapture={checkPlots}
                      name="plots"
                      label="Quantidade de parcelas"
                      onChange={(e) => {
                        if (e && e.value !== undefined) {
                          setTotalFromParcelamentos(e.value)
                        }
                      }}
                    />
                  ) : null}
                </>
              ) : (
                <>
                  <S.PaymentTypeGrid
                    style={{
                      gap: 15,
                      gridTemplateColumns: '2fr 2fr',
                    }}
                  >
                    <Input
                      customBoxInputStyle={{ background: '#ffffff' }}
                      onBlurCapture={checkName}
                      name="name"
                      maxLength={64}
                      label="Nome do Titular"
                    />

                    <InputDoc
                      customBoxInputStyle={{ background: '#ffffff' }}
                      customLabelStyle={{ marginTop: 8 }}
                      type="doc"
                      onBlurCapture={checkDocNumber}
                      name="document"
                      label="CPF ou CNPJ"
                    />
                  </S.PaymentTypeGrid>
                  <S.PaymentTypeGrid
                    style={{
                      gap: 15,
                      gridTemplateColumns: '2fr 2fr',
                    }}
                  >
                    <Input
                      customBoxInputStyle={{ background: '#ffffff' }}
                      onBlurCapture={checkEmail}
                      name="email"
                      label="Email"
                      type="email"
                    />

                    <InputMask
                      type="tel"
                      onBlurCapture={checkPhoneNumber}
                      customBoxInputStyle={{ background: '#ffffff' }}
                      customLabelStyle={{ marginTop: 8 }}
                      name="phone"
                      label="Telefone"
                      mask="(99) 99999-9999"
                    />
                  </S.PaymentTypeGrid>
                </>
              )}
            </Form>
          </div>
        ) : null}
      </S.PaymentTypeContainer>
      {/* <S.PaymentTypeContainer isActive={paymentFormat === 'PIX'}>
        <div className="payment_type_header">
          <div
            onClick={() => {
              changePaymentFormat('PIX')
            }}
            className="payment_type_header_left"
          >
            <S.RadioButton isActive={paymentFormat === 'PIX'} />
            <p>Pagar com Pix</p>
          </div>

          <img className="payment_type_icon" alt="pix" src={pixSvg} />
        </div>
        <div className="payment_type_body">
          <Form
            style={{
              // padding: '0 15px',
              margin: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              height: '100%',
              width: '100%',
            }}
            ref={formRef}
            onSubmit={handleSubmitPix}
            autoSave=""
          >
            <div
              style={{
                gap: 15,
                gridTemplateColumns: '2fr 2fr',
              }}
            >
              <InputMask
                customBoxInputStyle={{ background: '#ffffff' }}
                customLabelStyle={{ marginTop: 8 }}
                type="tel"
                onBlurCapture={checkDocNumber}
                name="document"
                label="CPF"
                mask="999.999.999-99"
              />
              <InputMask
                type="tel"
                onBlurCapture={checkPhoneNumber}
                customBoxInputStyle={{ background: '#ffffff' }}
                customLabelStyle={{ marginTop: 8 }}
                name="phone"
                label="Telefone"
                mask="(99) 99999-9999"
              />
            </div>
            <Input
              customBoxInputStyle={{ background: '#ffffff' }}
              onBlurCapture={checkEmail}
              name="email"
              label="Email"
            />
          </Form>
        </div>
      </S.PaymentTypeContainer>
      <S.PaymentTypeContainer isActive={paymentFormat === 'BOLETO'}>
        <div className="payment_type_header">
          <div
            onClick={() => {
              changePaymentFormat('BOLETO')
            }}
            className="payment_type_header_left"
          >
            <S.RadioButton isActive={paymentFormat === 'BOLETO'} />
            <p>Pagar com Boleto</p>
          </div>

          <img className="payment_type_icon" alt="boleto" src={boletoIcon} />
        </div>
        <div className="payment_type_body">
          <Form
            style={{
              // padding: '0 15px',
              margin: 0,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              height: '100%',
              width: '100%',
            }}
            ref={formRef}
            onSubmit={handleSubmitPix}
            autoSave=""
          >
            <div
              style={{
                gap: 15,
                gridTemplateColumns: '2fr 2fr',
              }}
            >
              <InputMask
                customBoxInputStyle={{ background: '#ffffff' }}
                customLabelStyle={{ marginTop: 8 }}
                type="tel"
                onBlurCapture={checkDocNumber}
                name="document"
                label="CPF"
                mask="999.999.999-99"
              />
              <InputMask
                type="tel"
                onBlurCapture={checkPhoneNumber}
                customBoxInputStyle={{ background: '#ffffff' }}
                customLabelStyle={{ marginTop: 8 }}
                name="phone"
                label="Telefone"
                mask="(99) 99999-9999"
              />
            </div>
            <Input
              customBoxInputStyle={{ background: '#ffffff' }}
              onBlurCapture={checkEmail}
              name="email"
              label="Email"
            />
          </Form>
        </div>
      </S.PaymentTypeContainer> */}
    </>
  )
}
