import styled from 'styled-components';

export const Container = styled.div`
  height: 50px;
  z-index: 20;
  top: 0px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 10px;
  @media only screen and (max-width: 768px){
    height: 50px;
  }
`;

export const Box = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  font-family: 'Inter';
  padding: 15px;
  a {
    cursor: pointer;
    text-decoration: none;
    color: #494949;
    display: flex;
    align-items: center;
    color: #494949;
    font-size: 12px;
    img{
      height: 12px;
      margin-left: 6px;
    }
  }
  div{
    display: flex;
    align-items: center;
    color: #494949;
    font-size: 12px;
  }
  @media only screen and (max-width: 768px){
    max-width: 100vw;
    a{
      height: 20px;
      display: flex;
      justify-content:center;
      align-items: center;
      color: '#494949';
      font-size: 12px;
      img{
        height: 12px;
        margin-left: 6px;
      }
    }
    div{
      height: 20px;
      display: flex;
      justify-content:center;
      align-items: center;
      color: '#494949';
      font-size: 12px;
    }
  }
`;
