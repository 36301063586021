/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'
import { AxiosResponse } from 'axios'
import { nanoid } from 'nanoid'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import cookie from 'react-cookies'

import { ReCaptcha } from 'react-recaptcha-v3'
import { User } from '../../utils/models'
import loadingIcon from '../../assets/icons/loading-primary.svg'
import { Container } from './styles'
import { newApi } from '../../services/api'
import { NotFoundInformationModal } from '../../components/NotFoundInformationModal'
import { NotFoundDocInformationModal } from '../../components/NotFoundDocInformationModal'
import { AlreadyPaidInvoiceModal } from '../../components/AlreadyPaidInvoiceModal'
import { InvalidPaidInvoiceModal } from '../../components/InvalidPaidInvoiceModal'

const PagamentoRedirect: React.FC = ({ modules, dispatch }: any) => {
  const [isNotFoundGuiaModalOpen, setIsNotFoundGuiaModalOpen] = useState(false)
  const [alreadyPaidModalOpen, setAlreadyPaidModalOpen] = useState(false)
  const [invalidPaidInvoiceModal, setInvalidPaidInvoiceModal] = useState(false)
  const [isNotFoundDocModalOpen, setIsNotFoundDocModalOpen] = useState(false)

  function openInvalidPaidInvoiceModal(): void {
    setInvalidPaidInvoiceModal(true)
  }
  function closeInvalidPaidInvoiceModal(): void {
    const refreshRef = localStorage.getItem('refreshRef')
    const docField = localStorage.getItem('refreshDoc')
    const pushState = {
      refreshRef,
      docField,
    }
    history.push({
      pathname: '/',
      state: pushState,
    })
    setInvalidPaidInvoiceModal(false)
  }
  function openNotFoundGuiaModal(): void {
    setIsNotFoundGuiaModalOpen(true)
  }
  function closeNotFoundGuiaModal(): void {
    const refreshRef = localStorage.getItem('refreshRef')
    const docField = localStorage.getItem('refreshDoc')
    const pushState = {
      refreshRef,
      docField,
    }
    history.push({
      pathname: '/',
      state: pushState,
    })
    setIsNotFoundGuiaModalOpen(false)
  }
  function openNotFoundDocModal(): void {
    setIsNotFoundDocModalOpen(true)
  }
  function closeNotFoundDocModal(): void {
    localStorage.clear()
    sessionStorage.clear()
    dispatch({
      type: 'RESET',
    })
    history.push('/login')
    setIsNotFoundDocModalOpen(false)
  }

  function openAlreadyPaidModal(): void {
    setAlreadyPaidModalOpen(true)
  }
  function closeAlreadyPaidModal(): void {
    localStorage.clear()
    sessionStorage.clear()
    dispatch({
      type: 'RESET',
    })
    history.push('/login')
    setAlreadyPaidModalOpen(false)
  }

  const [googleRecaptcha, setGoogleRecaptcha] = useState<string | undefined>(
    undefined
  )
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const history = useHistory()
  const [captcha, setCaptcha] = useState<ReCaptcha>()
  async function paymentHandle(
    guia: string,
    token: string,
    invoices: any[]
  ): Promise<void> {
    let client
    if (invoices && token) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      client = invoices

      try {
        const foundGuia = invoices.find((invoice) => invoice.id === guia)

        if (foundGuia) {
          const invoicesList: any = [{ id: foundGuia.invoice_id }]
          const { data } = await newApi.post(
            '/mobile/payment',
            {
              merchant: process.env.REACT_APP_MERCHANT_FIXED,
              doc: '',
              invoices: invoicesList,
            },
            {
              headers: {
                Authorization: `Basic ${token}`,
                'Content-Type': 'application/json',
              },
            }
          )

          if (data) {
            const compartilhado = queryParams.get('compartilhado')
            if (compartilhado) {
              sessionStorage.setItem('compartilhado', compartilhado)
            }
            history.push({
              pathname: '/payment',
              state: {
                data,
              },
            })
          }
        } else {
          // history.push('/')
          openNotFoundGuiaModal()
        }
      } catch (error) {
        console.error(error)
        openNotFoundGuiaModal()

        // history.push('/')
      }
    }
  }

  async function getRefData(doc: string, baseEncript: string): Promise<void> {
    try {
      const response = await newApi.get<any, AxiosResponse<any>>(
        `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/ref?doc=${doc}`,
        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
            'X-Recaptcha-Token': googleRecaptcha,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )

      if (response.data) {
        localStorage.setItem('Ref', JSON.stringify(response.data))

        localStorage.setItem('first_time', 'true')
      }
    } catch (error) {
      console.error(error)
    }
  }

  async function getDocData(doc: string): Promise<void> {
    const baseEncript = btoa(`${nanoid()}:s${nanoid()}`)
    sessionStorage.setItem('token', baseEncript)
    sessionStorage.setItem(
      'token_created',
      btoa(`${new Date().toISOString()}:s${nanoid()}`)
    )
    try {
      const response = await newApi.get<any, AxiosResponse<any>>(
        `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/invoices?doc=${doc}`,

        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
            'X-Recaptcha-Token': googleRecaptcha,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )
      const { data } = response
      const total = response.headers['x-total']
      localStorage.setItem('x-total', total)
      // await getRefData(doc, baseEncript)

      if (data) {
        const firstData = data[0]
        if (firstData) {
          const firstDataObj =
            firstData && firstData.extras && firstData.extras !== null
              ? firstData.extras
              : {}
          if (firstData.ref) {
            localStorage.setItem(
              'Ref',
              JSON.stringify({
                id: firstData.ref,
              })
            )
          } else
            localStorage.setItem(
              'Ref',
              JSON.stringify({
                doc,
              })
            )

          localStorage.setItem('first_time', 'true')
        }
        localStorage.setItem('Invoices', JSON.stringify(data))
        if (data[0]) localStorage.setItem('invoice', JSON.stringify(data[0]))
        localStorage.setItem('refreshDoc', doc)
        closeNotFoundGuiaModal()
        // history.push('/')
      }
    } catch (error) {
      // history.push('/login')
      openNotFoundDocModal()
    }
  }

  async function handleUC(item: any, baseEncript: string): Promise<void> {
    try {
      const response = await newApi.get<any, AxiosResponse<any>>(
        `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/invoices?ref=${item.id}`,
        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
          },
        }
      )
      const { data } = response
      const total = response.headers['x-total']
      localStorage.setItem('x-total', total)
      if (data) {
        const firstData = data[0]
        if (firstData) {
          const firstDataObj =
            firstData && firstData.customer && firstData.customer !== null
              ? firstData.customer
              : 'SEM NOME'
        }
      }
      localStorage.setItem('Invoices', JSON.stringify(data))
      if (data[0]) localStorage.setItem('invoice', JSON.stringify(data[0]))
      localStorage.setItem('refreshRef', item.id)
      setTimeout(() => {
        closeNotFoundGuiaModal()
      }, 2000)
    } catch (error) {
      console.log(error)
    }
  }

  async function getProcessoData(docField: string): Promise<void> {
    const baseEncript = btoa(`${nanoid()}:s${nanoid()}`)
    sessionStorage.setItem('token', baseEncript)
    sessionStorage.setItem(
      'token_created',
      btoa(`${new Date().toISOString()}:s${nanoid()}`)
    )
    try {
      const { data } = await newApi.get<any, AxiosResponse<any>>(
        `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/ref?ref=${docField}`,
        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
            'X-Recaptcha-Token': googleRecaptcha,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )

      if (data) {
        localStorage.setItem('Ref', JSON.stringify(data))

        localStorage.setItem('first_time', 'true')
        if (data.id) {
          await handleUC(data, baseEncript)
        } else {
          openNotFoundDocModal()
        }
      }
    } catch (error) {
      openNotFoundDocModal()
    }
  }

  async function getInvoiceData(invoiceValue: string): Promise<void> {
    const baseEncript = btoa(`${nanoid()}:s${nanoid()}`)
    sessionStorage.setItem('token', baseEncript)
    sessionStorage.setItem(
      'token_created',
      btoa(`${new Date().toISOString()}:s${nanoid()}`)
    )

    try {
      const { data } = await newApi.get<any, AxiosResponse<any>>(
        `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/invoice?invoice=${invoiceValue}`,
        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
            'X-Recaptcha-Token': googleRecaptcha,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )
      const firstData = data

      if (data && data.paymentStatus === 1) {
        openAlreadyPaidModal()
        return
      }

      if (firstData) {
        if (firstData.ref) {
          localStorage.setItem(
            'Ref',
            JSON.stringify({
              id: firstData.ref,
            })
          )
          localStorage.setItem('refreshRef', firstData.ref)
        } else {
          localStorage.setItem(
            'Ref',
            JSON.stringify({
              doc: firstData.extras?.doc,
            })
          )
          localStorage.setItem('refreshDoc', firstData.extras?.doc)
        }

        localStorage.setItem('first_time', 'true')
      }

      localStorage.setItem('Invoices', JSON.stringify([data]))
      if (data) localStorage.setItem('invoice', JSON.stringify(data))
      if (data && data.paymentStatus === -1) {
        openInvalidPaidInvoiceModal()
        return
      }
      setTimeout(() => {
        paymentHandle(invoiceValue, baseEncript, [data])
      }, 2000)
    } catch (error) {
      console.error(error)
      openNotFoundGuiaModal()
    }
  }

  async function getInvoiceDataV2(invoiceValue: string): Promise<void> {
    const baseEncript = btoa(`${nanoid()}:s${nanoid()}`)
    sessionStorage.setItem('token', baseEncript)
    sessionStorage.setItem(
      'token_created',
      btoa(`${new Date().toISOString()}:s${nanoid()}`)
    )

    try {
      const { data } = await newApi.post<any, AxiosResponse<any>>(
        // `/mobile/payment/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/invoice?invoice=${invoiceValue}`,
        `/mobile/payment`,
        {
          merchant: `${process.env.REACT_APP_MERCHANT_FIXED}`,
          doc: '',
          invoices: [
            {
              id: invoiceValue,
            },
          ],
        },
        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
            'X-Recaptcha-Token': googleRecaptcha,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )

      let firstInvoice = data.invoices[0]
      if (!firstInvoice) {
        openNotFoundGuiaModal()
        return
      }
      if (firstInvoice) {
        firstInvoice = { ...firstInvoice, invoice_id: firstInvoice.id }
        if (firstInvoice.contentRef) {
          localStorage.setItem(
            'Ref',
            JSON.stringify({
              id: firstInvoice.contentRef,
            })
          )
          localStorage.setItem('refreshRef', firstInvoice.contentRef)
        } else {
          localStorage.setItem(
            'Ref',
            JSON.stringify({
              doc: data.doc,
            })
          )
          localStorage.setItem('refreshDoc', data.doc)
        }

        localStorage.setItem('first_time', 'true')
      }

      const dataInvoices = data.invoices.map((invoice:any) => {
        return { ...invoice, invoice_id: invoice.id }
      })

      localStorage.setItem('Invoices', JSON.stringify(dataInvoices))
      if (data) localStorage.setItem('invoice', JSON.stringify(firstInvoice))

      if (data) {
        const compartilhado = queryParams.get('compartilhado')
        if (compartilhado) {
          sessionStorage.setItem('compartilhado', compartilhado)
        }
        history.push({
          pathname: '/payment',
          state: {
            data,
          },
        })
      }
    } catch (error) {
      console.error(error)
      openNotFoundGuiaModal()
    }
  }

  // renew captcha
  useEffect(() => {
    if (googleRecaptcha)
      setTimeout(() => {
        if (googleRecaptcha) {
          // console.log('recaptcha')
          setGoogleRecaptcha(undefined)
          captcha?.execute()
        }
      }, 60 * 2 * 1000)
  }, [googleRecaptcha])

  useEffect(() => {
    const doc = queryParams.get('doc')
    const processo = queryParams.get('processo')
    const guia = queryParams.get('guia')
    if (guia && googleRecaptcha) {
      // await
      getInvoiceDataV2(guia)
      return
    }

    if (doc && googleRecaptcha) {
      getDocData(doc)
      return
    }
    if (processo && googleRecaptcha) {
      getProcessoData(processo)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [googleRecaptcha])

  const verifyCallback = (recaptchaToken: string): void => {
    setGoogleRecaptcha(recaptchaToken)
  }

  return (
    <Container>
      <ReCaptcha
        ref={(e) => {
          if (e) setCaptcha(e)
        }}
        sitekey={process.env.REACT_APP_RECAPTCHA as string}
        action="auth_device"
        verifyCallback={verifyCallback}
      />
      <img
        src={loadingIcon}
        alt="Carregando"
        style={{ height: 140, margin: 0 }}
      />
      <span>Carregando</span>
      {isNotFoundGuiaModalOpen ? (
        <NotFoundInformationModal closeModal={closeNotFoundGuiaModal} />
      ) : null}
      {isNotFoundDocModalOpen ? (
        <NotFoundDocInformationModal closeModal={closeNotFoundDocModal} />
      ) : null}

      {alreadyPaidModalOpen ? (
        <AlreadyPaidInvoiceModal closeModal={closeAlreadyPaidModal} />
      ) : null}
      {invalidPaidInvoiceModal ? (
        <InvalidPaidInvoiceModal closeModal={closeInvalidPaidInvoiceModal} />
      ) : null}
    </Container>
  )
}

export default connect((state: any) => ({ modules: state.NOME_CLIENTE }))(
  PagamentoRedirect
)
