import styled, { css } from 'styled-components'

interface InvoiceItemProps {
  isOpen?: boolean
  isDisabledButton?: boolean
}

export const InvoiceItem = styled.div<InvoiceItemProps>`
  ${({ isOpen, isDisabledButton }) => css`
    background: #ffffff;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #212121;

    .invoice_header {
      height: 40px;
      min-height: 40px;
      cursor: pointer;
    }

    .invoice_value {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      text-align: right;
    }

    .invoice_status_container {
      gap: 8px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .arrow_icon {
      ${isOpen ? 'transform: rotate(180deg);' : ''}
    }
    .invoice_max_width {
      width: 100%;
    }
    .invoice_row_between {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .invoice_custas_container {
      display: ${isOpen ? 'flex' : 'none'};
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 64px;
      padding-right: 16px;
      padding-bottom: 8px;
    }

    .invoices_custas_label {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      color: #212121;
      text-transform: uppercase;
    }
    .invoices_custas_title {
      font-size: 12px;
      font-weight: 600;
      line-height: 24px;
      text-align: right;

      color: #212121;
    }

    .invoice_pointer {
      cursor: pointer;
    }
    .invoice_item_check {
      cursor: ${isDisabledButton ? 'not-allowed' : 'pointer'};
    }

    .invoice_item_dropdown_button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  `}
`

interface InvoiceItemStatusProps {
  color?: string
}
export const InvoiceItemStatus = styled.div<InvoiceItemStatusProps>`
  ${({ color }) => css`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${color || '#9E9E9E'};
  `}
`

export const DropdownButton = styled.button`
  outline: none;
  border: none;
  height: 32px;
  width: 32px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eeeeee;
`
