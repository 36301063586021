/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'
import { GlobalRow } from '../GlobalRow'
import * as S from './styles'

export interface OmniChannelComponentProps {
  isLogoWhite?: boolean
}

export const OmniChannelComponent = (props: OmniChannelComponentProps) => {
  const { isLogoWhite } = props
  return (
    <S.Wrapper isLogoWhite={isLogoWhite}>
      {!isLogoWhite ? (
        <img
          alt="bill"
          src={`${process.env.PUBLIC_URL}/assets/icons/bill-logo-black.svg`}
        />
      ) : (
        <img
          alt="bill"
          src={`${process.env.PUBLIC_URL}/assets/icons/bill-logo-white.svg`}
        />
      )}
      <p>Arrecadação Omnichannel</p>
    </S.Wrapper>
  )
}
