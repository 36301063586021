/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
export function removeDuplicates(items: any[]): any[] {
  const seen = new Set<number>();
  const result: any[] = [];

  for (let i = items.length - 1; i >= 0; i--) {
      if (!seen.has(items[i].id)) {
          seen.add(items[i].id);
          result.unshift(items[i]);
      }
  }

  return result;
}

export function removeDuplicatesInvoiceId(items: any[]): any[] {
  const seen = new Set<number>();
  const result: any[] = [];

  for (let i = items.length - 1; i >= 0; i--) {
      if (!seen.has(items[i].invoice_id)) {
          seen.add(items[i].invoice_id);
          result.unshift(items[i]);
      }
  }

  return result;
}
