/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons'
import { useField } from '@unform/core'
import { Container, Label, BoxInput } from './styles'

interface Props {
  name: string
  label?: string
  customLabelStyle?: React.CSSProperties
  customBoxInputStyle?: React.CSSProperties
}
type InputProps = JSX.IntrinsicElements['input'] & Props
const InputDoc: React.FC<InputProps> = ({
  name,
  label,
  customLabelStyle,
  customBoxInputStyle,
  ...rest
}: any) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)

  const [errorField, setErrorField] = useState<boolean>(false)

  useEffect(() => {
    if (error) {
      setErrorField(true)
    } else {
      setErrorField(false)
    }
  }, [error])

  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
  }, [fieldName, registerField])

  const [inputValue, setInputValue] = useState('')

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function handleChange(s: string, type: string): void {
    if (type !== 'document') {
      setInputValue(s)
    } else {
      const masked = maskDocument(s)
      setInputValue(masked)
    }
  }

  function maskDocument(preValue: string): string {
    let value = preValue
    value = value.replace(/\D/g, '')
    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
    } else {
      value = value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5'
      )
    }
    return value
  }

  return (
    <Container>
      {label && <Label style={customLabelStyle}>{label}</Label>}
      <BoxInput style={customBoxInputStyle}>
        <input
          id={fieldName}
          className={errorField ? 'error' : ''}
          ref={inputRef}
          defaultValue={defaultValue}
          onFocus={() => {
            setErrorField(false)
          }}
          value={inputValue}
          onChange={(e) => {
            handleChange(e.target.value, e.target.id)
            // console.log(e.target.id)
          }}
          maxLength={'00.000.000/0000-00'.length}
          {...rest}
        />
        {!errorField ? (
          <div />
        ) : (
          <FontAwesomeIcon
            icon={faExclamationCircle}
            color="#eb4646"
            size="1x"
            title={errorField ? `*${error}` : ''}
          />
        )}
      </BoxInput>
    </Container>
  )
}
export default InputDoc
