import { AxiosResponse } from 'axios'
import { newApi } from '../../services/api'

export const defaultPageSize = 10
export const pageSizes = [
  { value: 10, label: '10 por página' },
  { value: 25, label: '25 por página' },
  { value: 50, label: '50 por página' },
]

export async function handleUC(
  itemId: string,
  page: number,
  pageSize?: number
): Promise<any> {
  const baseEncript = sessionStorage.getItem('token')
  try {
    const response = await newApi.get<any, AxiosResponse<any>>(
      `/mobile/bill/merchant/${
        process.env.REACT_APP_MERCHANT_FIXED
      }/invoices?ref=${itemId}&page=${page}&size=${
        pageSize || defaultPageSize
      }`,
      {
        headers: {
          Authorization: `Basic ${baseEncript}`,
        },
      }
    )

    const { data } = response
    const total = response.headers['x-total']
    localStorage.setItem('x-total', total)
    return { invoices: data, total }
  } catch (error) {
    console.log(error)
    return undefined
  }
}

export async function handleConsultDoc(
  docField: string,
  page: number,
  pageSize?: number
): Promise<any> {
  const baseEncript = sessionStorage.getItem('token')

  try {
    const response = await newApi.get<any, AxiosResponse<any>>(
      `/mobile/bill/merchant/${
        process.env.REACT_APP_MERCHANT_FIXED
      }/invoices?doc=${docField}&page=${page}&size=${
        pageSize || defaultPageSize
      }`,

      {
        headers: {
          Authorization: `Basic ${baseEncript}`,
        },
      }
    )
    const { data } = response
    const total = response.headers['x-total']
    localStorage.setItem('x-total', total)

    return { invoices: data, total }
  } catch (error) {
    console.error(error)
    return undefined
  }
}
