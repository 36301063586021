export function maskCustomDocument(preValue: string): string {
  let value = preValue.replace(/\D/g, ''); // Remove all non-digit characters
  if (value.length < 19) {
      return value
  }
  value = value.replace(
      /(\d{7})(\d{2})(\d{4})(\d{1})(\d{2})(\d{4})/,
      '$1-$2.$3.$4.$5.$6'
  );
  return value;
}


export function maskDocument(preValue: string): string {
  let value = preValue
  value = value.replace(/\D/g, '')
  if (value.length <= 11) {
    value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
  } else {
    value = value.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5'
    )
  }
  return value
}


export  function maskBarCodeString(preValue: string): string {
  let value = preValue.replace(/\D/g, '');
  value = value.padEnd(38, '0'); // Ensure the string is long enough

  value = value.replace(
    /(\d{5})(\d{5}) (\d{5})(\d{6}) (\d{5})(\d{6}) (\d{1}) (\d{15})/,
    '$1.$2 $3.$4 $5.$6 $7 $8'
  );

  return value;
}
