/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import cookie from 'react-cookies'
import { StateInitial, User } from '../../utils/models'
import * as S from './styles'
import logo from '../../assets/icons/cpfj-icon-colored.svg'
import symbolColored from '../../assets/icons/colored-symbol.svg'
import logout from '../../assets/icons/logout.svg'
import swapIcon from '../../assets/icons/swap_horiz.svg'
import iconWhite from '../../assets/icons/cpfl-white-icon.svg'
import logoutWhite from '../../assets/icons/logout_white.svg'
import { maskCustomDocument, maskDocument } from '../../utils/altMaskDocument'
import Global from '../../styles/global'
import { GlobalRow } from '../GlobalRow'
import { GlobalColumn } from '../GlobalColumn'

interface Data {
  modules: StateInitial
  dispatch: any
}

const Header = ({ modules, dispatch }: Data): any => {
  const history = useHistory()
  const [nameClient, setNameClient] = useState<string | null>()
  const [ucClient, setUcClient] = useState<string | null>()
  const [isDoc, setIsDoc] = useState(false)
  const [adress, setAdress] = useState<string | null>()
  const [showDetailsMobile, setShowDetailsMobile] = useState(false)
  const [refsList, setRefsList] = useState<any[]>([])

  useEffect(() => {
    // const userTemp =  localStorage.getItem('info');
    const userTemp = localStorage.getItem('Invoices')
    // console.log(userTemp,'tempoUser')
    const usersTemp = localStorage.getItem('Refs')
    if (usersTemp) {
      setRefsList(JSON.parse(usersTemp))
    }
    const refTemp = localStorage.getItem('Ref')
    if (refTemp) {
      const usersInfo = JSON.parse(refTemp) as any

      setAdress(`${usersInfo.description}`)
      if (usersInfo.id) {
        setUcClient(usersInfo.id)
      } else if (usersInfo.doc) {
        setUcClient(usersInfo.doc)
        setIsDoc(true)
      }
    }
  }, [modules])

  function reset(): unknown {
    return {
      type: 'RESET',
    }
  }

  function handleLogout(): void {
    localStorage.clear()
    sessionStorage.clear()
    cookie.remove('OrangeLabs')
    dispatch(reset())
    history.push('/login')
  }

  function handleGoToUc(): void {
    localStorage.removeItem('Invoices')
    history.push('/uc')
  }

  const maskedClient = isDoc
    ? maskDocument(ucClient || '00000')
    : maskCustomDocument(ucClient || '00000')

  return (
    <S.Container>
      <S.BoxDesktop
        style={{
          maxHeight: showDetailsMobile ? '400px' : '90px',
          transition: '0.3s',
          minHeight: showDetailsMobile ? '400px' : '90px',
        }}
      >
        <div
          id="textTop"
          style={{
            borderBottom: showDetailsMobile ? '0.5px solid #9e9e9e' : 'none',
          }}
        >
          <GlobalRow gap="24px" justifyContent="flex-start">
            <div className="logoGrid">
              {
                // alterar para generica
              }
              <img src={logo} alt="CPFL" className="logoBig" />
              <img
                style={{ height: 39 }}
                src={symbolColored}
                alt="CPFL"
                className="logoSmall"
              />
            </div>
            <GlobalColumn alignItems="flex-start">
              <S.ContentUc>{ucClient || '-'}</S.ContentUc>
              <S.ContentTitle style={{ overflow: 'hidden' }}>
                Unidade consumidora
              </S.ContentTitle>
            </GlobalColumn>
            <GlobalColumn alignItems="flex-start">
              <S.ContentUc>{'-'}</S.ContentUc>
              <S.ContentTitle style={{ overflow: 'hidden' }}>
                Cliente
              </S.ContentTitle>
            </GlobalColumn>
            <GlobalColumn alignItems="flex-start">
              <S.ContentUc>{'-'}</S.ContentUc>
              <S.ContentTitle style={{ overflow: 'hidden' }}>
                Endereço da UC
              </S.ContentTitle>
            </GlobalColumn>
          </GlobalRow>

          <div id="textViewMobile" style={{ gap: 20 }}>
            {ucClient && (
              <div id="uc" style={{ display: 'flex', flexDirection: 'column' }}>
                <S.ContentTitle style={{ overflow: 'hidden' }}>
                  {`${nameClient ? `${nameClient.substr(0, 15)}...` : '-'}`}
                </S.ContentTitle>
                <S.ContentUc>{`UC ${ucClient || '00000'}`}</S.ContentUc>
              </div>
            )}
            <S.Menu
              onClick={() => {
                setShowDetailsMobile(!showDetailsMobile)
              }}
            >
              <FontAwesomeIcon
                style={{
                  transition: '0.3s',
                  transform: showDetailsMobile
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)',
                }}
                icon={faChevronDown}
                color="var(--secondaryColor)"
                size="1x"
              />
            </S.Menu>
          </div>
          <div
            id="textViewPc"
            style={{ flexDirection: 'row', gap: 30, alignItems: 'center' }}
          >
            {refsList && refsList.length > 1 ? (
              <S.ChangeRef onClick={handleGoToUc}>
                <img src={swapIcon} alt="trocar" />
                <p>Alterar UC</p>
              </S.ChangeRef>
            ) : null}
            <S.Logout onClick={handleLogout}>
              <img src={logout} alt="Sair" />
              <p>Sair</p>
            </S.Logout>

            <S.Menu
              onClick={() => {
                setShowDetailsMobile(!showDetailsMobile)
              }}
            >
              <FontAwesomeIcon
                style={{
                  transition: '0.3s',
                  transform: showDetailsMobile
                    ? 'rotate(180deg)'
                    : 'rotate(0deg)',
                }}
                icon={faChevronDown}
                color="var(--secondaryColor)"
                size="1x"
              />
            </S.Menu>
          </div>
        </div>
        <S.BarLoading
          style={{
            height: 2,
            visibility: modules.SEARCH_DB ? 'visible' : 'hidden',
          }}
        />
        <div id="textBody">
          {ucClient && (
            <>
              <S.Label>UNIDADE CONSUMIDORA</S.Label>
              <S.Content>{ucClient || '00000'}</S.Content>
            </>
          )}
          {nameClient && (
            <>
              <S.Label>CLIENTE ATUAL</S.Label>
              <S.Content>{nameClient}</S.Content>
            </>
          )}
          {adress && (
            <>
              <S.Label>ENDEREÇO</S.Label>
              <S.Content>{adress}</S.Content>
            </>
          )}
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <S.Logout onClick={handleLogout}>
              <img src={logout} alt="Sair" style={{ height: 20 }} />
            </S.Logout>
            <h3 style={{ color: '#494949' }}>Sair</h3>
          </div>
        </div>
      </S.BoxDesktop>

      <S.BoxMobile>
        <GlobalRow gap="16px" justifyContent="flex-start">
          <img className="box_img" src={logo} alt="Imagem" />

          <GlobalColumn alignItems="flex-start">
            <S.ContentUc>{`UC ${ucClient || '-'}`}</S.ContentUc>
          </GlobalColumn>
        </GlobalRow>
        <S.Logout onClick={handleLogout}>
          <img src={logout} alt="Sair" />
        </S.Logout>
      </S.BoxMobile>
    </S.Container>
  )
}

export default connect((state: StateInitial) => ({ modules: state }))(Header)
