/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, useState } from 'react';
import ReactInputMask, { Props as InputProps } from 'react-input-mask';
import { useField } from '@unform/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Container, Label, BoxInput } from './styles';

interface Props extends InputProps {
  name: string;
  label?:string;
  customLabelStyle?:React.CSSProperties
  customBoxInputStyle?:React.CSSProperties
}

const InputMask: React.FC<Props> = ({ name, label,customLabelStyle,customBoxInputStyle, ...rest }:any) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [errorField, setErrorField] = useState<boolean>(false);

  useEffect(()=>{
    if(error){
      setErrorField(true)
    }else{
      setErrorField(false)
    }
  },[error])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue(ref: any, value: string) {
        ref.setInputValue(value);
      },
      clearValue(ref: any) {
        ref.setInputValue('');
      },
    });
  }, [fieldName, registerField]);


  return (
    <Container>
      { label && <Label style={customLabelStyle}>{label}</Label> }
      <BoxInput style={customBoxInputStyle}>
        <ReactInputMask onFocus={()=>{setErrorField(false)}} maskChar="" ref={inputRef} className={errorField ? 'input-error' : 'input'} defaultValue={defaultValue} {...rest} />
        {!errorField
          ? <div />
          : <FontAwesomeIcon icon={faExclamationCircle} color='#eb4646' size='1x' title={errorField ? `*${error}` : ''} />}
      </BoxInput>
    </Container>
  );
};

export default InputMask;
