/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable new-cap */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react'
import jsPDF from 'jspdf'
import { useBarcode } from '@createnextapp/react-barcode'
import { parseISO, format } from 'date-fns'
import pt from 'date-fns/locale/pt-BR'

import { InvoiceDTO, Invoice, PayDTO, TFInvoice } from '../../utils/models'
import logoBillWhite from '../../assets/images/bill-logo-white.png'
import dollarIcon from '../../assets/images/dollar.png'
import * as S from './styles'

import {
  BoletoDataProps,
  BoletoTransationProps,
} from '../../pages/Payment/Models'
import { maskCustomDocument, maskDocument } from '../../utils/altMaskDocument'
import { base64Font } from './base64Font'
import { base64FontBold } from './base64FontBold'
import { base64FontSemiBold } from './base64FontSemiBold'

interface PaymentSlipBoletoProps {
  invoice: TFInvoice
}

export const PaymentSlipBoletoInvoice = ({
  invoice,
}: PaymentSlipBoletoProps): any => {
  const [client, setClient] = useState<Invoice>()
  const logo = `${process.env.PUBLIC_URL}/assets/images/cpfl-colored-icon.png`
  const logoGreen = `${process.env.PUBLIC_URL}/assets/images/bill-icon-green.png`
  const logoPigz = `${process.env.PUBLIC_URL}/assets/images/icon-orange.png`
  const [paymentInvoices, setPaymentInvoices] = useState<Array<TFInvoice>>([])
  const [clientRef, setClientRef] = useState<any>()

  useEffect(() => {
    const tempClientRef = localStorage.getItem('Ref')
    if (tempClientRef) {
      const temp = JSON.parse(tempClientRef)
      setClientRef(temp)
    }
  }, [])

  function dataFormat(data: string): string {
    const Date = parseISO(data)
    const formattedDate = format(Date, 'dd/MM/yyyy', {
      locale: pt,
    })

    return formattedDate.replace('  ', '')
  }

  useEffect(() => {
    const tempClient = localStorage.getItem('Invoices')
    if (tempClient) {
      const temp = JSON.parse(tempClient)
      setClient(temp)
      const divId = document.getElementById('pdfPrint') as HTMLElement
      const printDoc = new jsPDF({
        unit: 'px', // set the units of measurement to px
        format: 'A4', // set the 'paper' size
        userUnit: 72, // set the DPI here. Web uses 72 but you can change to 150 or 300
      })
      // const pdf = new jsPDF({
      //   orientation: 'portrait',
      //   unit: 'px',
      //   format: [canvas.width, canvas.height]
      // });
      // const printDoc = new jsPDF('portrait', 'pt', 'A4')

      printDoc.addFileToVFS(
        '../../assets/fonts/Roboto/Roboto-Medium.ttf',
        'Inter'
      )
      printDoc.addFileToVFS('Inter-Regular.ttf', base64Font)
      printDoc.addFileToVFS('Inter-Bold.ttf', base64FontBold)
      printDoc.addFileToVFS('Inter-SemiBold.ttf', base64FontSemiBold)

      printDoc.addFont('Inter-Regular.ttf', 'Inter', 'normal')
      printDoc.addFont('Inter-Bold.ttf', 'Inter', 'bold')
      printDoc.addFont('Inter-SemiBold.ttf', 'Inter', 'semi-bold')
      printDoc.setFont('Inter')

      printDoc.html(divId, {
        callback(pdf) {
          pdf.save(`Boleto_${invoice.refDate}.pdf`)
        },
      })
    }
  }, [invoice])

  const { inputRef } = useBarcode({
    value: invoice ? (invoice.barcode === null ? '0' : invoice.barcode) : '0',
    options: {
      displayValue: false,
      background: '#fff',
      format: 'ITF',
    },
  })

  return (
    <S.Container
      id="pdfPrint"
      style={{
        margin: 'auto',
        width: '450px',
        padding: '0 16px',
        minHeight: '600px',
      }}
    >
      <div
        style={{
          width: '100%',
          height: 10,
        }}
      />

      <S.Column
        style={{ alignItems: 'center', width: '100%', marginBottom: 12 }}
      >
        <S.Row>
          <S.Column>
            <img
              alt="Logo"
              height={48}
              width={48}
              src={logo}
              style={{ marginBottom: 10 }}
            />
          </S.Column>
        </S.Row>
      </S.Column>
      <S.HeaderComponentTitle>
        <p>Boleto Bancário</p>
        <p>
          {`R$ ${
            Number(invoice.total || invoice.totalPaymentDue).toLocaleString(
              'pt-BR',
              {
                minimumFractionDigits: 2,
              }
            ) || '-'
          }`}
        </p>
      </S.HeaderComponentTitle>
      <S.Line />
      <S.GridContainer gridRemplateColumns="1fr 2fr">
        <S.Column>
          <S.InfoTitle>Número do Processo</S.InfoTitle>
          <S.InfoLabel>
            {clientRef && clientRef.id ? maskCustomDocument(clientRef.id) : '-'}
          </S.InfoLabel>
        </S.Column>
      </S.GridContainer>
      <S.WeakLine />

      <S.GridContainer gridRemplateColumns="1fr 1fr 1fr">
        <S.Column>
          <S.InfoTitle>Valor do documento</S.InfoTitle>
          <S.InfoLabel>
            {`R$ ${
              Number(invoice.total || invoice.totalPaymentDue).toLocaleString(
                'pt-BR',
                {
                  minimumFractionDigits: 2,
                }
              ) || '-'
            }`}
          </S.InfoLabel>
        </S.Column>

        <S.Column>
          <S.InfoTitle>Data do vencimento</S.InfoTitle>
          <S.InfoLabel>{`${
            invoice.paymentDueDate
              ? dataFormat(invoice.paymentDueDate) || '-'
              : '-'
          }`}</S.InfoLabel>
        </S.Column>
      </S.GridContainer>

      {/* <S.GridContainer gridRemplateColumns="1fr 1fr 1fr">
        <S.Column>
          <S.InfoTitle>Banco Beneficiário:</S.InfoTitle>
          <S.InfoLabel>Pagar.Me Pagamentos</S.InfoLabel>
          <S.InfoTitle>CNPJ: 18.727.053/0001-74</S.InfoTitle>
        </S.Column>
        <S.Column>
          <S.InfoTitle>Recebedor</S.InfoTitle>
          <S.InfoLabel>{boletoTransaction.merchant.name}</S.InfoLabel>
          <S.InfoTitle>
            {maskDocument(boletoTransaction.merchant.doc)}
          </S.InfoTitle>
        </S.Column>
        <S.Column>
          <S.InfoTitle>Pagador</S.InfoTitle>
          <S.InfoLabel>{`${boletoData.name || '-'}`}</S.InfoLabel>
          <S.InfoTitle>{maskDocument(boletoData.doc)}</S.InfoTitle>
        </S.Column>
      </S.GridContainer> */}
      <S.WeakLine />

      <S.GridContainer gridRemplateColumns="1fr">
        <S.Column>
          <S.InfoTitle>Local de pagamento</S.InfoTitle>
          <S.InfoLabel>
            Até o vencimento, pague em qualquer banco ou correspondente bancário
          </S.InfoLabel>
        </S.Column>
      </S.GridContainer>

      {/* {boletoData.extras.instructions ? (
        <S.GridContainer gridRemplateColumns="1fr">
          <S.Column>
            <S.InfoTitle>Instruções</S.InfoTitle>
            <S.InfoLabel>{boletoData.extras.instructions}</S.InfoLabel>
          </S.Column>
        </S.GridContainer>
      ) : null} */}
      <S.Line />

      {invoice.barcodeLine ? (
        <S.GridContainer gridRemplateColumns="1fr">
          <S.Column>
            <S.InfoTitle>Linha digitável</S.InfoTitle>
            <S.InfoLabel>{invoice.barcodeLine}</S.InfoLabel>
          </S.Column>
        </S.GridContainer>
      ) : null}
      <S.WeakLine />

      <S.GridContainer gridRemplateColumns="1fr">
        <img
          style={{ width: 300, height: 50 }}
          ref={inputRef}
          alt="Código de Barras"
        />
      </S.GridContainer>
      <S.Line />

      <S.SlipFooter>
        <div className="slipfooter_row">
          <img height={12} width={28} src={logoGreen} alt="bill" />
          <p>Arrecadação Omnichannel</p>
        </div>
        <div className="slipfooter_row">
          <p className="slipfooter_label">Desenvolvido por </p>
          <img src={logoPigz} height={12} width={36} alt="pigz" />
        </div>
      </S.SlipFooter>
    </S.Container>
  )
}
