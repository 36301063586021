import React from 'react'
import { Switch } from 'react-router-dom'
import Dashboard from '../pages/Dashboard'
import Header from '../components/Header'
import { Container } from './styles'
import PrivateRoute from '../components/PrivateRoute'
import Payment from '../pages/Payment'
import Success from '../pages/Payment/components/Success'
import Error from '../pages/Payment/components/Error'
import GenericError from '../components/GenericError'
import ModalDocEmpty from '../components/ModalDocEmpty/inde'
import { PaymentPix } from '../pages/PaymentPix'
import { PaymentBoleto } from '../pages/PaymentBoleto'
import PickUC from '../pages/PickUC'

const AppRoutes: React.FC = () => {
  return (
    <Container>
      <Header />
      <Switch>
        <PrivateRoute exact key="home" path="/" component={Dashboard} />
        <PrivateRoute exact key="payment" path="/payment" component={Payment} />
        <PrivateRoute
          exact
          key="paymentPix"
          path="/payment/pix"
          component={PaymentPix}
        />
        <PrivateRoute
          exact
          key="paymentBoleto"
          path="/payment/boleto"
          component={PaymentBoleto}
        />

        <PrivateRoute
          exact
          key="success"
          path="/payment/success"
          component={Success}
        />
        <PrivateRoute
          exact
          key="error"
          path="/payment/error"
          component={Error}
        />
        <PrivateRoute
          exact
          key="genericError"
          path="/error"
          component={GenericError}
        />
      </Switch>
      <ModalDocEmpty />
    </Container>
  )
}

export default AppRoutes
