/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect } from 'react-redux'
// import { nanoid } from 'nanoid';
import { AxiosResponse } from 'axios'

import symbol from '../../assets/icons/bolt.svg'

import {
  Container,
  Label,
  Box,
  LabelConsumer,
  Skeleton,
  LabelDescription,
} from './styles'
import { LoaderIcon } from '../../components/Loader'
import { Invoice, Refs } from '../../utils/models'
import { newApi } from '../../services/api'
import { GlobalColumn } from '../../components/GlobalColumn'
import { OmniChannelComponent } from '../../components/OmniChannelComponent'

const PickUC: React.FC = ({ modules, dispatch }: any) => {
  const [user, setUser] = useState<Array<Refs>>([])
  const [loading, setLoading] = useState(true)
  const temp = localStorage.getItem('Refs')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 1000)
  }, [])

  useEffect(() => {
    localStorage.removeItem('invoices')
  }, [])

  useEffect(() => {
    if (!temp) {
      setUser([])
      setLoading(false)
    } else {
      const userTemp = JSON.parse(temp)
      setUser(userTemp)
      setLoading(false)
    }
  }, [temp])

  useEffect(() => {
    if (user) {
      if (user.length === 1) {
        handleUC(user[0])
      }
    }
  }, [user])

  const history = useHistory()

  /* function setClient(module: any, data: User): unknown {
    return {
        type: 'SET_UC',
        module,
        data,
    };
  } */
  function setName(module: any, name: string): unknown {
    return {
      type: 'SET_NAME',
      module,
      name,
    }
  }

  function setMatricula(module: any, matricula: string): unknown {
    return {
      type: 'SET_MATRICULA',
      module,
      matricula,
    }
  }

  function setDescription(module: any, endereco: string): unknown {
    return {
      type: 'SET_ENDERECO',
      module,
      endereco,
    }
  }
  /* function setNameClient(module: any, name: string): unknown {
    return {
        type: 'SET_NAME',
        module,
        name,
    };
  } */

  function SET_INVOICES(module: any, invoices: Invoice[]): unknown {
    return {
      type: 'SET_INVOICES',
      module,
      invoices,
    }
  }

  async function handleUC(item: Refs): Promise<void> {
    // const baseEncript = btoa(`${nanoid()}:s${nanoid()}`);

    const baseEncript = sessionStorage.getItem('token')
    try {
      history.push('/')
      localStorage.setItem('Ref', JSON.stringify(item))
      localStorage.setItem('refreshRef', item.id)

      history.push({
        pathname: '/',
        state: {

          refreshRef:item.id,
        },
      })

      // const response = await newApi.get<any, AxiosResponse<any>>(
      //   `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/invoices?ref=${item.id}`,
      //   {
      //     headers: {
      //       Authorization: `Basic ${baseEncript}`,
      //       // "X-Recaptcha-Token": googleRecaptcha,
      //       // "X-Forwarded-Host": window.location.hostname||process.env.REACT_APP_HOST
      //     },
      //   }
      // )
      // const { data } = response
      // const total = response.headers['x-total']
      // localStorage.setItem('x-total', total)

      // // console.log(data)
      // if (data) {
      //   const firstData = data[0]
      //   if (firstData) {
      //     const firstDataObj =
      //       firstData && firstData.customer && firstData.customer !== null
      //         ? firstData.customer
      //         : 'SEM NOME'
      //     dispatch(setName(modules, firstDataObj))
      //   }
      // }
      // localStorage.setItem('Invoices', JSON.stringify(data))
      // dispatch(SET_INVOICES(modules, data))
      // if (data[0]) localStorage.setItem('invoice', JSON.stringify(data[0]))
      // localStorage.setItem('refreshRef', item.id)

      // history.push('/')
    } catch (error) {
      console.log(error)
    }
    // dispatch(setClient(modules, item));
    // dispatch(setNameClient(modules, item.NOME_CLIENTE || '-'));
    /* localStorage.setItem(
      'Invoices',
      JSON.stringify(item),
    ); */
  }

  if (loading) {
    return <LoaderIcon />
  }

  function limitcharNumber(c: string, n: number): string {
    let name = ''

    const { length } = c

    if (length > n) name = `${c.slice(0, n)}...`
    else {
      name = c
    }

    return name
  }

  return (
    <Container>
      <img
        id="imgText"
        src={`${process.env.PUBLIC_URL}/assets/icons/cpfl-white-icon.svg`}
        alt="Imagem"
      />
      <Label>Selecione a UC</Label>
      <GlobalColumn>
        {isLoading
          ? user?.map(() => {
              return (
                <Box disabled>
                  <img src={symbol} alt="Caer" style={{ height: 28 }} />
                  <LabelConsumer>
                    <span>MATRÍCULA</span>
                    <Skeleton
                      style={{
                        opacity: 0.5,
                        width: 120,
                        height: 25,
                        marginTop: 3,
                      }}
                    />
                  </LabelConsumer>
                  <LabelConsumer style={{ marginLeft: 20, width: 100 }}>
                    <span>DESCRIÇÃO</span>
                    <Skeleton
                      style={{
                        opacity: 0.5,
                        width: 100,
                        height: 10,
                        marginTop: 3,
                      }}
                    />
                    <Skeleton
                      style={{
                        opacity: 0.5,
                        width: 70,
                        height: 10,
                        marginTop: 5,
                      }}
                    />
                  </LabelConsumer>
                  <FontAwesomeIcon icon={faArrowRight} size="2x" />
                </Box>
              )
            })
          : user?.map((item) => {
              return (
                <Box
                  onClick={() => {
                    handleUC(item)
                  }}
                >
                  <div className="icon_container">
                    <img src={symbol} alt="Caer" style={{ height: 32 }} />
                  </div>
                  <GlobalColumn gap="4px" alignItems="flex-start">
                    <LabelConsumer>{item.id}</LabelConsumer>
                    <div style={{ display: 'grid' }}>
                      <LabelDescription>{item.description}</LabelDescription>
                    </div>
                  </GlobalColumn>

                  <FontAwesomeIcon icon={faArrowRight} width={24} height={24} />
                </Box>
              )
            })}
      </GlobalColumn>
      <div style={{ position: 'absolute', bottom: '24px' }}>
        <OmniChannelComponent isLogoWhite />
      </div>
    </Container>
  )
}

export default connect((state: any) => ({ modules: state }))(PickUC)
