import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  max-height: 100%;
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  font-family: Inter;
  @media only screen and (max-width:768px){
    flex-direction: column;
  }

  &::-webkit-scrollbar {
    display: none;
  }
  .invoice_footer_bill {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #212121;
    gap: 8px;
    width: 100%;
    img {
      height: 16px;
    }
  }
  .page_container_mt-16{
    margin-top: 16px;
  }
`

export const Box = styled.div`
  width: 395px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  @media only screen and (max-width: 768px) {
    width: 100%;
    align-self: center;
    margin-top: 32px;
    padding: 15px;
  }
`

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #212121;
  @media only screen and (max-width: 768px) {
    width: 100%;
    text-align: center;
    width: 100%;
    font-size: 20px;
    margin-bottom: 20px;
  }
`

export const Top = styled.div`
  width: 395px;
  padding: 30px;
  margin-bottom: 15px;
  color: #fff;
  background-color: var(--primaryColor);
  border-radius: 8px;
  > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`

export const TableInvoices = styled.div`
  border: 3px solid #fff;
  display: flex;
  flex-direction: column !important;
  justify-content: flex-start;
  width: 100%;
  border-radius: 16px;
  margin-top: 5px;
  div:first-child {
    border-top: none;
  }
  > div {
    border-top: 3px solid #fff;
    padding: 10px;
    width: 100%;
    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      span {
        font-size: 25px;
        font-weight: 500;
        font-family: 'Inter';
      }
    }
  }
  @media only screen and (max-width: 768px) {
    border-radius: 10px;
    span {
      font-size: 20px !important;
      font-weight: 500;
      font-family: 'Inter';
    }
  }
`

export const Bottom = styled.div`
  width: 395px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  padding: 24px;
  margin-bottom: 15px;
  background-color: #fff;
  border-radius: 16px;
  margin-top: 16px;
  span {
    color: #494949;
    font-size: 13px;
    font-family: 'Inter';
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const Button = styled.button`
  border: none;
  min-height: 40px;
  font-size: 16px;
  font-family: 'Inter';
  font-weight: 500;
  border-radius: 8px;
  &.homeButton {
    color: #212121;
    background-color: #ffffff;
    border: 0.5px solid  #212121;
    margin-bottom: 20px;
    width: 192px;
    align-self: center;
  }
  &.proofButton {
    border: 0.5px solid #00AB50;
    color: #ffffff;
    background-color: #00AB50;
    width: 100%;
    margin: 24px 0;
  }
`

export const WhiteContainer = styled.div`
  padding: 24px;
  border-radius: 16px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #212121;
  .container_title {
    border-bottom: 1px solid #dddddd;
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 32px;
    text-align: left;
    color: #212121;
    padding-bottom: 8px;
    width: 100%;
  }

  .container_row {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .row_label {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`
export const FooterContainer = styled.div`
  padding: 24px;
  border-radius: 16px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #212121;
  gap: 32px;
`
