/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const MetaTagsAsync: React.FC = () => {
  return (
    <HelmetProvider>
      <Helmet>
        <meta name="google" content="notranslate" />
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1, maximum-scale=1"
        />
        <meta
          name="Description"
          content="Pagar Custas Processuais ficou muito mais fácil e prático."
        />

        <meta
          property="og:title"
          content="CPFL Energia - Pagamentos"
        />
        <meta
          property="og:description"
          content="Portal de pagamentos"
        />
        <meta
          property="og:image"
          content={`${process.env.REACT_APP_URL_HOST}/assets/icons/favicon-color.ico`}
        />

        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <meta name="msapplication-TileColor" content="#fff" />
        <meta name="theme-color" content="#fff" />

        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="apple-mobile-web-app-status-bar-style" content="default" />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3)"
          href={`${process.env.REACT_APP_URL_HOST}/assets/images/cpfl-colored-icon.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2)"
          href={`${process.env.REACT_APP_URL_HOST}/assets/images/cpfl-colored-icon.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 414px) and (device-height: 736px) and (-webkit-device-pixel-ratio: 3)"
          href={`${process.env.REACT_APP_URL_HOST}/assets/images/cpfl-colored-icon.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 320px) and (device-height: 568px) and (-webkit-device-pixel-ratio: 2)"
          href={`${process.env.REACT_APP_URL_HOST}/assets/images/cpfl-colored-icon.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 768px) and (device-height: 1024px) and (-webkit-device-pixel-ratio: 2)"
          href={`${process.env.REACT_APP_URL_HOST}/assets/images/cpfl-colored-icon.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 834px) and (device-height: 1112px) and (-webkit-device-pixel-ratio: 2)"
          href={`${process.env.REACT_APP_URL_HOST}/assets/images/cpfl-colored-icon.png`}
        />
        <link
          rel="apple-touch-startup-image"
          media="(device-width: 1024px) and (device-height: 1366px) and (-webkit-device-pixel-ratio: 2)"
          href={`${process.env.REACT_APP_URL_HOST}/assets/images/cpfl-colored-icon.png`}
        />

        <link rel="icon" href="favicon-color.ico" />
        <link rel="mask-icon" href={`${process.env.REACT_APP_URL_HOST}/assets/icons/favicon-color.ico`} />
        <link rel="shortcut-icon" href={`${process.env.REACT_APP_URL_HOST}/assets/icons/favicon-color.ico`} />
        <link
          rel="apple-touch-icon"
          type="image/png"
          href={`${process.env.REACT_APP_URL_HOST}/assets/icons/favicon-color.ico`}
        />

        <title>CPFL Energia - Pagamentos</title>
      </Helmet>
    </HelmetProvider>
  )
}

export default MetaTagsAsync
