/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, useState } from 'react'
import ReactSelect, { OptionTypeBase, Props as SelectProps } from 'react-select'
import { useField } from '@unform/core'
import { Container, Label, BoxInput, OptionSelectItem } from './styles'

interface Props extends SelectProps<OptionTypeBase> {
  name: string
  label?: string
}

const Select: React.FC<Props> = ({ name, label, ...rest }: any) => {
  const selectRef = useRef(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)
  const [errorField, setErrorField] = useState<boolean>(false)

  useEffect(() => {
    if (error) {
      setErrorField(true)
    } else {
      setErrorField(false)
    }
  }, [error])

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return []
          }
          return ref.state.value.map((option: OptionTypeBase) => option.value)
        }
        if (!ref.state.value) {
          return ''
        }
        return ref.state.value.value
      },
    })
  }, [fieldName, registerField, rest.isMulti])

  const formatOptionLabel = (props: any) => {
    if (props.customComponent) {
      return props.customComponent
    }

    return (
      <div style={{ display: 'flex' }}>
        <div>{props.label}</div>
        <div style={{ marginLeft: '10px', color: '#ccc' }}>
          {props.customAbbreviation}
        </div>
      </div>
    )
  }
  const MyOption = (props: any) => {
    if (props.data.customComponent) {
      return (
        <OptionSelectItem
          ref={props.innerRef}
          {...props.innerProps}
          className="custom-option"
        >
          {props.data.customComponent}
        </OptionSelectItem>
      )
    }
    return (
      <article
        ref={props.innerRef}
        {...props.innerProps}
        className="custom-option"
      >
        <h4>{props.data.artist}</h4>
        <div className="sub">{props.label} </div>
      </article>
    )
  }

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <BoxInput style={{ borderColor: errorField ? '#eb4646' : '#707070' }}>
        <ReactSelect
          defaultValue={defaultValue}
          menuPosition="fixed"
          ref={selectRef}
          placeholder="Selecione a quantidade de parcelas"
          className="react-select-container"
          formatOptionLabel={formatOptionLabel}
          components={{ Option: MyOption }}
          theme={(theme) => ({
            ...theme,
            borderRadius: 8,
            colors: {
              ...theme.colors,
              primary25: '#ecffbb',
              primary: 'var(--primaryColor)',
            },
          })}
          classNamePrefix="react-select"
          {...rest}
        />
      </BoxInput>
    </Container>
  )
}

export default Select
