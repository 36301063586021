import styled from 'styled-components'

export const PageContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Inter;

  .page_container_mt-16 {
    margin-top: 16px;
  }
  .invoice_footer_bill {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #212121;
    gap: 8px;
    img {
      height: 16px;
    }
  }
  @media only screen and (max-width:768px){
    padding: 0 24px;
    padding-top: 48px;
  }
`
export const PageTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  text-align: center;
  color: #212121;
`

export const PixContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff !important;
  gap: 32px;
  padding: 24px;
  border-radius: 16px;
  max-width: 512px;

  @media only screen and (max-width:768px){
    padding: 16px;
    gap: 16px;

  }

  .pix_image_container {
    display: flex;
    align-items: center;
    justify-items: center;
    border-radius: 8px;
    border: 0.5px solid #212121;
    padding: 16px;
  }
  color: #212121;

  .pix_text_container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

  }
  .pix_text_title {
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
      line-height: 24.2px;
      text-align: left;
    }
    .pix_text_label {
      font-family: Inter;
      font-size: 14px;
      font-weight: 400;
      line-height: 21px;
      text-align: left;
    }

  .pix_container_copy_button {
    border: none;
    background: #00AB50;
    color: #fff;
    border-radius: 8px;
  }

  .pix_container_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 8px;

    font: normal normal normal 12px/15px Roboto;
    color: #212121;
    text-align: center;

    .pix_container_13px {
      font: normal normal normal 13px/18px Roboto;
    }
  }

  .pix_container_box {
    display: grid;
    grid-template-columns: 1fr 64px;
    height: 40px;
    gap: 16px;

    .pix_container_box_label_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 16px;
      padding-right: 16px;
      border: 1px solid #bdbdbd;
    border-radius: 16px;

    }
    .pix_container_box_label {
      font: normal normal normal 13px/18px Roboto;
      color: #212121;
      text-overflow: ellipsis;
      display: block;
      overflow: hidden;
      white-space: nowrap;
      max-width: 100%;
    }
  }

  .qrCodeContainer {
    width: 168px;
  }
`

export const ButtonPay = styled.button`
  border: none;
  height: 40px;
  background: #00ab50;
  color: #fff;
  font-family: 'Inter';
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  padding: 0 16px;
  text-align: left;
`

export const BackButton = styled.button`
  border: none;
  height: 40px;
  background: #212121;
  background: #fff;
  font-family: 'Inter';
  border-radius: 8px;
  font-weight: 500;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  padding: 0 16px;
  border: 0.5px solid #212121
`

export const PixRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 512px;
  @media only screen and (max-width:768px){
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }

`
