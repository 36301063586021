/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import * as S from './styles'

export const NotFoundInformationModal = ({
  closeModal,
}: {
  closeModal: () => void
}) => {
  return (
    <S.ModalContainer
      onClick={() => {
        closeModal()
      }}
    >
      <S.ModalDetail
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <div className="modal_detail_head">
          <p className="modal_detail_head_label">Guia não encontrada!</p>
        </div>
        <p className="modal_detail_label">
          Não foi possível localizar o número da guia informado. Por favor,
          tente novamente.
        </p>
        <S.ModalButton
          onClick={() => {
            closeModal()
          }}
        >
          Ok, Entendi
        </S.ModalButton>
      </S.ModalDetail>
    </S.ModalContainer>
  )
}
