import styled, { css } from "styled-components"

interface PaymentTypeContainerProps {
  isActive?: boolean
}
export const PaymentTypeContainer = styled.div<PaymentTypeContainerProps>`
  ${({ isActive }) => css`
    padding: 16px;
    border: 1px solid #dddddd;
    background: ${isActive ? '#F2F2F4' : '#FFFFFF'};
    border-radius: 16px;
    margin: 0 16px;
    margin-top: 16px;
    cursor: pointer;

    .payment_type_icon {
      width: 20px;
      height: 20px;
      color: #212121;
    }

    .payment_type_header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      color: #212121;
      font-size: 13px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;

      .payment_type_header_left {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        cursor: pointer;
      }
    }

    .payment_type_body {
      display: ${isActive ? 'flex' : 'none'};
    }
    .react-select__single-value{
      width: 100%;
      padding-right: 8px;
    }
    .react-select__value-container{
      height: 38px;
    }
  `}
`


interface RadioButtonProps {
  isActive?: boolean
}
export const RadioButton = styled.button<RadioButtonProps>`
  ${({ isActive }) => css`
    width: 16px;
    height: 16px;
    background: #ffffff;
    border-radius: 8px;
    border: ${isActive ? '4px solid #00AB50' : '1px solid #bdbdbd'};
  `}
`


export const PaymentTypeGrid = styled.div`

display: grid;
gap: 16px;

@media only screen and (max-width:768px){
  display: flex;
  flex-direction: column;
  gap: 0 !important;
}

`
