/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from 'react'
import * as S from './styles'
import { TFInvoice } from '../../../../utils/models'
import { StringToCurrency } from '../../../../utils/stringToCurrency'

export const InvoiceDetailModal = ({
  invoiceItem,
  generatingColorStatus,
  generatingTextStatus,
  invoiceDescription,
  closeModal,
}: {
  invoiceItem: TFInvoice
  invoiceDescription(invoice: TFInvoice): string
  generatingColorStatus(inv: TFInvoice): string
  generatingTextStatus(inv: TFInvoice): string
  closeModal: () => void
}) => {
  return (
    <S.ModalContainer
      onClick={() => {
        closeModal()
      }}
    >
      <S.ModalDetail
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <div className="modal_detail_column">
          <p className="modal_detail_head">Guia</p>
          <p className="modal_detail_head_label">{invoiceItem.id}</p>
        </div>
        <div className="modal_detail_column">
          <p className="modal_detail_head">Descrição</p>
          <p className="modal_detail_head_label">
            {invoiceDescription(invoiceItem)}
          </p>
        </div>
        <div className="modal_detail_column modal_detail_max-width">
          <div className="modal_detail_space_between modal_detail_max-width">
            <p className="modal_detail_head">Total</p>
            <p className="modal_detail_head">Status</p>
          </div>
          <div className="modal_detail_space_between modal_detail_max-width">
            <p className="modal_detail_head_label">
              {`${StringToCurrency(`${invoiceItem.totalPaymentDue || 0}`)}`}
            </p>
            <div style={{ display: 'flex', gap: '4px', alignItems: 'center' }}>
              <S.InvoiceItemStatus color={generatingColorStatus(invoiceItem)} />
              <p className="modal_detail_head">
                {generatingTextStatus(invoiceItem) || '-'}
              </p>
            </div>
          </div>
        </div>
        <div className="modal_line" />

        {invoiceItem.extras?.custa?.map((custa, index) => (
          <div
            className="modal_detail_max-width modal_detail_column"
            key={index}
          >
            {custa.itemGuia.length ? (
              custa.itemGuia.map((itemGuia, indexItem) => {
                return (
                  <div
                    key={indexItem}
                    className="modal_detail_space_between modal_detail_max-width"
                  >
                    <p className="modal_detail_head">{itemGuia.descricao}</p>
                    <p className="modal_detail_head">
                      {`${StringToCurrency(`${itemGuia.valor || 0}`)}`}
                    </p>
                  </div>
                )
              })
            ) : (
              <div className="modal_detail_space_between modal_detail_max-width">
                <p className="modal_detail_head">{custa.descricao}</p>
                <p className="modal_detail_head">
                  {custa.valorCusta
                    ? `${StringToCurrency(`${custa.valorCusta || 0}`)}`
                    : null}
                </p>
              </div>
            )}
          </div>
        ))}
        <button
          onClick={() => {
            closeModal()
          }}
          type="button"
          className="modal_close_button"
        >
          Fechar
        </button>
      </S.ModalDetail>
    </S.ModalContainer>
  )
}
