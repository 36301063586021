/* eslint-disable spaced-comment */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'
import * as Yup from 'yup'
import { ReCaptcha } from 'react-recaptcha-v3'
import { nanoid } from 'nanoid'
import { AxiosResponse } from 'axios'
import whiteLogo from '../../assets/icons/logo-amazonas-energia-branco.svg'
import whiteLogoHorizontal from '../../assets/icons/logo-amazonas-energia-horizontal.svg'
import loadingIcon from '../../assets/icons/loading.svg'
import loginImage from '../../assets/icons/login-image.svg'
import imageSmartPhone from '../../assets/images/smartphone-ame-mockup.png'
import { Invoice, Refs, TFInvoice, TFRefs } from '../../utils/models'
import LogoBillGray from '../../assets/images/logo-bill-gray.svg'

import * as S from './style'
import { newApi } from '../../services/api'
import Input from './components/Input'
import Recaptcha from './components/Recaptcha'
import useKeyboardStatus from './components/KeyboardVisible'
import { OmniChannelComponent } from '../../components/OmniChannelComponent'

interface Errors {
  [key: string]: string
}

const TWO_MINUTES = 2 * 60000

const UpdatedLogin: React.FC = ({ modules, dispatch }: any) => {
  const [loading, setLoading] = useState(false)
  const [loadingCaptcha, setLoadingCaptcha] = useState(true)
  const history = useHistory()
  const formRef = useRef<FormHandles>(null)
  const [googleRecaptcha, setGoogleRecaptcha] = useState<string | undefined>(
    undefined
  )
  const [waitScreen, setWaitScreen] = useState(false)
  const [captcha, setCaptcha] = useState<ReCaptcha>()
  const [docMode, setDocMode] = useState<boolean>(true)
  const isKeyboardVisible = false
  // useKeyboardStatus()

  /* function setClient(module: any, data: User): unknown {
    return {
      type: 'SET_UC',
      module,
      data,
    }
  } */

  function setName(module: any, name: string): unknown {
    return {
      type: 'SET_NAME',
      module,
      name,
    }
  }
  function SET_INVOICES(module: any, invoices: TFInvoice[]): unknown {
    return {
      type: 'SET_INVOICES',
      module,
      invoices,
    }
  }
  function SET_REFS(module: any, refs: TFRefs[]): unknown {
    return {
      type: 'SET_REFS',
      module,
      refs,
    }
  }

  // renew captcha
  useEffect(() => {
    if (googleRecaptcha)
      setTimeout(() => {
        if (googleRecaptcha) {
          // console.log('recaptcha')
          setGoogleRecaptcha(undefined)
          captcha?.execute()
        }
      }, TWO_MINUTES)
  }, [googleRecaptcha])

  function clearMask(s: string): string {
    const value = s.replace(/\D/g, '')

    return value
  }

  async function handleSubmit(data: any): Promise<void> {
    if (formRef.current) {
      try {
        // console.log(data)
        formRef.current.setErrors({})
        const schema = Yup.object().shape({
          document: Yup.string()
            .min(3, 'No mínimo 3 caracteres')
            .required('Formato inválido'),
        })
        await schema.validate(data, {
          abortEarly: false,
        })
        if (googleRecaptcha) {
          handleConsult(clearMask(data.document))
        } else {
          captcha?.execute()
          handleConsult(clearMask(data.document))
        }
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  async function handleSubmitDoc(data: any): Promise<void> {
    if (formRef.current) {
      try {
        // console.log(data)
        formRef.current.setErrors({})
        const schema = Yup.object().shape({
          document: Yup.string()

            .test('length', 'Número de CNPJ inválido', (docValue) =>
              docValue
                ? !(
                    clearMask(docValue).length > 11 &&
                    clearMask(docValue).length !== 14
                  )
                : false
            )
            .test('length', 'Número de CPF inválido', (docValue) =>
              docValue ? clearMask(docValue).length >= 11 : false
            )
            .min(3, 'No mínimo 3 caracteres')
            .required('Formato inválido'),
        })
        await schema.validate(data, {
          abortEarly: false,
        })
        if (googleRecaptcha) {
          handleConsultDoc(clearMask(data.document))
        } else {
          captcha?.execute()
          handleConsultDoc(clearMask(data.document))
        }
      } catch (err) {
        const validationErrors: Errors = {}
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path] = error.message
          })
          formRef.current.setErrors(validationErrors)
        }
      }
    }
  }

  /* function setNameClient(module: any, name: string): unknown {
    return {
      type: 'SET_NAME',
      module,
      name,
    }
  } */

  async function handleUC(item: Refs): Promise<void> {
    // const baseEncript = btoa(`${nanoid()}:s${nanoid()}`);

    const baseEncript = sessionStorage.getItem('token')
    try {
      const response = await newApi.get<any, AxiosResponse<any>>(
        `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/invoices?ref=${item.id}`,
        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
            // "X-Recaptcha-Token": googleRecaptcha,
            // "X-Forwarded-Host": window.location.hostname||process.env.REACT_APP_HOST
          },
        }
      )
      const { data } = response
      const total = response.headers['x-total']
      localStorage.setItem('x-total', total)

      // console.log(data)
      if (data) {
        const firstData = data[0]
        if (firstData) {
          const firstDataObj =
            firstData && firstData.customer && firstData.customer !== null
              ? firstData.customer
              : 'SEM NOME'
          dispatch(setName(modules, firstDataObj))
        }
      }
      localStorage.setItem('Invoices', JSON.stringify(data))
      dispatch(SET_INVOICES(modules, data))
      if (data[0]) localStorage.setItem('invoice', JSON.stringify(data[0]))
      localStorage.setItem('refreshRef', item.id)

      history.push('/')
    } catch (error) {
      console.log(error)
    }
    // dispatch(setClient(modules, item));
    // dispatch(setNameClient(modules, item.NOME_CLIENTE || '-'));
    /* localStorage.setItem(
      'Invoices',
      JSON.stringify(item),
    ); */
  }

  async function getRefHandleConsultDoc(
    docField: string,
    baseEncript: string
  ): Promise<void> {
    try {
      const response = await newApi.get<any, AxiosResponse<any>>(
        `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/ref?doc=${docField}`,
        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
            'X-Recaptcha-Token': googleRecaptcha,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )

      if (response.data) {
        localStorage.setItem('Ref', JSON.stringify(response.data))
        SET_REFS(modules, response.data)

        localStorage.setItem('first_time', 'true')
      }
    } catch (error) {
      console.log(error)
    }
  }

  async function handleConsultDoc(docField: string): Promise<void> {
    setLoading(true)
    const baseEncript = btoa(`${nanoid()}:s${nanoid()}`)
    // insert here baseEncript override
    sessionStorage.setItem('token', baseEncript)
    sessionStorage.setItem(
      'token_created',
      btoa(`${new Date().toISOString()}:s${nanoid()}`)
    )

    try {
      const response = await newApi.get<any, AxiosResponse<any>>(
        `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/refs?doc=${docField}`,

        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
            'X-Recaptcha-Token': googleRecaptcha,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )

      const { data } = response
      if (data && data.length > 0) {
        localStorage.setItem('Refs', JSON.stringify(data))
        SET_REFS(modules, data)
        setLoadingCaptcha(true)
        setLoading(false)
        localStorage.setItem('first_time', 'true')
        history.push('/uc')
        /*
        if(data.length > 1){

          /* localStorage.setItem(
            'infoClient',
            JSON.stringify(data),
          );
         /* dispatch(setClient(modules, data[0]));
          dispatch(setNameClient(modules, data[0].NOME_CLIENTE || "SEM NOME"));
          setLoadingCaptcha(true);
          setLoading(false);
          localStorage.setItem('first_time', 'true');
          history.push("/uc");
        } else {
          dispatch(setClient(modules, data[0]));
          dispatch(setNameClient(modules, data?.NOME_CLIENTE));
          localStorage.setItem(
            'infoClient',
            JSON.stringify(data),
          );
          localStorage.setItem(
            'info',
            JSON.stringify(data[0]),
          );
          setLoading(false);
          localStorage.setItem('first_time', 'true');
          history.push("/");
        }
        */
      } else {
        if (formRef.current)
          formRef.current.setErrors({ document: 'Tente novamente' })
        captcha?.execute()
        localStorage.clear()
        sessionStorage.clear()
        dispatch({
          type: 'RESET',
        })
        setLoadingCaptcha(true)
        setLoading(false)
      }

      // else {
      //   if (formRef.current)
      //   captcha?.execute()
      //   localStorage.clear()
      //   sessionStorage.clear()
      //   dispatch({
      //     type: 'RESET',
      //   })
      //   setLoadingCaptcha(true)
      //   setLoading(false)
      // }

      // if (data && data.length > 0) {
      //   localStorage.setItem('Refs', JSON.stringify(data))
      //   SET_REFS(modules, data)
      //   setLoadingCaptcha(true)
      //   setLoading(false)
      //   localStorage.setItem('first_time', 'true')
      //   history.push('/uc')
      // }
    } catch (error) {
      if (formRef.current)
        formRef.current.setErrors({
          document:
            docField?.length > 11
              ? 'Número de CNPJ inválido'
              : 'Número de CPF inválido',
        })
      captcha?.execute()
      setLoadingCaptcha(true)
      setLoading(false)
      localStorage.clear()
      sessionStorage.clear()
      dispatch({
        type: 'RESET',
      })
      history.push('/login')
    }
  }

  async function handleConsult(docField: string): Promise<void> {
    setLoading(true)
    const baseEncript = btoa(`${nanoid()}:s${nanoid()}`)
    // insert here baseEncript override
    sessionStorage.setItem('token', baseEncript)
    sessionStorage.setItem(
      'token_created',
      btoa(`${new Date().toISOString()}:s${nanoid()}`)
    )
    // if (docField.length < 11) {
    try {
      const { data } = await newApi.get<any, AxiosResponse<any>>(
        // `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/ref?ref=${docField}`,
        `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/ref?ref=${docField}`,
        {
          headers: {
            Authorization: `Basic ${baseEncript}`,
            'X-Recaptcha-Token': googleRecaptcha,
            'X-Forwarded-Host':
              window.location.hostname || process.env.REACT_APP_HOST,
          },
        }
      )

      if (data) {
        localStorage.setItem('Ref', JSON.stringify(data))
        SET_REFS(modules, data)

        localStorage.setItem('first_time', 'true')
        if (data.id) {
          await handleUC(data)
          setLoading(false)
        } else {
          if (formRef.current)
            formRef.current.setErrors({ document: 'Tente novamente' })
          captcha?.execute()
          setLoadingCaptcha(true)
          setLoading(false)
          localStorage.clear()
          sessionStorage.clear()
          dispatch({
            type: 'RESET',
          })
          history.push('/login')
        }

        /* const x = data[0].customer !== null ? data[0].customer : 'SEM NOME'
          // console.log(x)
          dispatch(setName(modules, x))
          localStorage.setItem('Invoices', JSON.stringify(data))
          dispatch(SET_INVOICES(modules, data))

          localStorage.setItem('invoice', JSON.stringify(data[0])) */
      }
      //  console.log(response,'DataNew')
      /*  const { data } = await api.get<any, AxiosResponse<User>>(`/ref/${docField}/current`,{
          headers: {
            Authorization: `Basic ${baseEncript}`,
            "X-Recaptcha-Token": googleRecaptcha,
            "X-Forwarded-Host": window.location.hostname||process.env.REACT_APP_HOST
          }
        });
      */
      /*  dispatch(setClient(modules, data));
        dispatch(setNameClient(modules, data.NOME_CLIENTE || "SEM NOME")); */
      /* console.log(data[0].customer)
       const x = data[0].customer !== null ? data[0].customer : "SEM NOME"
       console.log(x)
       dispatch(setName(modules, x)); */

      /*  //
       localStorage.setItem(
        'infoClient',
        JSON.stringify(data[0]),
      );
      localStorage.setItem(
        'info',
        JSON.stringify(data[0]),
      ); */

      /* localStorage.setItem(
        'infoClient',
        JSON.stringify(data),
      );
      localStorage.setItem(
        'info',
        JSON.stringify(data),
      ); */
      if (!data) {
        if (formRef.current)
          formRef.current.setErrors({ document: 'Número de processo inválido' })
        captcha?.execute()
        setLoadingCaptcha(true)
        setLoading(false)
        localStorage.clear()
        sessionStorage.clear()
        dispatch({
          type: 'RESET',
        })
        history.push('/login')
      } else {
        setLoading(false)
        localStorage.setItem('first_time', 'true')
        // history.push('/')
      }
    } catch (error) {
      if (formRef.current)
        formRef.current.setErrors({ document: 'Número de processo inválido' })
      captcha?.execute()
      setLoadingCaptcha(true)
      setLoading(false)
      localStorage.clear()
      sessionStorage.clear()
      dispatch({
        type: 'RESET',
      })
      history.push('/login')
    }
    // }
    //  else {
    //   try {
    //     const { data } = await newApi.get<any, AxiosResponse<any>>(
    //       `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/invoices?ref=${docField}`,

    //       // `/mobile/bill/merchant/${process.env.REACT_APP_MERCHANT_FIXED}/refs?doc=${docField}`,
    //       {
    //         headers: {
    //           Authorization: `Basic ${baseEncript}`,
    //           'X-Recaptcha-Token': googleRecaptcha,
    //           'X-Forwarded-Host':
    //             window.location.hostname || process.env.REACT_APP_HOST,
    //         },
    //       }
    //     )
    //     // console.log(data)

    //     // console.log(response,'DataNew')

    //     /* const { data } = await api.get<any, AxiosResponse<any>>(`/${docField}/refs`,{
    //       headers: {
    //         Authorization: `Basic ${baseEncript}`,
    //         "X-Recaptcha-Token": googleRecaptcha,
    //         "X-Forwarded-Host": window.location.hostname||process.env.REACT_APP_HOST
    //       }
    //     }); */
    //     //  console.log(data)
    //     if (data && data.length > 0) {
    //       localStorage.setItem('Refs', JSON.stringify(data))
    //       SET_REFS(modules, data)
    //       setLoadingCaptcha(true)
    //       setLoading(false)
    //       localStorage.setItem('first_time', 'true')
    //       history.push('/uc')
    //       /*
    //       if(data.length > 1){

    //         /* localStorage.setItem(
    //           'infoClient',
    //           JSON.stringify(data),
    //         );
    //        /* dispatch(setClient(modules, data[0]));
    //         dispatch(setNameClient(modules, data[0].NOME_CLIENTE || "SEM NOME"));
    //         setLoadingCaptcha(true);
    //         setLoading(false);
    //         localStorage.setItem('first_time', 'true');
    //         history.push("/uc");
    //       } else {
    //         dispatch(setClient(modules, data[0]));
    //         dispatch(setNameClient(modules, data?.NOME_CLIENTE));
    //         localStorage.setItem(
    //           'infoClient',
    //           JSON.stringify(data),
    //         );
    //         localStorage.setItem(
    //           'info',
    //           JSON.stringify(data[0]),
    //         );
    //         setLoading(false);
    //         localStorage.setItem('first_time', 'true');
    //         history.push("/");
    //       }
    //       */
    //     } else {
    //       if (formRef.current)
    //         formRef.current.setErrors({ document: 'Tente novamente' })
    //       captcha?.execute()
    //       localStorage.clear()
    //       sessionStorage.clear()
    //       dispatch({
    //         type: 'RESET',
    //       })
    //       setLoadingCaptcha(true)
    //       setLoading(false)
    //     }
    //   } catch (error) {
    //     if (formRef.current)
    //     captcha?.execute()
    //     setLoadingCaptcha(true)
    //     setLoading(false)
    //     localStorage.clear()
    //     sessionStorage.clear()
    //     dispatch({
    //       type: 'RESET',
    //     })
    //     history.push('/login')
    //   }
    // }
  }

  useEffect(() => {
    localStorage.clear()
    sessionStorage.clear()
    dispatch({
      type: 'RESET',
    })
  }, [])

  const verifyCallback = (recaptchaToken: string): void => {
    setLoadingCaptcha(false)
    setGoogleRecaptcha(recaptchaToken)
  }

  useEffect(() => {
    if (!googleRecaptcha) {
      setLoadingCaptcha(true)
    } else {
      setLoadingCaptcha(false)
    }
  }, [googleRecaptcha])

  return (
    <S.Container>
      <ReCaptcha
        ref={(e) => {
          if (e) setCaptcha(e)
        }}
        sitekey={process.env.REACT_APP_RECAPTCHA as string}
        action="auth_device"
        verifyCallback={verifyCallback}
      />
      <span className="thisRecap">
        <Recaptcha googleRecapt={!loadingCaptcha} />
      </span>
      <S.Box>
        <S.BoxLeft>
          <S.BoxTwoImages>
            <img
              id="imgText"
              src={`${process.env.PUBLIC_URL}/assets/icons/cpfl-white-icon.svg`}
              alt="Imagem"
            />
          </S.BoxTwoImages>
          {!isKeyboardVisible ? (
            <S.BoxTextAndCard>
              <p className=" box_left_title  mb-32">Portal de pagamentos.</p>
              <p className="box_left_subtitle">
                Pagar suas faturas ficou muito mais fácil!{' '}
              </p>
              <p className="box_left_subtitle">
                Selecione a melhor forma de pagamento e parcele em até 12X!*{' '}
              </p>
            </S.BoxTextAndCard>
          ) : null}
          <a
            href="https://orangelabs.com.br/"
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
            className="box_left_footer"
          >
            <span>Desenvolvido por</span>
            <img
              style={{ marginLeft: 6 }}
              alt="orange"
              src={`${process.env.PUBLIC_URL}/assets/icons/orange-white.svg`}
            />
          </a>
        </S.BoxLeft>

        <S.BoxRight>
          {waitScreen ? (
            <div className="login_form_container login_gap">
              <img
                className="login_wait_image"
                alt="please wait"
                src={`${process.env.PUBLIC_URL}/assets/images/please-wait.svg`}
              />
              <S.WaitTitle>Aguarde.</S.WaitTitle>
              <S.WaitInfo>
                Em breve o Portal de Pagamentos estará disponível para facilitar
                os pagamentos de custas judiciais.
              </S.WaitInfo>
              <S.FormFooterWait>
                <p>
                  {`Ou `}
                  <a
                    className="footer_link-a"
                    href="https://projudi.tjrr.jus.br/projudi/"
                  >
                    clique aqui
                  </a>
                  {` para Acessar PROJUDI`}
                </p>
              </S.FormFooterWait>
            </div>
          ) : (
            <div className="login_form_container">
              {docMode ? (
                <Form
                  ref={formRef}
                  className="pb-72"
                  onSubmit={handleSubmitDoc}
                >
                  <p className="login_form_title pb-40">
                    Para iniciar, informe o número do documento
                  </p>
                  <p className="login_form_title-mobile pb-24">
                    Para iniciar,
                    <br />
                    informe o número do documento
                  </p>
                  {/*<S.ModeSelector className="mb-24">
                    <S.ModeSelectorItem
                      onClick={(e) => {
                        setDocMode(false)
                      }}
                    >
                      <p>Processo</p>
                    </S.ModeSelectorItem>
                    <S.ModeSelectorItem
                      isActive
                      onClick={(e) => {
                        setDocMode(true)
                      }}
                    >
                      <p>Documento</p>
                    </S.ModeSelectorItem>
                  </S.ModeSelector> */}
                  <p className="login_input_label">
                    Número do documento (CPF/CNPJ)
                  </p>

                  <div>
                    <Input
                      className="login_input_container"
                      name="document"
                      type="tel"
                      placeholder="000.000.000-00"
                    />

                    <S.FormButton
                      type="submit"
                      onClick={handleSubmitDoc}
                      disabled={loadingCaptcha}
                      style={{
                        filter: !loadingCaptcha
                          ? 'grayscale(0)'
                          : 'grayscale(1)',
                      }}
                      isFocused
                      className="login_consult_button"
                    >
                      {loading ? (
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/icons/loading.svg`}
                          alt="Loading"
                          style={{ height: 40 }}
                        />
                      ) : (
                        'Consultar'
                      )}
                    </S.FormButton>
                  </div>
                </Form>
              ) : (
                <Form ref={formRef} className="pb-72" onSubmit={handleSubmit}>
                  <p className="login_form_title pb-40">
                    Para iniciar, informe o número do processo
                  </p>
                  <p className="login_form_title-mobile pb-24">
                    Para iniciar,
                    <br />
                    informe o número do processo
                  </p>
                  <S.ModeSelector className="mb-24">
                    <S.ModeSelectorItem
                      isActive
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setDocMode(false)
                      }}
                    >
                      <p>Processo</p>
                    </S.ModeSelectorItem>
                    <S.ModeSelectorItem
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setDocMode(true)
                      }}
                    >
                      <p>Documento</p>
                    </S.ModeSelectorItem>
                  </S.ModeSelector>
                  <p className="login_input_label">Número do processo</p>

                  <div>
                    <Input
                      className="login_input_container"
                      name="document"
                      type="tel"
                      placeholder="0000000-00.0000.0.00.0000"
                      isAltMask
                    />

                    <S.FormButton
                      type="submit"
                      onClick={handleSubmit}
                      disabled={loadingCaptcha}
                      style={{
                        filter: !loadingCaptcha
                          ? 'grayscale(0)'
                          : 'grayscale(1)',
                      }}
                      isFocused
                      className="login_consult_button"
                    >
                      {loading ? (
                        <img
                          src={`${process.env.PUBLIC_URL}/assets/icons/loading.svg`}
                          alt="Loading"
                          style={{ height: 40 }}
                        />
                      ) : (
                        'Consultar'
                      )}
                    </S.FormButton>
                  </div>
                </Form>
              )}
              <S.FormFooter>
                <p>Precisa de ajuda?</p>
                <S.FormFooterLink>
                  <a className="footer_link-a" href="/">
                    Toque aqui
                  </a>
                </S.FormFooterLink>
              </S.FormFooter>

              <OmniChannelComponent />
            </div>
          )}

          <a
            href="https://orangelabs.com.br/"
            target="_blank"
            rel="noreferrer"
            className="box_right_footer_mobile align_self_start"
          >
            <span>Desenvolvido por</span>
            <img
              style={{ marginLeft: 6 }}
              alt="orange"
              src={`${process.env.PUBLIC_URL}/assets/icons/orange-gray.svg`}
            />
          </a>
        </S.BoxRight>
      </S.Box>
    </S.Container>
  )
}

export default connect((state: any) => ({ modules: state.NOME_CLIENTE }))(
  UpdatedLogin
)
