export function floatValuesSum(num1: number, num2: number): number {
  const sum = Math.round((num1 + num2) * 100) / 100

  return sum
}

export function floatValuesSub(num1: number, num2: number): number {
  const sum = Math.round((num1 - num2) * 100) / 100

  return sum
}
