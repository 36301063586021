/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import { useField } from '@unform/core'
import * as S from './styles'
import { maskCustomDocument } from '../../../../utils/altMaskDocument'

interface Props {
  name: string
  label?: string
  doNotMask?: boolean
  isAltMask?: boolean
}
type InputProps = JSX.IntrinsicElements['input'] & Props
const Input: React.FC<InputProps> = ({
  name,
  label,
  doNotMask,
  isAltMask,
  className,
  ...rest
}: any) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { fieldName, defaultValue, registerField, error } = useField(name)
  useEffect(() => {
    registerField({
      name: fieldName,
      path: 'value',
      ref: inputRef.current,
    })
  }, [fieldName, registerField])

  const [inputValue, setInputValue] = useState('')

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  function handleChange(s: string, type: string): void {
    if (isAltMask) {
      const masked = maskCustomDocument(s)

      setInputValue(masked)
    } else if (type !== 'document' || doNotMask) {
      setInputValue(s)
    } else {
      const masked = maskDocument(s)
      setInputValue(masked)
    }
  }

  function maskDocument(preValue: string): string {
    let value = preValue
    value = value.replace(/\D/g, '')
    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4')
    } else {
      value = value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        '$1.$2.$3/$4-$5'
      )
    }
    return value
  }

  return (
    <S.Container>
      {label && <S.Label>{label}</S.Label>}
      <S.InputText
        style={{ height: 48 }}
        id={fieldName}
        className={error ? `${className} error` : className}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
        value={inputValue}
        onChange={(e) => {
          handleChange(e.target.value, e.target.id)
          // console.log(e.target.id)
        }}
      />
      {error && error.length > 0 ? (
        <S.ErrorRow>
          <FontAwesomeIcon
            style={{ width: 16, height: 16 }}
            icon={faExclamationTriangle}
          />

          <S.Span>{error ? `${error}` : ''}</S.Span>
        </S.ErrorRow>
      ) : null}
    </S.Container>
  )
}
export default Input
