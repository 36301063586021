/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable react/react-in-jsx-scope */


import { useBarcode } from '@createnextapp/react-barcode'

export function BarCodeV2({ barcodeValue }: any) {
  const { inputRef } = useBarcode({
    value: barcodeValue || '0',
    options: {
      displayValue: true,
      background: '#fff',
      format: 'ITF',
    },
  })

  return (
    <img
      style={{ height: 'auto', width: '100%', maxWidth: '460px' }}
      ref={inputRef}
      alt="código de barras"
    />
  )
}
