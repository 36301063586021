import { useHistory, useLocation } from 'react-router-dom'
import React, { useEffect, useRef, useState } from 'react'
import Lottie from 'react-lottie'
import logoBill from '../../assets/images/logo-bill.png'
import loadingIcon from '../../assets/icons/loading.svg'
import * as S from './styles'
import { PixDataProps, PixTransationProps } from '../Payment/Models'
import { newApi } from '../../services/api'
import copyLottie from '../../assets/icons/copiar-codigo.json'

const THREE_SECONDS = 3000

export const PaymentPix: React.FC = () => {
  const history = useHistory()
  const location = useLocation() as any

  const [pixData, setPixData] = useState<PixDataProps | undefined>()
  const [pixTransaction, setPixTransaction] = useState<
    PixTransationProps | undefined
  >()
  const [showInfo, setShowInfo] = useState<number | null>()

  const refPaymentFormat = useRef(false)

  function copyBarCode(toCopyText: string): void {
    setShowInfo(1)
    const dummy = document.createElement('textarea')
    document.body.appendChild(dummy)
    dummy.value = toCopyText
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)
    setTimeout(() => {
      setShowInfo(undefined)
    }, THREE_SECONDS)
  }
  function pixDate(): string {
    if (pixData) {
      const pixDateHour = new Date(pixData.expires_at)
      const day = String(pixDateHour.getDate()).padStart(2, '0')
      const month = String(pixDateHour.getMonth() + 1).padStart(2, '0') // Months are zero-based
      const year = pixDateHour.getFullYear()
      const hour = String(pixDateHour.getHours()).padStart(2, '0')
      const minute = String(pixDateHour.getMinutes()).padStart(2, '0')

      const formattedDate = ` ${day}/${month}/${year} às ${hour}:${minute}`
      return formattedDate
    }
    return ''
  }

  async function getTransactionData(transactionId: string): Promise<void> {
    if (refPaymentFormat && refPaymentFormat.current) {
      try {
        const token = sessionStorage.getItem('token')
        const response = await newApi.get(
          `/mobile/payment/${transactionId}/status`,
          {
            headers: {
              Authorization: `Basic ${token}`,
              'X-Forwarded-Host':
                window.location.hostname || process.env.REACT_APP_HOST,
            },
          }
        )
        const { data } = response
        let transactionItem = {} as PixTransationProps
        if (location && location.state) {
          transactionItem = location.state.data
        }
        if (
          data.payment &&
          data.payment.status &&
          [5].includes(data.payment.status)
        ) {
          const statusPaymentResume = {
            ...(pixTransaction || transactionItem),
            ...data.payment,
            paymentMethod: 'Pix',
          }

          localStorage.setItem(
            'statusPaymentResume',
            JSON.stringify(statusPaymentResume)
          )
          history.push('/payment/success')
          // transactionId
        } else if (
          data.payment &&
          data.payment.status &&
          [0, 1, 4,10].includes(data.payment.status)
        ) {
          setTimeout(() => {
            getTransactionData(transactionId)
          }, 15 * 1000)
        } else {
          localStorage.setItem(
            'statusPaymentResume',
            JSON.stringify({
              ...(pixTransaction || transactionItem),
              ...data.payment,

              paymentMethod: 'Pix',
            })
          )
          history.push('/payment/error')
        }
      } catch (error) {
        history.push('/payment/error')
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (location && location.state) {
      const pixTransactionProps = location.state.data
      if (pixTransactionProps) {
        setPixTransaction(pixTransactionProps)
        if (refPaymentFormat) {
          refPaymentFormat.current = true
        }
        getTransactionData(pixTransactionProps.id)
      }
    }

    return () => {
      refPaymentFormat.current = false
    }
  }, [])
  useEffect(() => {
    if (pixTransaction) {
      const transaction = pixTransaction.lastTransaction
      if (transaction) {
        setPixData(transaction.extras)
      }
    }
  }, [pixTransaction])

  if (pixData) {
    return (
      <S.PageContainer>
        <S.PageTitle>Pix</S.PageTitle>
        <S.PixContainer>
          <div className="pix_image_container">
            <img
              className="qrCodeContainer"
              src={pixData.qr_code_url}
              alt="qr code"
            />
          </div>
          <div className="pix_text_container">
            <p className="pix_text_title">Aqui está o Pix Copia e Cola</p>
            <p className="pix_text_label page_container_mt-16">
              Copie o código ou use a câmera para ler o QR Code ao lado e
              realize o pagamento no seu aplicativo.
            </p>
            <p className="pix_text_label page_container_mt-16">
              {' '}
              Pague até
              {pixDate()}
            </p>
            <div className="pix_container_box page_container_mt-16">
              <div className="pix_container_box_label_container">
                <div style={{ display: 'grid' }}>
                  <p className="pix_container_box_label">{pixData.qr_code}</p>
                </div>
              </div>
              <button
                className="pix_container_copy_button"
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  copyBarCode(pixData.qr_code_url)
                }}
                style={{ cursor: 'pointer', width: '100%' }}
              >
                Copiar
              </button>
            </div>
            <div className="invoice_footer_bill page_container_mt-16">
              <p>Fornecido por</p>
              <img alt="bill" src={logoBill} />
            </div>
          </div>

          {/* <div className="pix_container_top">
            <p>Copie o código abaixo e utiliza o Pix Copia e Cola no</p>
            <p>aplicativo que você vai fazer o pagamento</p>
            <div className="pix_container_box">
              <div className="pix_container_box_label_container">
                <div style={{ display: 'grid' }}>
                  <p className="pix_container_box_label">{pixData.qr_code}</p>
                </div>
              </div>
              <button
                className="pix_container_copy_button"
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  copyBarCode(pixData.qr_code_url)
                }}
                style={{ cursor: 'pointer', width: '100%' }}
              >
                Copiar
              </button>
            </div>
            <img
              className="qrCodeContainer"
              src={pixData.qr_code_url}
              alt="qr code"
            />
            <p>
              Pague até
              {pixDate()}
            </p>
            <p className="pix_container_13px">
              Abra o leitor de QR Code na área Pix do aplicativo que você vai
              fazer o pagamento e escaneie o código
            </p>
          </div> */}
        </S.PixContainer>

        <S.PixRow className="page_container_mt-16">
          <S.BackButton
            type="button"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              history.goBack()
              setPixData(undefined)
            }}
            style={{ cursor: 'pointer' }}
          >
            Alterar forma de pagamento
          </S.BackButton>
          <S.ButtonPay
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              history.replace('/')
              setPixData(undefined)
            }}
          >
            Tela inicial
          </S.ButtonPay>
        </S.PixRow>
        <S.BoxInfoCopy
          style={{
            opacity: !showInfo ? 0 : 1,
            bottom: !showInfo ? 0 : 40,
            transition: 'all 0.5s ease',
          }}
        >
          <span>Código copiado para a área de transferencia</span>
        </S.BoxInfoCopy>
      </S.PageContainer>
    )
  }

  return (
    <div
      style={{
        // background: '#f00',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={loadingIcon} alt="Loading" style={{ height: 50 }} />
    </div>
  )
}
