/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React from 'react'
import { TFInvoice } from '../../../../utils/models'
import arrowDropdown from '../../../../assets/icons/arrowDropdown.svg'

import * as S from './styles'
import { StringToCurrency } from '../../../../utils/stringToCurrency'

interface PaymentInvoiceProps {
  invoiceItem: TFInvoice
}

export const PaymentInvoice = ({ invoiceItem }: PaymentInvoiceProps): any => {
  const [isOpen, setIsOpen] = React.useState(false)
  const InvoiceExtrasCusta = ({ invoice }: { invoice: TFInvoice }): any => {
    const { extras } = invoice
    if (extras) {
      if (extras.custa) {
        return extras.custa.map((custa, index) => {
          return (
            <div
              key={index}
              className="payment_invoice_pl-16 payment_invoice_custas"
            >
              {custa.itemGuia.length ? (
                custa.itemGuia.map((itemGuia, indexItem) => {
                  return (
                    <div
                      key={indexItem}
                      className="payment_invoice_row_between"
                    >
                      <p className="payment_invoice_label payment_invoice_max_width-200">
                        {itemGuia.descricao}
                      </p>
                      <p className="payment_invoice_label">
                        {`${StringToCurrency(`${itemGuia.valor || 0}`)}`}
                      </p>
                    </div>
                  )
                })
              ) : (
                <div className="payment_invoice_row_between">
                  <p className="payment_invoice_label payment_invoice_max_width-200">
                    {custa.descricao}
                  </p>
                  {custa.valorCusta ? (
                    <p className="payment_invoice_label">
                      {`${StringToCurrency(`${custa.valorCusta || 0}`)}`}
                    </p>
                  ) : null}
                </div>
              )}
            </div>
          )
        })
      }
    }

    return <></>
  }

  return (
    <S.PaymentInvoiceContainer isOpen={isOpen} key={invoiceItem.id}>
      <div
        onClick={() => {
          setIsOpen((opened) => !opened)
        }}
        className="payment_invoice_row_between payment_pointer"
      >
        <p className="payment_invoice_strong">{`${invoiceItem.refDate||invoiceItem.contentRefDate}`}</p>
        <div className="payment_invoice_row">
          <p className="payment_invoice_strong">
            {`${StringToCurrency(`${invoiceItem.totalPaymentDue||invoiceItem.total || 0}`)}`}
          </p>
          {/* <img
            alt="down"
            src={arrowDropdown}
            className="payment_invoice_icon"
          /> */}
        </div>
      </div>

      {isOpen ? <InvoiceExtrasCusta invoice={invoiceItem} /> : null}
    </S.PaymentInvoiceContainer>
  )
}
