/* eslint-disable react/jsx-closing-bracket-location */
/* eslint-disable react/jsx-indent-props */
import { useHistory, useLocation } from 'react-router-dom'
import Lottie from 'react-lottie'
import React, { useEffect, useRef, useState } from 'react'
import logoBill from '../../assets/images/logo-bill.png'
import loadingIcon from '../../assets/icons/loading.svg'
import loadingIconPrimary from '../../assets/icons/loading-primary.svg'

import * as S from './styles'
import {
  BoletoDataProps,
  BoletoTransationProps,
  PixDataProps,
  PixTransationProps,
} from '../Payment/Models'
import { newApi } from '../../services/api'
import BarcodeComponent from './components/barCode'
import { maskBarCodeString, maskDocument } from '../../utils/altMaskDocument'
import copyLottie from '../../assets/icons/copiar-codigo.json'
import { PaymentSlipBoleto } from '../../components/PDF/PaymentSlipBoleto'
import { TFInvoice } from '../../utils/models'
import { BarCodeV2 } from './components/barCodeV2'

const THREE_SECONDS = 3000

export const PaymentBoleto: React.FC = () => {
  const history = useHistory()
  const location = useLocation() as any
  const [showInfo, setShowInfo] = useState<number | null>()
  const [downloadThis, setDownloadThis] = useState<boolean | null>(false)

  const [boletoData, setBoletoData] = useState<BoletoDataProps | undefined>()
  const [boletoTransaction, setBoletoTransaction] = useState<
    BoletoTransationProps | undefined
  >()
  const refPaymentFormat = useRef(false)
  const [invoices, setInvoices] = useState<TFInvoice[]>([])

  useEffect(() => {
    const temp = localStorage.getItem('Invoices')
    if (temp) {
      const tempInvoices = JSON.parse(temp)
      if (tempInvoices[0] && tempInvoices[0].id) {
        setInvoices(tempInvoices)
      }
    }
  }, [])

  useEffect(() => {
    if (downloadThis) {
      setTimeout(() => {
        setDownloadThis(false)
      }, THREE_SECONDS)
    }
  }, [downloadThis])

  function copyBarCode(toCopyText: string): void {
    setShowInfo(1)
    const dummy = document.createElement('textarea')
    document.body.appendChild(dummy)
    dummy.value = toCopyText
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)
    setTimeout(() => {
      setShowInfo(undefined)
    }, THREE_SECONDS)
  }

  function boletoDate(): string {
    if (boletoData && boletoData.extras) {
      const boletoDateHour = new Date(boletoData.extras.due_at)
      const day = String(boletoDateHour.getDate()).padStart(2, '0')
      const month = String(boletoDateHour.getMonth() + 1).padStart(2, '0') // Months are zero-based
      const year = boletoDateHour.getFullYear()
      const hour = String(boletoDateHour.getHours()).padStart(2, '0')
      const minute = String(boletoDateHour.getMinutes()).padStart(2, '0')

      const formattedDate = ` ${day}/${month}/${year} às ${hour}:${minute}`
      return formattedDate
    }
    return ''
  }

  async function getTransactionData(transactionId: string): Promise<void> {
    if (refPaymentFormat && refPaymentFormat.current) {
      try {
        const token = sessionStorage.getItem('token')
        const response = await newApi.get(
          `/mobile/payment/${transactionId}/status`,
          {
            headers: {
              Authorization: `Basic ${token}`,
              'X-Forwarded-Host':
                window.location.hostname || process.env.REACT_APP_HOST,
            },
          }
        )
        const { data } = response
        let transactionItem = {} as PixTransationProps
        if (location && location.state) {
          transactionItem = location.state.data
        }
        if (
          data.payment &&
          data.payment.status &&
          [5].includes(data.payment.status)
        ) {
          const statusPaymentResume = {
            ...(boletoTransaction || transactionItem),
            ...data.payment,
            paymentMethod: 'Boleto',
          }
          localStorage.setItem(
            'statusPaymentResume',
            JSON.stringify(statusPaymentResume)
          )
          history.push('/payment/success')
          // transactionId
        } else if (
          data.payment &&
          data.payment.status &&
          [0, 1, 4,10].includes(data.payment.status)
        ) {
          setTimeout(() => {
            getTransactionData(transactionId)
          }, 60 * 1000)
        } else {
          const statusPaymentResume = {
            ...(boletoTransaction || transactionItem),
            ...data.payment,
            paymentMethod: 'Boleto',
          }
          localStorage.setItem(
            'statusPaymentResume',
            JSON.stringify(statusPaymentResume)
          )
          history.push('/payment/error')
        }
      } catch (error) {
        history.push('/payment/error')
        console.log(error)
      }
    }
  }

  useEffect(() => {
    if (location && location.state) {
      const pixTransactionProps = location.state.data
      if (pixTransactionProps) {
        setBoletoTransaction(pixTransactionProps)
        if (refPaymentFormat) {
          refPaymentFormat.current = true
        }
        getTransactionData(pixTransactionProps.id)
      }
    }
    return () => {
      refPaymentFormat.current = false
    }
  }, [])
  useEffect(() => {
    if (boletoTransaction) {
      const transaction = boletoTransaction.lastTransaction
      if (transaction) {
        setBoletoData(transaction)
      }
    }
  }, [boletoTransaction])

  if (boletoData && boletoTransaction) {
    return (
      <S.PageContainer>
        {downloadThis ? (
          <div style={{ display: 'none' }}>
            <PaymentSlipBoleto
              invoices={invoices}
              boletoData={boletoData}
              boletoTransaction={boletoTransaction}
            />
          </div>
        ) : null}

        <S.PageTitle>Boleto Bancário</S.PageTitle>
        <S.BoletoContainer>
          <div className="boleto_background_container boleto_container_left">
            <S.BoletoLeftTitle>
              Aqui está o código de barras do seu Boleto
            </S.BoletoLeftTitle>
            <S.BoletoLeftLabel className="page_container_mt-24">
              Com seu aplicativo de pagamento aberto, use a câmera para ler o
              código de barras abaixo e realizar o pagamento
            </S.BoletoLeftLabel>
            <div className="page_container_mt-32 boleto_max-width">
              {boletoData.barcode ? (
                <BarCodeV2 barcodeValue={boletoData.barcode} />
              ) : null}
            </div>

            <S.BoletoLeftLineTitle className="page_container_mt-32">
              Linha digitável
            </S.BoletoLeftLineTitle>

            <S.BoletoLeftLineLabel className="page_container_mt-8">
              {maskBarCodeString(boletoData?.extras?.line||'')}
            </S.BoletoLeftLineLabel>
            <S.BoletoRow className="page_container_mt-32">
              <S.BackButton
                type="button"
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  setDownloadThis(true)
                }}
                style={{ cursor: 'pointer' }}
              >
                {downloadThis ? (
                  <img src={loadingIconPrimary} alt="Loading" style={{ height: 24 }} />
                ) : (
                  'Salvar Boleto'
                )}
              </S.BackButton>
              <S.ButtonPay
                onClick={() => {
                  copyBarCode(boletoData?.extras?.line||'')
                }}
              >
                Copiar Código
              </S.ButtonPay>
            </S.BoletoRow>
            <div className="invoice_footer_bill page_container_mt-16">
              <p>Fornecido por</p>
              <img alt="bill" src={logoBill} />
            </div>
          </div>
          <div className="pix_text_container boleto_background_container boleto_container_right">
            <p className="pix_text_title">Detalhes do documento</p>
            <p className="boleto_text_info_title page_container_mt-16">
              VENCIMENTO EM
            </p>
            <p className="boleto_text_info_label">{boletoDate()}</p>
            <p className="boleto_text_info_title page_container_mt-8">
              VALOR TOTAL
            </p>
            <p className="boleto_text_info_label">
              R$
              {parseFloat(boletoTransaction?.valueDue).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
              })}
            </p>
            <p className="boleto_text_info_title page_container_mt-8">
              EMITIDO EM
            </p>
            <p className="boleto_text_info_label boleto_text_info_normal">
              {boletoData.created}
            </p>
            <div className="page_container_line page_container_mt-16" />

            <p className="boleto_text_info_title page_container_mt-16">
              PAGADOR
            </p>
            <p className="boleto_text_info_label boleto_text_info_normal">
              {boletoData.name}
            </p>
            <p className="boleto_text_info_title page_container_mt-8">CPF</p>
            <p className="boleto_text_info_label boleto_text_info_normal">
              {maskDocument(boletoData.doc)}
            </p>
            <div className="page_container_line page_container_mt-16" />

            <p className="boleto_text_info_title page_container_mt-16">
              BENEFICIÁRIO
            </p>
            <p className="boleto_text_info_label boleto_text_info_normal">
              {boletoTransaction.merchant.name}
            </p>
            <p className="boleto_text_info_title page_container_mt-8">CNPJ</p>
            <p className="boleto_text_info_label boleto_text_info_normal">
              {maskDocument(boletoTransaction.merchant.doc)}
            </p>
          </div>
        </S.BoletoContainer>

        <S.BoletoRow className="page_container_mt-16 page_container_mobile_padding_horizontal-24">
          <S.BackButton
            type="button"
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              history.goBack()
              setBoletoData(undefined)
            }}
            style={{ cursor: 'pointer' }}
          >
            Alterar forma de pagamento
          </S.BackButton>
          <S.ButtonPay
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()

              history.replace('/')
              setBoletoData(undefined)
            }}
          >
            Tela inicial
          </S.ButtonPay>
        </S.BoletoRow>
        <S.BoxInfoCopy
          style={{
            opacity: !showInfo ? 0 : 1,
            bottom: !showInfo ? 0 : 40,
            transition: 'all 0.5s ease',
            padding: 0,
          }}
        >
          <Lottie
            style={{
              width: 20,
              height: 20,
              margin: '0px 0px 0px 0px',
              padding: 0,
            }}
            options={{
              autoplay: true,
              loop: true,
              animationData: copyLottie,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
              },
            }}
            isStopped={!showInfo}
            height={20}
            width={20}
          />
          <span>Código de barras copiado</span>
        </S.BoxInfoCopy>
      </S.PageContainer>
    )
  }

  return (
    <div
      style={{
        // background: '#f00',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img src={loadingIcon} alt="Loading" style={{ height: 50 }} />
    </div>
  )
}
