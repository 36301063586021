/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React from 'react'
import * as S from './styles'
import { TFInvoice } from '../../../../utils/models'
import keyboardDownIcon from '../../../../assets/icons/keyboardDownIcon.svg'
import moreVert from '../../../../assets/icons/move_vert.svg'
import { StringToCurrencyNoSifra } from '../../../../utils/stringToCurrency'

interface InvoiceItemProps {
  invoiceItem: TFInvoice
  checkIdList(invoiceIdparse: number | null): boolean
  handlePaymentButton(invoice: TFInvoice): void
  invoiceDescription(invoice: TFInvoice): string
  generatingColorStatus(inv: TFInvoice): string
  generatingTextStatus(inv: TFInvoice): string
  checkDueDate(dataInvoice: string): boolean
  checkDueStatus(invoiceInfo: TFInvoice): boolean
}

export const InvoiceItem = ({
  invoiceItem,
  checkIdList,
  handlePaymentButton,
  invoiceDescription,
  generatingColorStatus,
  generatingTextStatus,
  checkDueDate,
  checkDueStatus,
}: InvoiceItemProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const isDisabled =
    !checkDueDate(invoiceItem.paymentDueDate) || !checkDueStatus(invoiceItem)
  function transformDate(d: string): string {
    const dSplit = d.split('-')

    return `${dSplit[2]}/${dSplit[1]}/${dSplit[0]}`

    return ''
  }

  return (
    <S.InvoiceItem
      isDisabledButton={isDisabled}
      isOpen={isOpen}
      key={invoiceItem.invoice_id}
    >
      <div
        onClick={() => {
          handlePaymentButton(invoiceItem)
        }}
        className="grid_container_row invoice_header"
      >
        <div className="grid_container_center invoice_pointer">
          <p>{invoiceItem.refDate || '-'}</p>
        </div>
        <div className="grid_container_center invoice_pointer">
          <p>
            {invoiceItem.paymentDueDate
              ? transformDate(invoiceItem.paymentDueDate)
              : '-'}
          </p>
        </div>
        <div className="grid_container_center ">
          <div
            style={{
              display: 'grid',
            }}
          >
            <p className="text_overflow">
              267 kWh
              {/* {invoiceDescription(invoiceItem) || '-'} */}
            </p>
          </div>
        </div>
        <div className="grid_container_center grid_container_end">
          <p className="invoice_value">
            {`${StringToCurrencyNoSifra(
              `${invoiceItem.totalPaymentDue || 0}`
            )}`}
          </p>
        </div>
        <div className="grid_container_center">
          <div className="invoice_status_container">
            <S.InvoiceItemStatus color={generatingColorStatus(invoiceItem)} />
            <p>{generatingTextStatus(invoiceItem) || '-'}</p>
          </div>
        </div>
        <div
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setIsOpen((opened) => !opened)
          }}
          className="grid_container_center invoice_item_dropdown_button"
        >
          <S.DropdownButton>
            <img
              className="arrow_icon"
              alt="keyboardDownIcon"
              src={moreVert}
            />
          </S.DropdownButton>
        </div>
      </div>
    </S.InvoiceItem>
  )
}
