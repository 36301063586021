import styled, { css } from 'styled-components'
import { OmniChannelComponentProps } from '.'

export const Wrapper = styled.div<OmniChannelComponentProps>`
  ${({ isLogoWhite }) => css`
    color: ${isLogoWhite ? '#ffffff' : '#212121'};
  `}
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 8px;

  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
`
