/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/no-duplicates */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable new-cap */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable consistent-return */
/* eslint-disable new-cap */
import React, { useEffect, useState } from 'react'
import jsPDF from 'jspdf'
import { useBarcode } from '@createnextapp/react-barcode'
import { parseISO, format } from 'date-fns'
import pt from 'date-fns/locale/pt-BR'

import { InvoiceDTO, Invoice, PayDTO, TFInvoice } from '../../utils/models'
import logoBillWhite from '../../assets/images/bill-logo-white.png'
import dollarIcon from '../../assets/images/dollar.png'
import * as S from './styles'

import {
  BoletoDataProps,
  BoletoTransationProps,
} from '../../pages/Payment/Models'
import { maskCustomDocument, maskDocument } from '../../utils/altMaskDocument'
import { base64Font } from './base64Font'
import { base64FontBold } from './base64FontBold'
import { base64FontSemiBold } from './base64FontSemiBold'

interface PaymentSlipBoletoProps {
  boletoData: BoletoDataProps
  boletoTransaction: BoletoTransationProps
  invoices: TFInvoice[]
}
interface InvProofDTO {
  proof: PayDTO
  invoices: TFInvoice[]
}

export const ProofPaymentPDF = ({ proof, invoices }: InvProofDTO): any => {
  const [client, setClient] = useState<Invoice>()
  const [clientRef, setClientRef] = useState<any>()

  const logoGreen = `${process.env.PUBLIC_URL}/assets/images/bill-icon-green.png`
  const logoPigz = `${process.env.PUBLIC_URL}/assets/images/icon-orange.png`

  const [paymentInvoices, setPaymentInvoices] = useState<Array<TFInvoice>>()

  function overlapInvoices(
    paymentResumeItem: PayDTO,
    invoicesList: TFInvoice[]
  ): void {
    const tempArray = [...invoicesList]
    const itemArray = tempArray.filter((invoice) =>
      paymentResumeItem.invoices.some(
        (paymentInvoiceItem) => paymentInvoiceItem.id === invoice.invoice_id
      )
    )
    const uniqueArray = itemArray.reduce((acc: TFInvoice[], item) => {
      if (!acc.some((ac) => ac.invoice_id === item.invoice_id)) {
        acc.push(item)
      }
      return acc
    }, [])
    setPaymentInvoices(uniqueArray)
  }

  useEffect(() => {
    console.log(invoices)
    if (proof) {
      overlapInvoices(proof, invoices || [])
    }
  }, [proof, invoices])

  useEffect(() => {
    const tempClient = localStorage.getItem('invoice')
    if (tempClient) {
      const temp = JSON.parse(tempClient)
      setClient(temp)
    }
    const tempClientRef = localStorage.getItem('Ref')
    if (tempClientRef) {
      const temp = JSON.parse(tempClientRef)
      setClientRef(temp)
    }
  }, [proof])

  function transactionDoc(): string {
    const size = proof.transactions.length
    if (size && size !== 1) {
      return proof.transactions[size - 1].doc
    }
    return proof.transactions[0].doc
  }

  function transactionAtk(): string {
    const size = proof.transactions.length
    if (size && size !== 1) {
      return proof.transactions[size - 1].atk
    }
    return proof.transactions[0].atk
  }

  function transactionId(): number {
    const size = proof.transactions.length
    if (size && size !== 1) {
      return proof.transactions[size - 1].id
    }
    return proof.transactions[0].id
  }
  function transactionPan(): string {
    const size = proof.transactions.length
    if (size && size !== 1) {
      return proof.transactions[size - 1].pan
    }
    return proof.transactions[0].pan
  }

  const logo = `${process.env.PUBLIC_URL}/assets/images/cpfl-colored-icon.png`

  function dataFormat(data: string): string {
    const Date = parseISO(data)
    const formattedDate = format(Date, 'dd MMM. yyyy - HH:mm', {
      locale: pt,
    })

    return formattedDate.replace('  ', '')
  }

  const merchantName =
    proof.merchant?.name || proof.lastTransaction?.merchant.name || '-'
  const merchantDoc = proof.merchant?.doc
    ? maskDocument(proof.merchant?.doc)
    : '-'
  const customerName =
    proof.lastTransaction?.name || proof.lastTransaction?.holderName || '-'
  const customerDoc = proof.lastTransaction?.doc
    ? maskDocument(proof.lastTransaction.doc)
    : '-'

  useEffect(() => {
    const tempClient = localStorage.getItem('Invoices')
    if (paymentInvoices) {
      if (tempClient) {
        const divId = document.getElementById('pdfPrint') as HTMLElement
        const printDoc = new jsPDF({
          unit: 'px', // set the units of measurement to px
          format: 'A4', // set the 'paper' size
          userUnit: 72, // set the DPI here. Web uses 72 but you can change to 150 or 300
        })
        // const printDoc = new jsPDF('portrait', 'pt', 'A4')

        printDoc.addFileToVFS(
          '../../assets/fonts/Roboto/Roboto-Medium.ttf',
          'Inter'
        )
        printDoc.addFileToVFS('Inter-Regular.ttf', base64Font)
        printDoc.addFileToVFS('Inter-Bold.ttf', base64FontBold)
        printDoc.addFileToVFS('Inter-SemiBold.ttf', base64FontSemiBold)

        printDoc.addFont('Inter-Regular.ttf', 'Inter', 'normal')
        printDoc.addFont('Inter-Bold.ttf', 'Inter', 'bold')
        printDoc.addFont('Inter-SemiBold.ttf', 'Inter', 'semi-bold')
        printDoc.setFont('Inter')

        printDoc.html(divId, {
          callback(pdf) {
            pdf.save(`comprovantePDF.pdf`)
          },
        })
      }
    }
  }, [paymentInvoices])

  return (
    <S.Container
      id="pdfPrint"
      style={{
        margin: 'auto',
        width: '450px',
        padding: '0 16px',
        minHeight: '600px',
      }}
    >
      <div
        style={{
          width: '100%',
          height: 10,
        }}
      />

      <S.Column
        style={{ alignItems: 'center', width: '100%', marginBottom: 12 }}
      >
        <S.Row>
          <S.Column>
            <img
              alt="Logo"
              height={48}
              width={48}
              src={logo}
              style={{ marginBottom: 10 }}
            />
          </S.Column>
        </S.Row>
      </S.Column>
      <S.HeaderComponentTitle>
        <p>{`Comprovante de Pagamento - ${proof.paymentMethod}`}</p>
      </S.HeaderComponentTitle>
      <S.InfoProofTitle>{dataFormat(proof.created)}</S.InfoProofTitle>
      <S.Column style={{ marginTop: '4px' }}>
        <S.InfoProofTitle>Número do Processo</S.InfoProofTitle>
        <S.InfoProofLabel>{clientRef?.id || '-'}</S.InfoProofLabel>
      </S.Column>
      <S.InfoProofTitle>Faturas</S.InfoProofTitle>

      {paymentInvoices?.map((invoiceItem, invoiceIndex) => (
        <S.GridContainer
          style={{
            gap: 0,
            borderTop: invoiceIndex !== 0 ? '1px solid #DDDDDD' : undefined,
            paddingTop: 4,
            paddingBottom: 4,
          }}
          gridRemplateColumns="1fr"
        >
          <div className="invoice_custas_container">
            <div className="invoice_row_between invoice_max_width">
              <S.InvoiceRow>
                <S.InfoProofInvoiceLabelBold>
                  {invoiceItem.refDate||invoiceItem.contentRefDate || '-'}
                </S.InfoProofInvoiceLabelBold>

              </S.InvoiceRow>

              <S.InfoProofInvoiceLabelBold>
                {`R$ ${Number(`${(invoiceItem.totalPaymentDue||invoiceItem.total||0)}`)?.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                })}`}
              </S.InfoProofInvoiceLabelBold>
            </div>
          </div>

        </S.GridContainer>
      ))}
      {proof?.lastTransaction?.installments &&
      proof?.lastTransaction?.installments > 1 ? (
        <>
          <div className="invoice_row_between invoice_max_width">
            <S.InfoProofInvoiceLabelBold>Parcelas</S.InfoProofInvoiceLabelBold>
            <S.InfoProofInvoiceLabelBold>{`${proof?.lastTransaction?.installments}x`}</S.InfoProofInvoiceLabelBold>
          </div>
        </>
      ) : null}
      {proof?.lastTransaction?.installmentsInterest ? (
        <>
          <div className="invoice_row_between invoice_max_width">
            <S.InfoProofInvoiceLabelBold>
              Taxa de Serviço
            </S.InfoProofInvoiceLabelBold>
            <S.InfoProofInvoiceLabelBold>{`R$ ${(
              proof?.lastTransaction?.installmentsInterest || 0
            ).toLocaleString('pt-BR', {
              minimumFractionDigits: 2,
            })}`}</S.InfoProofInvoiceLabelBold>
          </div>
        </>
      ) : null}
      <S.InfoProofTitle>Valor</S.InfoProofTitle>
      <S.InfoProofLabel>
        {`R$ ${
          Number(proof.total).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
          }) || '-'
        }`}
      </S.InfoProofLabel>
      <S.Line />
      <S.GridContainer gridRemplateColumns="1fr">
        <S.Column>
          <S.InfoProofTitle>Transferido para</S.InfoProofTitle>
          <S.InfoProofLabel>{merchantName}</S.InfoProofLabel>
          <S.InfoProofSubLabel>{merchantDoc}</S.InfoProofSubLabel>
        </S.Column>
        <S.Column>
          <S.InfoProofTitle>Enviado de</S.InfoProofTitle>
          <S.InfoProofLabel>{customerName}</S.InfoProofLabel>
          <S.InfoProofSubLabel>{customerDoc}</S.InfoProofSubLabel>
        </S.Column>
        <S.Column>
          <S.InfoProofTitle>ID da Transação</S.InfoProofTitle>
          <S.InfoProofLabel>{proof.id}</S.InfoProofLabel>
        </S.Column>
      </S.GridContainer>
      <S.Line />
      <S.GridContainer gridRemplateColumns="1fr">
        <S.InfoProofLabel>Detalhes do pagamento</S.InfoProofLabel>
        <S.Column>
          <S.InfoProofTitle>Empresa</S.InfoProofTitle>
          <S.InfoProofLabel>{merchantName} </S.InfoProofLabel>
        </S.Column>
      </S.GridContainer>

      <S.SlipFooter>
        <div className="slipfooter_row">
          <img height={12} width={28} src={logoGreen} alt="bill" />
          <p>Arrecadação Omnichannel</p>
        </div>
        <div className="slipfooter_row">
          <p className="slipfooter_label">Desenvolvido por </p>
          <img src={logoPigz} height={12} width={36} alt="pigz" />
        </div>
      </S.SlipFooter>
    </S.Container>
  )
}
