import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import redCircleIcon from '../../../../assets/icons/information-red.svg'
import {
  PayDTO,
  PaymentDTO,
  TransactionsDTO,
  TransactionsProps,
} from '../../../../utils/models'
import cancelIcon from '../../../../assets/icons/cancel.svg'

import * as S from './styles'

const Error: React.FC = () => {
  const location = useLocation<unknown>()
  const history = useHistory()
  const [paymentResume, setPaymentResume] = useState<PayDTO>()
  const [transactionData, setTransactionData] = useState<
    TransactionsProps | undefined
  >()
  const [errorMsg, setErrorMsg] = useState(
    'Pagamento não autorizado. Verifique os dados informados e tente novamente.'
  )
  const [totalCart, setTotalCart] = useState(0)

  function setErrorTextFn(data: TransactionsProps): void {
    if (data.acquirerReturnCode) {
      if (
        ['5', '05', '005', '51', '051', '1007', '1016'].includes(
          data.acquirerReturnCode
        )
      ) {
        setErrorMsg(
          'Pagamento não autorizado, verifique seu saldo ou limite. Contate a central do seu cartão.'
        )
        return
      }

      if (
        [
          '3',
          '03',
          '003',
          '7',
          '07',
          '57',
          '59',
          '62',
          '78',
          '1009',
          '1813',
          '1828',
          '3002',
          '83',
        ].includes(data.acquirerReturnCode)
      ) {
        setErrorMsg(
          'Transação não permitida, verifique se seu cartão possui a função crédito e está disponível para compras. Contate a central do seu cartão.'
        )
        return
      }
      if (['054', '54', 'N7', '82'].includes(data.acquirerReturnCode)) {
        setErrorMsg(
          'Pagamento não autorizado. Verifique os dados informados e tente novamente.'
        )
        return
      }
    }

    setErrorMsg(
      'Pagamento não autorizado. Verifique se existe algum bloqueio e tente novamente.'
    )
  }

  useEffect(() => {
    const tempPaymentDefault = localStorage.getItem('statusPaymentResume')
    if (tempPaymentDefault) {
      setPaymentResume(JSON.parse(tempPaymentDefault))
      setTotalCart(JSON.parse(tempPaymentDefault).total||0)
    }
    // else {
    //   history.push('/')
    // }
  }, [history, location])

  useEffect(() => {
    if (paymentResume) {
      setTransactionData(paymentResume.lastTransaction)
    }
  }, [paymentResume])

  useEffect(() => {
    if (transactionData) {
      setErrorTextFn(transactionData)
      //
    }
  }, [transactionData])

  return (
    <S.PageContainer>
      <S.PageTitle>Pagamento não efetuado</S.PageTitle>
      <S.PixContainer className="page_container_mt-16">
        <img src={cancelIcon} alt="cancel" />
        <p className='pix_text_label'>
          {errorMsg}
        </p>
      </S.PixContainer>

      <S.PixRow className="page_container_mt-16">
        <S.BackButton
          type="button"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            history.push('/payment')
          }}
          style={{ cursor: 'pointer' }}
        >
          Alterar forma de pagamento
        </S.BackButton>
        <S.ButtonPay
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()

            history.push('/')
          }}
        >
          Tela inicial
        </S.ButtonPay>
      </S.PixRow>
    </S.PageContainer>
  )
}
export default Error
