import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  padding: 20px;
  height: 140px;
  z-index: 11;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    padding: 20px 16px;
    height: 120px;
    background: #0f5286;
    flex-direction: column;
    height: 80px;
    padding: 0 16px;
  }
`
export const BarLoading = styled.div`
  max-width: 1200px;
  margin: 0 10px;
  top: 100px;
  position: fixed;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-image: linear-gradient(
    -90deg,
    var(--primaryColor) 0%,
    var(--primaryColor) 50%,
    #fff 100%
  );
  background-size: 100% 100%;
  animation: animation 50s ease-in infinite;
  @keyframes animation {
    0% {
      background-position: -10000px 0;
    }
    100% {
      background-position: 10000px 0;
    }
  }
`

export const BoxDesktop = styled.div`
  max-width: 1200px;
  transition: 0.3s;
  width: 100%;
  display: flex;
  box-shadow: 0px 6px 24px #0000001a;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  > div {
    margin: 0;
    transition: 0.3s;
    padding: 5px 0;
    flex: 1;
    width: 100%;
    align-items: center;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    .logoGrid {
      margin: 0;
      .logoBig {
        display: flex;
      }
      .logoSmall {
        display: none;
      }
    }
  }
  #textTop {
    display: flex;
    align-items: center;
  }
  #textViewMobile {
    display: none;
  }
  #textBody {
    display: none;
  }
  #textViewPc {
    display: flex;
  }
  overflow: hidden;
  @media only screen and (max-width: 768px) {
    display: none;
    #textViewPc {
      display: none;
    }
    #textViewMobile {
      width: 100%;
      margin-left: 20px;
      justify-content: space-between;
      align-items: center;
      display: flex;
    }
    > div {
      .logoGrid {
        margin: 0;
        .logoBig {
          display: none;
        }
        .logoSmall {
          display: flex;
        }
      }
      > div:last-child {
        display: flex;
      }
    }
    div#textBody {
      display: flex;
      flex-direction: column;
      > div {
        > div {
          display: none;
        }
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: 10px;
        gap: 20px;
        margin-top: 5px;
        border-top: 0.5px solid #9e9e9e;
      }
    }
    padding: 15px 20px 20px 20px;
    width: 100vw;
    height: 70px;
    position: relative;
    align-items: flex-start;
    justify-content: flex-start;
    > div {
      align-items: flex-start;
    }
    img {
      height: 30px;
    }
    #adress,
    #client {
      display: none !important;
    }
  }
`

export const BoxMobile = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    display: flex;
    background: #ffffff;
    border-radius: 16px;
    padding: 0 16px;
    height: 64px;
    min-height: 64px;
    transform: translateY(24px);
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    .box_img {
      width: 40px;
      height: 40px;
    }
  }
`

export const HeaderMobile = styled.div`
  display: none;
  @media only screen and (max-width: 768px) {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transform: translateY(20px);
    .header_img {
      /* width: 124px; */
      height: 24px;
    }

    .header_logout {
      display: flex;
      flex-direction: row;

      align-items: center;
      justify-content: center;
      gap: 4px;

      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      text-align: left;
      color: #ffffff;
    }
  }
`

export const Label = styled.span`
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  text-align: left;
  color: #212121;
  @media only screen and (max-width: 768px) {
    font-size: 10pt;
    margin-top: 10px;
  }
`

export const Content = styled.span`
  font-family: 'Inter';
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #212121;

  @media only screen and (max-width: 768px) {
    margin-right: 10px;
  }
`

export const ContentTitle = styled.span`
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  text-align: left;

  color: #212121;
  @media only screen and (max-width: 768px) {
    margin-right: 10px;
    font-weight: bold;
    font-size: 20px;
  }

  @media only screen and (max-width: 400px) {
    margin-right: 10px;

    font-weight: bold;
    font-size: 16px;
    margin-bottom: 4px;
  }
`

export const ContentUc = styled.span`
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #212121;
  @media only screen and (max-width: 768px) {
    margin-right: 10px;
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 16px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
`

export const Logout = styled.button`
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  width: 93px;
  height: 40px;
  border-radius: 8px;
  background-color: #f0f0f0;
  gap: 8px;

  transition: 300ms;
  color: #212121;

  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  &:active {
    background-color: var(--primaryColor);
  }

  @media only screen and (max-width: 768px) {
    background: #eeeeee;
    color: #ffffff;
    width: 40px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }
`

export const ChangeRef = styled.button`
  display: flex;
  border: none;
  border: 1px solid #212121;
  align-items: center;
  justify-content: center;
  width: 140px;
  height: 40px;
  border-radius: 8px;
  background-color: #ffffff;
  gap: 8px;

  transition: 300ms;
  color: #212121;

  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;

  &:active {
    background-color: var(--primaryColor);
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

export const Menu = styled.button`
  display: none;
  border: none;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #edf1f5;
  transition: 300ms;
  &:active {
    background-color: var(--primaryColor);
  }
  @media only screen and (max-width: 768px) {
    display: flex;
    padding: 5px;
  }
`
