import styled, { css } from 'styled-components'

export const ModalContainer = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  z-index: 50;
  background: rgba(0, 0, 0, 0.3);
  @media only screen and (min-width: 769px) {
    justify-content: center;
    align-items: center;
  }
`

export const ModalDetail = styled.div`
  width: 100vw;
  padding: 24px;
  background: #ffffff;
  border-radius: 24px 24px 0px 0px;
  padding-bottom: 96px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: #212121;
  gap: 16px;
  @media only screen and (min-width: 769px) {
    border-radius: 24px;
    padding: 24px;
    max-width: 600px;
  }

  .modal_detail_max-width {
    width: 100%;
  }

  .modal_detail_column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  .modal_detail_space_between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
  }
  .modal_detail_head {
    font-family: Inter;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
  }
  .modal_detail_head_label {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: #212121;
  }
  .modal_detail_label {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #212121;
  }

  .modal_line {
    background: #e0e0e0;
    width: 100%;
    height: 1px;
  }
`
export const ModalButton = styled.button`
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00ab50;
  color: #ffffff;
  padding: 8px 28px;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  align-self: flex-end;
`

interface InvoiceItemStatusProps {
  color?: string
}
export const InvoiceItemStatus = styled.div<InvoiceItemStatusProps>`
  ${({ color }) => css`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${color || '#9E9E9E'};
  `}
`
