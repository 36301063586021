import styled, { css } from 'styled-components'

interface PaymentInvoiceContainerProps {
  isOpen?: boolean
}

export const PaymentInvoiceContainer = styled.div<PaymentInvoiceContainerProps>`
  ${({ isOpen }) => css`
    padding-left: 24px;
    padding-right: 24px;

    .payment_invoice_label {
      color: #212121;
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
    .payment_invoice_strong {
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      text-align: right;
    }
    .payment_invoice_row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .payment_invoice_row_between {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }

    .payment_invoice_max_width-200 {
      max-width: 200px;
    }
    .payment_invoice_icon {
      ${isOpen ? 'transform: rotate(180deg)' : ''};
    }
    .payment_invoice_pl-16{
      padding-left: 16px;
    }
    .payment_invoice_custas{
      display: flex;
      flex-direction: column;
      gap: 8px;
    }

    .payment_pointer{
      cursor: pointer;
    }
  `}
`
