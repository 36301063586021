import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto;
  background: linear-gradient(90deg, #006799 0%, #00bacb 100%);
  height: 100vh;
  justify-content: center;
  gap: 24px;

  //padding:10px;
`

export const Skeleton = styled.div`
  background-image: linear-gradient(
    -90deg,
    #fafafc 0%,
    #053f75 50%,
    #fafafc 100%
  );
  background-size: 400% 400%;
  animation: shimmer 1.2s ease-in-out infinite;
  @keyframes shimmer {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: -135% 0%;
    }
  }
`

export const Label = styled.span`
  font-family: Inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  letter-spacing: -0.05em;
  text-align: center;
  color: #ffffff;
`

export const Box = styled.button`
  display: flex;
  border: none;
  margin-bottom: 20px;
  padding: 16px;
  color: #212121;
  width: 366px;
  max-width: 95vw;
  height: 84px;
  align-items: center;
  background: #ffffff;
  border-radius: 16px;
  justify-content: space-between;
  transition: 300ms;
  gap: 16px;

  .icon_container {
    width: 48px;
    height: 48px;
    min-width: 48px;
    min-height: 48px;
    max-width: 48px;
    max-height: 48px;
    border-radius: 8px;
    background: #eeeeee;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:active {
    background: #042e64;
  }
`

export const LabelConsumer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  span {
    font-size: 9px;
    line-height: 12px;
  }
  text {
    font-size: 26px;
    line-height: 32px;
    font-weight: 700;
  }
  label {
    text-align: left;
    font-size: 12px;
    font-weight: 500;
  }
  font-family: Inter;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #212121;
`

export const LabelDescription = styled.p`
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #212121;

  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`
